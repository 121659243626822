.client-service-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 30px
  margin-bottom: 20px
  +max($desktop)
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 20px

  +max($mobile)
    grid-template-columns: 1fr

.client-service
  background: $mainWhite
  border-radius: 8px
  border: 1px solid $borderGrey
  &__heading
    padding: 20px 30px
    display: flex
    border-bottom: 1px solid $borderGrey
    &-pic
      flex-shrink: 0
      width: 50px
      height: 50px
      border-radius: 50%
      border: 2px solid $mainBlue
      overflow: hidden
      margin-right: 12px
      img
        width: 100%
        height: 100%
        display: block
    &-info
      display: flex
      flex-direction: column
      a
        text-decoration: none
        font: $regularFontSize $avenirNextMedium
        color: $mainBlack
        line-height: 20px
        margin-bottom: 12px
      span
        display: block
        color: $darkBlue
        font: $extraSmallFontSize $avenirNextMedium
  &__body
    padding: 20px 30px

  .btn-wrap
    padding: 0 30px 20px
    .button_medium
      +max($tablet)
        min-width: 109px
        padding: 0 10px
    .button_medium + .button_medium
      margin-left: auto
  &__title
    display: block
    font: $regularFontSize $avenirNextDemi
    color: $darkBlue
    margin-bottom: 20px
    &:hover
      color: $darkBlue
  &__wrapper
    +flex-content()
    .client-service__info
      margin-bottom: 0

  &__info
    margin-bottom: 20px
    &-title
      font: $smallFontSize $avenirNextMedium
      color: $textGrey
      margin-bottom: 5px
    &-description
      font: $smallFontSize $avenirNextMedium
      color: $mainBlack
      &--status
        font-family: $avenirNextBold
        color: $mainGrey
        &.status-pending
          color: $alertYellow
        &.status-accepted
          color: $mainGreen
        &.status-no-conclusion
          color: $mainRed
          position: relative
          padding-left: 20px
          &:before
            content: "no_sim"
            font: $minimalFontSize "Material Icons Outlined"
            color: $mainWhite
            width: 14px
            height: 14px
            text-align: center
            line-height: 14px
            background: $mainRed
            border-radius: 50%
            display: block
            position: absolute
            left: 0
            top: 50%
            transform: translateY(-50%)




