.popup-menu
  opacity: 1
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2)
  border-radius: 6px
  background-color: $mainWhite
  border: 1px solid$halfGrey
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 8px
  min-width: 230px

  &--item
    cursor: pointer
    padding: 10px 30px
    pointer-events: auto
    color: $darkBlue
    font-family: $avenirNextMedium
    font-size: $regularFontSize
    display: flex
    align-items: center

    &:hover
      text-decoration: none
      color: $darkBlue