.header-dropdown
    flex-wrap: wrap
    align-items: flex-start
    padding-bottom: 60px
    padding-left: 60px
    position: relative
    +max(767)
        &.main-dropdown-content
            padding-top: 60px
    .close-btn
        position: absolute
        top: 20px
        right: 20px
    +max($laptop)
        padding-left: 40px
    +max($mobile)
        padding: 0 15px 40px 15px
    &-logo
        display: none
        img
            object-fit: contain
            +size(32px)
            min-width: 32px
            +max($mobile)
                display: flex
                margin-right: auto
    &-nav
        justify-content: space-between
        width: 100%
        display: flex
        align-items: center
        .lang
            margin-right: 25px
    &__buttons
        width: 100%
        border: 1px solid $borderGrey
        border-radius: 4px
        min-height: 50px
        padding: 0 58px
        display: flex
        justify-content: space-between
        +max($mobileL)
            justify-content: space-around
            padding: 0 8px
        .auth-btn
            +flex-center()
            background-color: transparent
            border: none
            outline: none
            padding: 0 30px
            min-height: 48px
            transition: .3s ease-in-out
            min-width: unset
            position: relative
            font-family: $avenirNextMedium
            font-size: $regularFontSize
            +max($mobileL)
                padding: 0 15px
            .material-icons,
            .material-icons-outlined
                margin-left: 10px
                font-size: 18px
            &:hover
                color: $darkGrey
                text-decoration: none
                &:after
                    left: 0
                    right: 0
                    opacity: 1
            &.active
                color: $mainGreen
                &:hover
                    color: $mainGreen
                &:after,
                &:hover:after
                    left: 0
                    right: 0
                    opacity: 1
                    background-color: $mainGreen
            &:after
                border-radius: 2px 2px 0 0
                content: ''
                height: 3px
                position: absolute
                bottom: 0
                background-color: $darkGrey
                border-radius: 5px
                left: 50%
                right: 50%
                transition: all .3s ease-in-out
                opacity: 0
    &-user
        display: flex
        align-items: center
        text-decoration: none
        &:hover
            text-decoration: none
            color: $mainBlack
        &-img
            +size(50px)
            min-width: 50px
            margin-right: 20px
            border: 1px solid $halfGrey
            border-radius: 50%
        &-cabinet
            color: $mainBlack
    .register,
    .login,
    .nav-dropdown,
    .forgot-password-block,
    .recovery-password-block,
    .end-register
        padding-top: 30px
        max-height: 100vh
        height: 100%
        overflow: hidden auto
        display: none
        width: 100%
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        &.active
            display: flex
        div[data-role="preloader"]
            +flex-center()
            width: 100%
        +max($mobile)
            padding-bottom: 60px
            padding-top: 20px
        &-checkbox-wrapper
            .checkbox
                align-items: flex-start
                margin-bottom: 0
                &__ico
                    margin: 2px 10px 0 0
                &__text
                    font-size: $regularFontSize
                    color: #000105
                    font-family: $avenirNextLight
                    line-height: 24px
                    +max($mobileL)
                        font-size: $extraSmallFontSize
                        line-height: 24px
        &-error
            &.active
                margin-top: 20px
                border: 1px solid $mainRed
                background-color: #F5D2AA
                font-size: $smallFontSize
                border-radius: 2px
                padding: 15px
                line-height: 18px
        .form-group__error-list
            .form-group__error
                margin-top: 20px
                border: 1px solid $mainRed
                background-color: #F5D2AA
                font-size: $minimalFontSize
                border-radius: 2px
                padding: 15px
                line-height: 18px
        &__role
            font-size: $regularFontSize
            font-family: $avenirNextDemi
            margin-bottom: 20px
            span
                color: $mainBlue
                text-decoration: underline
                cursor: pointer
        .btn-wrap.btn-wrap--mt-none.btn-wrap--fill
            .social-btn + .social-btn
                margin-left: 0
    .login
        .radio-wrap
            margin-top: 30px
            margin-bottom: 40px
            +max($mobile)
            margin-top: 60px
    .end-register--register:before
        background-image: url('/build/images/general/illustrations/undraw_Stability_ball_b4ia.svg')
    .end-register--charge:before
        background-image: url('/build/images/general/illustrations/undraw_gradma_wanr.svg')
    .end-register
        padding-top: 0
        display: flex
        &:before
            content: ''
            display: block
            background-repeat: no-repeat
            background-size: 100% 100%
            margin: 0 auto 40px
            +size(450px, 303px)
            min-height: 303px
        +max($mobile)
            &:before
                +size(100%, 150px)
                min-height: 150px
                margin-bottom: 20px
                background-position: left center
                background-size: contain
        &__title
            font: $bigFontSize $avenirNextDemi
            color: $mainBlack
            margin-bottom: 15px
            +max($mobileL)
                font-size: $demiFontSize

        &__desc
            font: $regularFontSize $avenirNextLight
            color: $darkGrey
            margin-bottom: 15px
        &__faq-item
            font: $smallFontSize $avenirNextRegular
            color: $mainBlack
            margin-bottom: 20px
            b
              display: block
        &__panels
            width: 100%
            display: flex
            flex-direction: column
            .panel
                width: 100%
                &__title
                    display: inline-block
                    font: $regularFontSize $avenirNextDemi
                    color: $darkBlue
                    position: relative
                    padding: 15px 30px 15px 0
                    cursor: pointer
                    &:after
                        content: ""
                        border: 6px solid transparent
                        border-bottom-color: $darkBlue
                        position: absolute
                        top: 33%
                        right: 10px
                        margin: 0
                        +size(0)
                    &.collapsed:after
                        content: ""
                        top: 47%
                        border: 6px solid transparent
                        border-top-color: $darkBlue
                &__body
                    padding: 5px 0
        .change-email-form
            display: flex
            justify-content: space-between
            .form-group
                width: 69.1111%
                input::placeholder
                    font: $regularFontSize $avenirNextLight
                    color: $darkBlue
            .btn-wrap
                width: 28.8888%
            +max($mobileM)
                flex-direction: column
                .btn-wrap
                    width: 100%
                    margin-top: 15px
                .form-group
                     width: 100%
        .btn-wrap--send-mail
            margin-top: 15px
    &-login-with
        font-size: $extraSmallFontSize
        color: $mainBlack
        font-family: $avenirNextMedium
        margin: 10px 0
        +flex-center()
        width: 100%
        +max($mobileL)
            margin: 16px 0
    &-login-create
        margin-top: 20px
        font-family: $avenirNextLight
        font-size: $regularFontSize
        color: $mainBlack
        .register-click
            color: $mainGreen
            text-decoration: underline
        +max($mobileL)
            font-size: $extraSmallFontSize
            line-height: 24px
    &-back
        margin-bottom: 40px