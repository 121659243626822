.author
  display: flex
  align-items: center

  &__pic
    margin-right: 8px
    &:nth-of-type(2)
      margin-left: 8px

    img
      border-radius: 50%
      object-fit: cover
      +size(40px)
  
  &__desc
    display: flex
    align-items: center
    flex-wrap: wrap
    height: auto

  &__name
    color: $mainBlack
    font: $smallFontSize / 1 $avenirNextMedium
    margin-bottom: 2px
    width: 100%

    &:hover
      color: $mainBlack

  &__specializtion
    color: #45617E
    font: 12px / 1 $avenirNextMedium    