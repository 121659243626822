.add-analysis
  border: 1px solid $halfGrey
  border-radius: 6px
  padding: 30px
  background-color: $mainWhite
  width: 100%
  +max($mobile)
    padding: 15px
  &__client,
  &__mail,
  &__category
    width: 100%
    .form-group
      width: calc(100% / 2 - 15px)
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      +max($mobile)
        width: calc(100% / 2 - 7.5px)
      label
        width: 100%
      .select-custom
        width: 100%
      &:first-child
        +max($mobile)
          width: 100%
      &.add-analysis__phone
        +max($mobile)
          width: 100%
  &__category
    .form-group
      &:first-child
        width: 30%
        +max($mobile) 
          width: 100%
      &:last-child
        width: 70%
        margin-left: 30px
        +max($mobile) 
          width: 100%
          margin-left: 0
                 
    