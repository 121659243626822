.archive-history
  margin-bottom: 30px

.archive-history-date-item
  margin-bottom: 40px
  color: $mainBlack
  &:last-child
    margin-bottom: 0
  &__title
    font: $mediumFontSize $avenirNextDemi
    margin-bottom: 5px
  &__total-changes
    font: $smallFontSize $avenirNextMedium
    margin-bottom: 20px
  &__header
    display: flex
    justify-content: space-between

.modal-search-archive
        .modal-dialog
            max-width: 640px !important
@media(min-width: 760px)
    .modal-search-archive
        .modal-dialog
            max-width: 500px
.archive-history-date-item__list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 30px
  grid-row-gap: 20px
  @media (max-width: 1200px)
    grid-template-columns: repeat(2, 1fr)
  @media (max-width: 767px)
    grid-template-columns: 1fr

  .archive-history-item
    background: $mainWhite
    border-radius: 10px
    border: 1px solid $halfGrey
    padding: 20px
    color: $mainBlack

    &.hidden-item
      display: none

    &__wrapper
      display: flex
      align-items: center
      margin-bottom: 5px
      &--header
        justify-content: space-between

    &__link
      text-decoration: none
      &:hover
        text-decoration: none
      i
        display: block
        font-size: $regularFontSize
        color: $mainBlack
    &__time
      font: $smallFontSize $avenirNextMedium

    &__title
      font: $regularFontSize $avenirNextDemi
      margin-bottom: 5px
    &__param
      font: $smallFontSize $avenirNextMedium
      margin-right: 10px
    &__value
      font: $mediumFontSize $avenirNextMedium
    &__measuer-name
      font: $smallFontSize $avenirNextRegular
      color: $darkGrey
