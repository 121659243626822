.benefit
    display: none
    +max($mobile)
        display: block
    &-content
        padding: 30px 0 40px 0
        +max($mobile)
            padding: 17px 20px
            background-color: white
            border: 1px solid $mainGreen
            border-radius: 0 0 10px 10px
            transform: translateY(-2px)
    &-container
        +max($mobile)
            margin-bottom: 15px
        .function
            &__title
                +max($mobile)
                    display: none
            &__desc
                +max($mobileM)
                    font-size: $extraSmallFontSize
        .functions-nav
            &__link
                background-color: $mainWhite
                +max($mobile)
                    margin-bottom: 0
                    border: 1px solid $mainGreen
                &.active
                    +max($mobile)
                        border: none
                &.active
                    &.f-benefit-0
                        .functions-nav__inner
                            .functions-nav__ico
                                background-image: url('/build/images/general/research_white.svg')
                &.active
                    &.f-benefit-1
                        .functions-nav__inner
                            .functions-nav__ico
                                background-image: url('/build/images/general/data_white.svg')
                &.active
                    &.f-benefit-2
                        .functions-nav__inner
                            .functions-nav__ico
                                background-image: url('/build/images/general/archive_white.svg')
                &.active
                    &.f-benefit-3
                        .functions-nav__inner
                            .functions-nav__ico
                                background-image: url('/build/images/general/hosp_white.svg')
                &.active
                    &.f-benefit-4
                        .functions-nav__inner
                            .functions-nav__ico
                               background-image: url('/build/images/general/wallet_white.svg')
                &.active
                    &.f-benefit-5
                        .functions-nav__inner
                            .functions-nav__ico
                                background-image: url('/build/images/general/profile_white.svg')
                &.active
                    &.f-benefit-6
                        .functions-nav__inner
                            .functions-nav__ico
                                background-image: url('/build/images/general/care_white.svg')
                &.active
                    &.f-benefit-7
                        .functions-nav__inner
                            .functions-nav__ico
                                 background-image: url('/build/images/general/heart_white.svg')
                &.f-benefit-0
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/research.svg')
                &.f-benefit-1
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/data.svg')
                &.f-benefit-2
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/archive.svg')
                &.f-benefit-3
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/hosp.svg')
                &.f-benefit-4
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/wallet.svg')
                &.f-benefit-5
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/profile.svg')
                &.f-benefit-6
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/care.svg')
                &.f-benefit-7
                    .functions-nav__inner
                        .functions-nav__ico
                            background-image: url('/build/images/general/heart.svg')





