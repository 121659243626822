.company-register
    &__name
        max-width: 600px
    &__address
        width: 100%
        [data-role="autocomplete"]
            max-width: 600px
            margin-bottom: 15px
    &__type,
    &__city
        max-width: 300px
    &__street
        max-width: 300px
    &__region
        max-width: 300px
    &__district
        max-width: 300px
    &__email,
    &__webaddress
        max-width: 400px
    &__main-phone
        max-width: 220px
    &__documents
        max-width: 480px
        &-more
            display: flex
            align-items: center
            max-width: 516px
            div[data-component="file-field"]
                width: 100%
    &__work
        &--days
            .form-group
                display: flex
                flex-wrap: wrap
                align-items: center
                .checkbox
                    align-items: center
                    margin-right: 50px
                    &:last-child
                        margin-right: 0
                    &__ico
                        +size(24px)
                    &__text
                        margin-bottom: 0
                        font-size: $regularFontSize
                        font-family: $avenirNextLight
                        color: $darkBlue
        &--days,
        &--hours,
        &--lunch
            .form-wrap
                max-width: 700px
            .d-flex
                max-width: 335px
                width: 100%
                .select-custom
                    margin-right: 15px
                    &:last-child
                        margin-right: 0
        &--title
            font-size: $demiFontSize
            font-family: $avenirNextMedium
            color: $mainBlack
            margin-bottom: 20px
