.favorite-experts
  margin-bottom: 30px
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 30px
    &.js-accesses-list,
    &.js-experts-list
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: 30px
      grid-row-gap: 30px
      +max($laptopM)
        grid-template-columns: repeat(2, 1fr)
      +max($mobile)
        grid-template-columns: 1fr
    &.js-experts-list
      +max($desktop)
        grid-template-columns: repeat(2, 1fr)