.btn-wrap__profile-switcher
  margin-bottom: 2px
  margin-top: 0
  justify-content: space-between
  .button + .button
    margin-left: 10px
  .button
    flex: 1
    path
      fill: $mainWhite
    &:hover path
      transition: fill .3s ease-out
      fill: $mainWhite
  .button_secondary
    path
      fill: $mainGreen
  svg,
  .material-icons-outlined
    margin-right: 12px
    background-color: transparent
  .material-icons-outlined
    transform: translateY(1.5px)