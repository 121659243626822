.form-group-pass-wrapper
  position: relative
  .form-control
      padding-right: 50px
  .show-pass
    position: absolute
    height: 100%
    width: 22px
    right: 15px
    top: 0
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    &-eye-green
      display: none
    &.active
      .show-pass-eye
        display: none
      .show-pass-eye-green
        display: flex