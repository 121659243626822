.add-photo
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative
    img
        min-width: 100px
        object-fit: cover
        +size(100px)
        border-radius: 50%
        border: 5px solid $mainBlue
        overflow: hidden
    &__ico
        +size(36px)
        min-width: 36px
        +flex-center()
        background-color: $mainWhite
        border-radius: 50%
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)
        cursor: pointer
        color: $mainGrey
        position: absolute
        bottom: -18px    
    &__info
        display: flex
        flex-direction: column
        margin-left: 20px
        +max($mobileL)
            margin-top: 24px
            margin-left: 0
        &-title
            color: $darkBlue
        &-format,
        &-size
            font-family: $avenirNextLight
            font-size: $regularFontSize
            color: $darkBlue
            +max($mobileL)
                font-size: $extraSmallFontSize
                line-height: 24px
        &-format
            margin: 4px 0 
    &__wrap
        display: flex
        align-items: center
        margin-bottom: 48px 
        +max($mobileL)
            flex-direction: column 
            margin-bottom: 15px               
.popup-menu
    +max($laptop)
        left: 20px!important
