.form-control[readonly]
  background-color: transparent
.main-dropdown
  position: fixed
  right: 0
  left: 0
  top: 0
  bottom: 0
  z-index: 101
  display: flex
  justify-content: flex-end
  pointer-events: none
  transition: all 400ms ease-in-out
  overflow: hidden
  .feedback
    .dropdown-form__subtitle
      font-size: $regularFontSize
      font-family: $avenirNextMedium
      +max($mobile)
        font-size: $smallFontSize
  &.active
    pointer-events: auto

    &:after
      opacity: 1

    .main-dropdown-content
      opacity: 1
      right: 0
      &.create-chat-dropdown
        .dropdown-form
          height: 100%

  &:after
    content: ''
    display: block
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-color: rgba(22, 57, 91, 0.8)
    z-index: 1
    opacity: 0
    transition: all 300ms ease-in-out
.main-dropdown-content
  // height: 100%
  // max-height: 100vh
  overflow: hidden
  background-color: $mainBlack
  width: 100%
  max-width: 570px
  background: linear-gradient(123deg, #fff 0%, #fff 51.44%, #fff 100%)
  z-index: 2
  padding-top: 60px
  padding-left: 60px
  padding-right: 60px
  position: relative
  right: -100%
  transition: all 400ms ease-in-out
  opacity: 0
  display: flex
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.25)
  +max($mobile)
    padding-left: 20px
    padding-top: 20px
    padding-right: 20px
  .modal-close-btn
    position: absolute
    top: 15px
    right: 15px
    padding: 5px
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    outline: none
    border: none
    background-color: transparent
    i
      color: #ACB6DB
      font-size: $demiFontSize

  &--white
    background: $mainWhite
    color: $mainBlack
    padding-bottom: 15px
    .form-group
      &.card-checkbox
        margin-top: 20px

.main-edit-contract
  &__name
    width: 100% !important
    margin-right: 10px
    + button
      margin-top: auto

  &__start-date,
  &__end-date
    position: relative
    i
      position: absolute
      right: 10px
      top: 54%
      opacity: .5

  &__text
    width: 100% !important

.dropdown-content,
.dropdown-form
  max-width: 100%
  // min-height: 100%
  // overflow: hidden auto
  max-height: 100%
  overflow: auto
  padding-bottom: 20px
  width: 100%
  padding-right: 20px
  +max($mobile)
    padding-right: 5px
.dropdown-form
  .radio-wrap
    .radio
      margin-right: 15px
      &:last-child
        margin-right: 0
      & + .radio
        margin-left: 0

.dropdown-form::-webkit-scrollbar-track
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
  border-radius: 5px
  background-color: #F5F5F5

.dropdown-form::-webkit-scrollbar
  width: 5px
  background-color: #F5F5F5

.dropdown-form::-webkit-scrollbar-thumb
  border-radius: 5px
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
  background-color: $mainGreen
