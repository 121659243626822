.monitoring-list
  display: flex
  flex-direction: column

.monitoring-item
  width: 100%
  position: relative
  overflow: hidden
  padding: 12px 16px
  margin-top: 10px
  border-radius: 5px
  border: 1px solid $halfGrey
  box-shadow: 0 0 0 rgba(0, 0, 0, 0)
  transition: all 300ms ease
  order: 0
  &:hover
    box-shadow: 0 5px 10px rgba($mainBlack, 0.15)
  &.monitoring-item--disabled-active
    background: #bfc7e3
    order: 1
  .checkbox.monitoring-item__checkbox-disabled
    pointer-events: none
  ol
    margin-top: 10px
    display: none
    border: 1px solid $darkYellow
    background: #fccc79
    border-radius: 3px
    position: relative
    color: $mainBlack
    font-size: $smallFontSize
    overflow: hidden
    width: 100%
    padding: 7px 40px 6px 40px
    transition: all 400ms ease-out
    li:first-child
      position: relative
      &:before
        content: 'warning'
        font-family: 'Material Icons'
        position: absolute
        left: -30px
        top: 0px
        font-size: 19px
        color: $darkYellow
        margin: auto
        height: 19px
        width: 19px
        line-height: 19px
    &.expand-enabled
      max-height: 37px
      cursor: pointer
      li
        opacity: 0
        transition: all 800ms ease-out
        &:first-child
          opacity: 1
          width: 100%
      &::after
        content: 'expand_more'
        font-family: 'Material Icons'
        font-size: $bigFontSize
        position: absolute
        right: 10px
        top: 5px
        color: $mainBlack
        line-height: 24px
        +size(24px)
      &.open
        max-height: 1000px
        transition: all 800ms ease-in
        &::after
          content: 'expand_less'
        li
          transition: all 800ms ease-in
          opacity: 1
          &:first-child
            white-space: normal
    &::before
      content: ""
      background: linear-gradient(0, #fccc79, rgba($mainWhite, 0.001))
      position: absolute
      left: 0
      bottom: 0
      height: 10px
      width: 100%
      z-index: 1
  &__header
    display: flex
    justify-content: space-between
  &__header-right
    display: flex
    align-items: center
    .checkbox
      margin-left: 5px
    .checkbox__ico
      margin-right: 0
    .search-ico
      display: block
      margin-left: 5px
      line-height: 18px
      &:hover
        text-decoration: none
      i
        display: block
        font-size: $mediumFontSize
  &__expert-name
    font: $smallFontSize $avenirNextRegular
    color: $mainGrey
  &__text
    font-size: $regularFontSize
    line-height: 18px
    color: $mainBlack
    padding-right: 10px
    font-family: $avenirNextMedium
    width: 100%

  &__date
    height: 24px
    display: inline-block
    font: $smallFontSize $avenirNextRegular
    margin-left: auto
    color: rgba($mainBlack, .7)
    align-self: flex-start

  .monitoring-item__btn
    background-color: transparent
    border: 0
    padding: 0
    display: block
    line-height: 1
    margin-left: 5px
    i
      font-size: 18px
  &--active
    background: $mainWhite
    ol
      display: block
