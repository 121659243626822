.checkbox
  $t: &

  position: relative
  display: flex
  align-items: flex-start
  cursor: pointer
  &:hover
    transition: .3s ease-in-out
    #{$t}__ico
      border-color: $mainGreen
  label
    font-size: $smallFontSize    
  input
    position: absolute
    left: 5px
    top: 50%
    transform: translateY(-50%)
    opacity: 0
    
    &:checked 
      ~ #{$t}__ico
        transition: background-color .3s ease-in-out
        background-color: $mainGreen
        border: 1px solid $mainGreen

        &:after
          color: $mainWhite
          opacity: 1
      ~ #{$t}__text
        color: $mainBlack
    &:disabled
      cursor: none
      pointer-events: none
      ~ #{$t}__ico
        border-color: $mainGrey
        background-color: transparent
      ~ #{$t}__text
        color: $mainGrey


  &__ico
    +size(18px)
    background-color: transparent
    border: 2px solid $lightBlue
    border-radius: 2px
    margin: auto 10px auto 0
    transition: background-color .3s ease-in-out
    position: relative
    
    &:after
      content: 'check'
      direction: ltr
      font-family: 'Material Icons'
      font-weight: 600
      font-style: normal
      letter-spacing: normal
      text-transform: none
      white-space: nowrap
      word-wrap: normal
      -webkit-font-feature-settings: 'liga'
      -webkit-font-smoothing: antialiased
      display: flex
      align-items: center
      justify-content: center
      +size(100%)
      opacity: 0
  
  &__text
    margin-bottom: 0
    font-size: $smallFontSize
    font-family: $avenirNextMedium
    color: $mainBlack
    cursor: pointer
    

