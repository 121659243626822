.profile-additional-info
  .profile-workplace
    order: 0
    width: 100%
    background: $mainWhite
    border-radius: 8px
    overflow: hidden
    margin-bottom: 30px
    +max($laptopM)
      order: 1
      margin-top: 30px
    &__top
      position: relative
    &__pic
      position: relative
      img
        width: 100%
        height: auto
        max-height: 100%
        max-width: 100%
      &:after
        content: ""
        width: 100%
        height: 100%
        display: block
        position: absolute
        left: 0
        top: 0
        background: $imageCover
    &__full-name
      font: $mediumFontSize $avenirNextDemi
      color: $mainWhite
      position: absolute
      bottom: 30px
      padding: 0 30px

    &__info
      padding: 30px
    &__title
      font: $extraSmallFontSize $avenirNextMedium
      color: $textGrayDarker
      align-items: center
      +flex-content()
    &__value
      font: $extraSmallFontSize $avenirNextRegular
      color: $mainBlack
      &--bold
        font-family: $avenirNextDemi
    &__info-wrapper + .profile-workplace__info-wrapper
      margin-top: 15px

    &__link
      color: $mainBlue
      font: $regularFontSize $avenirNextDemi
      margin-top: 20px
      display: block
    .button_borderless
      font-size: $extraSmallFontSize
      i
        font-size: $regularFontSize
  .profile-section--list-experts
    order: 0
    .profile-section__wrapper
      padding: 0 0 20px
      .profile-section__empty
        padding: 20px 0 0 0
      .profile-section__read-more
        margin-left: 30px
      .expert-preview
        border: none
        border-radius: 0
        border-bottom: 1px solid $halfGrey
        .expert-preview__link-wrap
          padding-bottom: 20px
        .expert-preview__hide
          border: none
      .catecory_doctor_card
        padding-bottom: 20px
