@keyframes ekg
  0%
    opacity: 0
    transform: scale( 1 )

  10%
    transform: scale( 1 )

  20%
    transform: scale( 1.2 )

  25%
    opacity: 1
    transform: scale( 1 )

  30%
    transform: scale( 1.2 )

  40%
    transform: scale( 1 )

  50%
    stroke-dashoffset: 400

  99%
    opacity: 0
    stroke-dashoffset: 600

  100%
    stroke-dashoffset: 200

@keyframes heartbeat

  0%
    transform: scale( 1 )

  10%
    transform: scale( 1 )

  20%
    transform: scale( 1.2 )

  25%
    transform: scale( 1 )

  30%
    transform: scale( 1.2 )

  40%
    transform: scale( 1 )

  100%
    transform: scale( 1 )

.load-conainer
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
  opacity: 1
  margin: auto
  background: transparent

  .in-content > &
    position: relative
    display: flex

#preloader
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  z-index: 9999999999
  width: 100%
  height: 100%
  // overflow: hidden
  background-color: $mainWhite
  visibility: visible
  opacity: 1
  &.hidden
    overflow: visible
    visibility: hidden
    opacity: 0
    transition: visibility opacity .5s ease-in-out

.load-conainer
  position: absolute
  margin: auto
  vertical-align: middle
  left: 0
  right: 0
  bottom: 0
  top: 0
  width: 200px

.ecgline
  stroke: #29F499
  stroke-width: 2.3
  stroke-linecap: round
  stroke-linejoin: miter
  opacity: 0
  stroke-dashoffset: 200
  stroke-dasharray: 200
  animation: ekg 1.8s linear forwards infinite
  transform-origin: center

.preloader-logo
  animation: heartbeat 1.8s linear forwards infinite
  transform-origin: center
