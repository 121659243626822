.become-expert
    display: grid
    grid-template-columns: 240px 1fr
    grid-column-gap: 45px
    +max($laptopM)
        grid-template-columns: 1fr
    +max($mobileL)
        display: block
    &__img
        max-width: 240px
        +max($laptopM)
            display: none
    h3
        margin-bottom: 15px
        font-family: $avenirNextDemi
        font-size: $bigFontSize
    .radio-wrap
        margin-top: 40px
        display: flex
        flex-direction: column
        align-items: flex-start
        p
            margin-bottom: 15px
            font-family: $avenirNextMedium
            font-size: $regularFontSize
            &:after 
                content: '*'
                color: $mainRed
                margin-left: 5px
        .radio + .radio
            margin-left: 44px
        .form-group
            display: flex
            flex-direction: row
    .form-group
        display: flex
        flex-direction: column
    .form-group__label,
    .select-custom label
        color: $darkBlue
        font-family: $avenirNextMedium
        font-size: $regularFontSize
        margin-bottom: 5px
    .select-custom
        margin-top: 23px
        margin-bottom: 23px
        width: 420px
        +max($mobile)
            width: 100%
    .btn-wrap
        margin-top: 23px
        margin-bottom: 40px
    .add-document__wrap .add-document.add-document--expert input
        max-width: 100%
    .add-document
        width: 420px
        +max($mobile)
            width: 100%