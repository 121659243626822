.find-experts
  padding-top: 50px
  overflow: hidden
  +max($mobile)
    padding-top: 18px 
  &__wrapper
    .container
      +max($tablet)
        padding: 0 40px
      +max($mobile)
        padding: 0 20px
    .title
      font-size: $largerFontSize
      margin-bottom: 20px
      +max($tablet)
        font-size: $bigFontSize
        margin-bottom: 15px
      +max($mobile)
        font-size: $mediumFontSize
        margin-bottom: 10px
  &-title
    font-size: $largeFontSize
    margin-bottom: 5px
    +max($mobile)
      font-size: $bigFontSize

  &-block
    margin-top: 90px
    +max($mobile)
      margin-top: 40px
      margin-bottom: 0
    .shares-wrap
      margin-top: 0
      padding-top: 0
  &_requests
    display: flex
    align-items: flex-start
    flex-wrap: wrap
    width: 100%
    &-item
      padding: 12px 20px
      margin-right: 10px
      cursor: pointer
      +flex-center()
      font-size: $regularFontSize
      color: $mainBlack
      font-family: $avenirNextRegular
      border-radius: 5px
      border: 1px solid $mainGrey
      background-color: transparent
      margin-bottom: 10px
      transition: .3s ease-in-out
      &:hover
        background-color: $mainGreen
        color: $mainWhite
        border-color: $mainGreen
      a
        text-decoration: none
        &:hover
          text-decoration: none
          color: $mainWhite  
  
  .cabinet-content-header
    margin-bottom: 0

  .find-experts-title
    margin-bottom: 25px
    +max($mobile)
      margin-bottom: 20px
  
  .shares-wrap
    padding-top: 0
    
  .best-doctors
    margin-bottom: 165px
    position: relative
    +max($mobile)
      margin-bottom: 0
    
    .swiper-container
      z-index: 1
      padding-right: 10px
      padding-bottom: 10px 
      margin-right: -10px
    .expert-preview__clinic
      .expert-preview
        padding-bottom: 0
        &__address
          padding: 20px 30px
        &__hide
          margin-top: 30px
          padding: 0 30px 0px 30px
          width: auto
          top: 0
          left: 0
          position: relative
          visibility: visible
          opacity: 1
          border: none
          box-shadow: none
          +max($laptop)
            padding-bottom: 20px
    .next,
    .prev
      bottom: -90px
      z-index: 0