.social-btn
  font: $regularFontSize $avenirNextMedium
  border-radius: 2px
  img
    width: 28px
    margin-right: 8px
  &--fb,
  &--apple,
  &--google
    font: $regularFontSize $avenirNextDemi
    border-radius: 5px
    img
      height: 22px
      width: auto
      +max($mobileL)
        margin: 0
    span
      +max($mobileL)
        display: none
    &.button.button_icon
      justify-content: center
  &--fb
    margin-right: 10px
    color: $mainWhite
    background-color: #3B5998
  &--apple
    margin-right: 10px
    color: $mainWhite
    background-color: $mainBlack
  &--google
    margin-left: 0
    color: $darkBlue
    background-color: $lightGrey     