.static
  padding-top: 50px
  display: grid
  grid-template-columns: 330px 3fr
  grid-column-gap: 30px
  +max($mobile)
    padding-top: 18px
    grid-template-columns: 1fr
  &-page
    .header
      border-bottom: 1px solid $mainGrey
  &__content
    +max($tablet)
      width: 100%
      margin-left: 0
      padding-top: 40px
  &-nav
    +max($tablet)
      width: 100%
    &__wrapper
      +max($tablet)
          display: flex
          align-items: center
          justify-content: space-between
      +max($mobile)
          align-items: flex-start
          flex-direction: column   
  &_list
    &.common_list
      +max($tablet)
        display: none
  table
    +max($mobile)
      max-width: 100%
      table-layout: fixed
    td
      +max($mobile)
        word-break: break-all