.dashboard-schedule

  .dashboard-item__content
    max-height: 610px
    min-height: 400px
    .before-load
      position: absolute
      left: 0
      top: 0
  &__calendar-wrapper
    padding: 20px
    display: flex
    justify-content: center

  &__calendar
    display: inline-block
    position: relative
    width: 140px
    input
      height: 40px
      border-radius: 4px
      border: 1px solid #AFB9DD
      width: 100%
      padding-left: 10px
      font: 16px $avenirNextMedium
      color: #000
      cursor: pointer
    &:after
      content: ''
      width: 0
      height: 0
      position: absolute
      right: 10px
      top: 19px
      border-width: 5px
      border-style: solid
      border-color: #000 transparent transparent transparent

  &__item
    width: 100%
    border: 1px solid #E0E7FF
    background-color: #fff
    border-radius: 5px
    padding: 20px
    transition: .3s ease-in-out
    display: flex
    flex-direction: column
    margin-bottom: auto
    margin-top: 20px

    &:first-child
      margin-top: 0
    &:hover
      transition: .3s ease-in-out
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1)
  &__time
    font: 13px $avenirNextRegular
    color: #010101
    margin-bottom: 20px
  &__header
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    margin-bottom: 25px
  &__avatar
    width: 32px
    height: 32px
    border-radius: 50%
    margin-right: 10px
    overflow: hidden
    img
      max-width: 100%
      max-height: 100%
  &__wrapper
    margin-right: 10px
  &__cost
    margin-left: auto
  &__user-name
    font: 14px $avenirNextMedium
    color: #000
  &__service-name
    font: 14px $avenirNextRegular
    color: #000
  &__label
    font: 13px $avenirNextRegular
    color: #010101
  &__price
    font: 14px $avenirNextMedium
    color: #010101
    word-break: break-all
  &__contact
    display: flex
    justify-content: space-between
  &__phone
    display: flex
    align-items: center
    font: 13px $avenirNextMedium
    color: #010101
    flex-wrap: wrap
    word-break: break-all
  &__secondary-btn
    width: 100px
    min-height: 36px
    background: #fff
    color: #000
    font: 14px $avenirNextMedium
    border: 1px solid #AFB9DD
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    text-decoration: none
    transition: all 0.3s ease
    &:hover
      text-decoration: none
      color: #000
      background: #E8E8E8
      box-shadow: 0 5px 10px rgba(0,0,0,0.2)
