.consultant-requests
  background: $mainWhite
  border-radius: 8px
  border: 1px solid $borderGrey
  &__header
    padding: 20px 40px
    border-bottom: 1px solid $borderGrey
    display: flex
    width: 100%
    +max($mobile)
      display: none
  &__body
    padding: 0 40px 10px
    width: 100%
    +max($mobile)
      padding: 0 15px

  &__item
    display: flex
    align-items: center
    width: 100%
    padding: 18px 0
    +max($mobile)
      flex-wrap: wrap
      padding: 15px 0
    & + .consultant-requests__item
      border-top: 1px solid $borderGrey

  &__date,
  &__email,
  &__patient,
  &__status
    font: $regularFontSize $avenirNextDemi
    color: $textGrey

  &__patient
    width: 35%
    +max($mobile)
      width: 100%
      margin-bottom: 15px

  &__status
    width: 15%
    +max($mobile)
      width: 100%

  &__date,
  &__email
    width: 35%
    +max($mobile)
      width: 100%
      margin-bottom: 15px

  &__title
    font: $smallFontSize $avenirNextMedium
    color: $textGrey
    display: none
    +max($mobile)
      display: block
      margin-bottom: 10px
  &__value--email,
  &__value--name
    font: $smallFontSize $avenirNextMedium
    color: $darkBlue

  &__value--date
    font: $smallFontSize $avenirNextMedium
    color: $mainBlack
  &__value--status
    font: $smallFontSize $avenirNextBold
    &.registered
      color: $hoveredMainGreen
    &.new
      color: $darkYellow