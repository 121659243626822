.scroll-to-top-button
  display: none
  position: fixed
  bottom: 180px
  right: 25px
  opacity: 0
  visibility: hidden
  z-index: 6
  i
    color: $mainWhite
  &:hover
    cursor: pointer
  &:active
    background-color: #555
  &.show
    display: flex
    opacity: 1
    visibility: visible

