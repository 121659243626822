.safe-preview
  padding: 17px 20px

  &__balance
    font-size: $regularFontSize
    color: $darkBlue
    display: flex
    align-items: center

    span
      margin-left: 10px
      font-size: $bigFontSize
      font-family: $avenirNextBold
      color: $darkBlue
  
  &__cards
    margin-top: 20px

    .select2-container--default .select2-selection--single
      border-color: #E8F2FF !important

    .select2-container--default .select2-selection--single .select2-selection__rendered
      background-color: #E8F2FF !important
      color: #1248B6 !important
  
  .select-custom
    margin: 30px 0