.default-news
    &__list
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-gap: 30px
        +max($desktop)
            grid-template-columns: repeat(3, 1fr)
        +max($laptop)
            grid-template-columns: repeat(2, 1fr)
        +max($mobileL)
            grid-template-columns: 1fr
            grid-gap: 30px 20px
    &__item
        display: flex
        height: auto
        overflow: hidden
        flex-direction: column
        background-color: $mainWhite
        border-radius: 10px
        padding: 10px
        &.default-news__item--only-title
            height: auto
            padding-top: 19px
            margin-top: 20px
            border-top: 1px solid $borderGrey
            .default-news__item-pic
                display: none
        &-pic
            display: flex
            width: 100%
            min-height: 233px
            height: 233px
            margin-bottom: 20px
            +max($mobile)
                height: 150px
                min-height: auto
            img
                border-radius: 4px
                max-width: 100%
                width: 100%
                object-fit: cover
                +max($mobile)
                    height: 150px
        &-title
            color: $darkBlue
            line-height: 29px
            height: 100%
            max-height: 116px
            margin-bottom: 6px
            overflow: hidden
        &-content
            display: flex
            flex-direction: column
            height: 100%
        &-footer
            display: flex
            justify-content: space-between
            align-items: flex-end
            width: 100%
            margin-top: auto
            &--left,
            &--right
                +flex-center()
                align-items: flex-end
        &-category,
        &-date
            font-size: $extraSmallFontSize
            font-family: $avenirNextMedium
            color: $mainGreen
            margin-right: 12px
            &.links-effect
                &:hover,
                &:focus
                    color: $mainGreen
        &-date
            color: $mainGrey
        &-data
            display: flex
            align-items: center
            color: $mainGrey
            font-size: $extraSmallFontSize
            font-family: $avenirNextDemi
            .material-icons,
            .material-icons-outlined
                font-size: 18px
                margin-right: 6px
            + .default-news__item-data
                margin-left: 13px
        .one-news__banner
            margin: 0
            height: 300px
            +max($desktop)
                width: 100%
            +max($mobile)
                height: 250px

.one-news
    &__slide
        max-width: 100%

    &__content-banner
        display: flex
        margin: 0 auto
        width: 100%

        &-img
            object-fit: contain
            width: 100%
            height: auto

    &__right-block
        & .one-news__content-banner
            width: 100%
            margin-bottom: 20px

.default-news__item
    .one-news__content-banner
        width: 100%
        max-width: 100%
        height: auto

.swiper-slide-news
    width: 100%
    min-height: 200px
    margin-top: 140px
    +max($laptopM)
        margin-top: 70px
    &__less-three-slides
        height: 250px
        width: 100%
        object-fit: cover
    &__three-slides
        width: 100%
        object-fit: cover
        height: 200px
    &__more-three-slides
        width: 100%
        object-fit: cover
        height: 100%
