.cabinet-preview
  > a
    display: flex
    align-items: center
    color: $mainWhite
    margin-left: 17px
    +max($laptop)
      margin: 0 10px
    +max($mobile)
      margin: 0

    &:hover
      text-decoration: none
      color: $mainWhite

    img
      +size(60px)
      border-radius: 50%
      box-shadow: 0 10px 20px 0px rgba(0,0,0,0.1)
      margin-right: 9px
      object-fit: cover
      background-color: $mainWhite
      +max($mobile)
        +size(40px)
        margin-right: 0
  &__element
    +max($mobile)
      span
        display: none
  &__head
    padding: 20px 20px 5px 20px
    border-bottom: 1px solid #D2E1F5
    text-align: left

  &__name
    font-family: $avenirNextDemi
    font-size: $smallFontSize
    line-height: 17px
    color: $mainBlack
    margin-bottom: 2px
    overflow-wrap: anywhere

  &__mail
    font-size: $extraSmallFontSize
    font-family: $avenirNextLight
    color: $darkGrey
    margin-bottom: 10px

  &__company
    padding: 10px 0

  &__nav
    padding: 10px 0
    border-top: 1px solid $borderGrey
    border-bottom: 1px solid $borderGrey
    &-item
      display: block
      color: $darkBlue
      font-size: $smallFontSize
      font-family: $avenirNextLight
      background-color: transparent
      border-left: 4px solid transparent
      padding: 10px 20px
      display: flex
      align-items: center
      transition: all .2s
      i.material-icons,
      i.material-icons-outlined
        margin-right: 20px
        color: $mainGrey

      &:hover
        border-color: $darkBlue
        background-color: #E8F2FF
        text-decoration: none
        color: $darkBlue
        i.material-icons,
        i.material-icons-outlined
          color: $darkBlue

      &.logout
        font-family: $avenirNextMedium
        color: $mainRed
        i.material-icons,
        i.material-icons-outlined
          color: $mainRed
        &:hover
          i.material-icons,
          i.material-icons-outlined
            color: $mainRed

  &__footer
    padding: 10px 0
  .dropdown-body
    right: -1px
    width: 304px
    top: calc(100% + 20px)
    +max($laptop)
      display: none

    &:after
      right: 68px

    &:before
      content: ''
      height: 25px
      left: 0
      right: 0
      bottom: calc(100% - 2px)
      display: block
      position: absolute

.menu-disclamer-wrapper
  position: relative

.attention
  color: $alertYellow
  border-radius: 50%
  animation: animate-pulse 3s linear infinite
  font-size: 20px
  cursor: pointer
  position: absolute
  bottom: 0px
  left: 10px
  z-index: 20
  +max($laptop)
    left: 5px
  +max($mobile)
    font-size: 16px
    left: -5px

@keyframes animate-pulse
  0%
    box-shadow: 0 0 0 0 $alertYellow, 0 0 0 0 $alertYellow

  40%
    box-shadow: 0 0 0 20px rgba(255,109,74,0.0), 0 0 0 0 $alertYellow

  80%
    box-shadow: 0 0 0 20px rgba(255,109,74,0.0), 0 0 0 5px rgba(255,109,74,0)

  100%
    box-shadow: 0 0 0 0 rgba(255,109,74,0.0), 0 0 0 5px rgba(255,109,74,0)
