.modal
    background-color: rgba(22, 57, 91, 0.7)
    $t: &
    &-register-role
        &__list

        &__item
            position: relative
            cursor: pointer
            font-family: $avenirNextMedium
            font-size: $regularFontSize
            color: $darkBlue
            margin-bottom: 20px
            display: flex
            align-items: center
            justify-content: center
            border: 1px solid #C0D3FE
            border-radius: 10px
            transition: 200ms ease
            padding: 15px 10px 10px 5px
           
            &:hover
                border-color: $hoveredMainGreen
                .register-button
                    background-color: $hoveredMainGreen
                    color: $lightGrey

                .registerRoleModal_arrow_forward
                    background-color: $hoveredMainGreen
                    color: $mainWhite
            &:last-child
                margin-bottom: 0
            &::before
                content: ''
                position: absolute
                width: 100%
                height: 100%
    &.modal-register-role
        #{$t}-dialog
            &.modal-dialog-centered
                +flex-center()
        #{$t}-content
            max-width: 450px
            border-radius: 8px
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        #{$t}-body
            padding: 0px 20px 30px 20px
        #{$t}-header
            min-height: 56px
            padding: 0 20px
            display: flex
            align-items: center
            border-bottom: none
            &-title
                font-size: $mediumFontSize
                font-family: $avenirNextMedium
                color: $mainBlack
            .close
                +size(20px)
                position: static
                span
                    font-size: 20px
                    font-family: $avenirNextMedium
                    color: $textGreyLight
.dc-modal-float
    .dc-modal-float__content
        max-height: 80vh
        max-width: 75vw
        height: 100%
        img
            height: 100%
            width: 100%
            object-fit: contain

.register-modal-text
    font-size: 12px
    max-width: 285px
    width: 100%
    margin: 20px 15px 10px 5px

.register-text-header
    font-size: 16px
    font-weight: 600
    line-height: 1
    margin-bottom: 10px

.register-text-description
    font-weight: 450
    line-height: 1.16
    padding-right: 20px

.register-img
    width: 100px
    height: 100px
    margin: 5px

.register-button
    width: 30px
    height: 30px
    border-radius: 50%
    background-color: $lightGrey
    color: $hoveredMainGreen
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    right: 10px
    bottom: 9px
    transition: 200ms ease

.registerRoleModal_roleDescription
    font-family: $avenirNextLight
    line-height: 1.16
    padding-right: 20px
    font-size: $smallFontSize

.registerRoleModal_roleTitle
    font-size: $regularFontSize
    font-family: $avenirNextDemi
    line-height: 1
    margin-bottom: 10px

.registerRoleModal_arrow_forward
    padding: 5px
    margin: 0px
    background-color: #e5eeff
    border-radius: 50%
    align-self: self-end
    transition: 200ms ease

.registerRoleModal_status_description
    margin-left: 5px
    align-self: flex-start
