.about-wrap
  padding-top: 130px
  +max($desktop)
    padding-top: 100px
  +max($mobile)
    padding-top: 60px
.about
  padding: 4px 1px 1px 1px
  border-radius: 7px
  background: linear-gradient(64deg, #06CF9A 0%, #00D9D7 100%)
  position: relative
  box-shadow: 0 30px 30px 0px rgba(0,0,0,0.05)
  &:after,
  &:before
    content: ''
    background-color: $mainWhite
    border: 1px solid #DEE6FF
    border-top: 0
    box-shadow: 0 30px 30px 0px rgba(0,0,0,0.05)
    border-radius: 0 0 7px 7px
    position: absolute
    bottom: -20px
    height: 20px
    display: block
    left: 60px
    right: 60px
    z-index: 1
  &:after
    bottom: -40px
    display: block
    left: 120px
    right: 120px
  +max($mobile)
    &:after,
    &:before
      height: 10px
    &:after
      bottom: -20px
      left: 40px
      right: 40px
    &:before
      left: 20px
      right: 20px
      bottom: -10px

.swiper-container
  overflow: hidden
  
.about-inner
  background-color: $mainWhite
  border-radius: 7px
  padding: 60px
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  position: relative
  z-index: 3
  +max($mobile)
    padding: 20px

  .swiper-container
    width: 100%
    @media (max-width: 768px)
      padding-bottom: 25px

  .swiper-wrapper
    
   .swiper-slide
    display: flex

  .next
    right: -15px

  .prev
    left: -15px

  .prev,
  .next
    +size(60px)
    border-radius: 50%
    background-color: $mainGreen
    color: $mainWhite
    box-shadow: none
    z-index: 10
    +flex-center()
    position: absolute
    cursor: pointer
    +top()
    +max($laptopM)
      +size(40px)
      top: 230px
      transform: unset

  .about-nav,
  .about-btns
    display: flex
    align-items: center
    flex-direction: row
    width: 100%
    justify-content: flex-end
    +max($laptop)
      justify-content: flex-start
  .about-btns
    margin-top: 30px
    +max($mobileL)
      flex-direction: column
    +max($mobile)
      margin-top: 0
    .btn-wrap
      width: calc(50% - 24px)
      +max($laptop)
        width: 100%
      +max($mobile)
        width: 100%
        display: block
        margin-right: 0
        justify-content: space-between

  &__readmore
    height: 60px
    background-color: transparent
    padding: 0 35px
    +flex-center()
    color: #12C48B
    font-size: $regularFontSize
    font-family: $avenirNextMedium
    border-radius: 30px
    border: 1px solid #18D766
    margin-bottom: 0
    width: auto
    justify-content: center
    transition: all .3s ease-in-out
    &:hover   
      text-decoration: none
      background-color: #12C48B
      color: $mainWhite

    +max($mobile)
      margin-right: 0
      width: 100%
      padding: 0 20px
      min-height: 50px
      height: unset
      margin-bottom: 10px
      justify-content: center
  &__start
    height: 60px
    display: flex
    align-items: center
    background-color: #12C48B
    box-shadow: 0 10px 20px 0px rgba(18,196,139,0.2)
    border-radius: 30px
    border: 0
    font-size: $regularFontSize
    font-family: $avenirNextMedium
    color: $mainWhite
    padding: 0 10px 0 20px
    width: auto
    justify-content: center
    margin-left: 30px
    &:hover,
    &:focus
      text-decoration: none
      color: #fff
    +max($laptopM)
      padding: 0 20px
    +max($mobile)
      width: 100%
      margin-left: 0
      min-height: 50px
      height: unset
    span
      margin-left: 35px
      +size(40px)
      +flex-center()
      border-radius: 50%
      background-color: $mainWhite
      +max($laptopM)
        display: none
      i
        color: #12C48B
        +max($laptopM)
          display: none

.about-item
  display: flex
  justify-content: space-between
  align-items: flex-start
  +max($laptop)
    flex-direction: column
  &__img
    margin-bottom: 0
    width: calc(50% - 24px)
    max-height: 300px
    img
      max-height: 450px
      +max($laptop)
        width: 100%
        margin: 0
        max-height: 369px
      +max($mobile)
        max-height: 200px
    +max($laptop)
      margin: 25px 0
      width: 100%
    +max($tablet)
      margin-bottom: 20px
      margin-top: 0
      width: 100%
      max-height: 500px
    +max($mobile)
      max-height: unset
      margin-bottom: 20px

  &__body
    width: calc(50% - 24px)
    padding-right: 35px
    +max($laptop)
      width: 100%
      padding-right: 0
    +max($mobile)
      padding-right: 0

    p
      display: flex
      align-items: flex-start
      font: 18px / 24px $avenirNextMedium
      color: $darkBlue
      .material-icons
        +size(32px)
        min-width: 32px
        line-height: 32px
        text-align: center
        border-radius: 50%
        background-color: #1ea579
        color: white
        margin-right: 12px
        +max($mobile)
          +size(20px)
          min-width: 20px
          font-size: 14px
          line-height: 20px
          transform: translateY(5px)
      @media (max-width: 768px)
        font-size: $extraSmallFontSize
        font-family: $avenirNextRegular

      + p,
      + ul
        margin-top: 25px
        +max($mobile)
          margin-top: 15px
    ul
    + p,
    + ul
      margin-top: 25px
      +max($mobile)
        margin-top: 15px

    li
      font: 18px / 24px $avenirNextMedium
      display: flex
      position: relative
      color: $mainBlack
      +max($mobile)
        font-size: $regularFontSize
        font-family: $avenirNextRegular

      + li
        margin-top: 20px
        +max($mobile)
          margin-top: 25px

      span
        background-color: #00D7A9
        +size(24px)
        border-radius: 50%
        +flex-center()
        display: block
        position: absolute
        left: 0
        top: 0

        i
          +center()
          transform: translate(-82%, -50%)
          font-size: $mediumFontSize
          color: $mainWhite

  &__title
    color: $darkBlue
    font: $largeFontSize / 1 $avenirNextBold
    margin-bottom: 40px
    +max($mobile)
      text-align: center
      font-family: $avenirNextDemi
      font-size: $mediumFontSize
      margin-bottom: 20px
    +max($mobileM)
      font-size: $regularFontSize
      margin-bottom: 15px

.slide-progress
  width: 0
  max-width: 100%
  height: 9px
  background: #1EA579
  border-radius: 7px 7px 0 0
  position: absolute
  top: 0
  left: 0
  right: 0
  z-index: 2
