.error-page
  .header-container
    position: relative
    z-index: 2
    display: flex
    justify-content: space-between
    width: 100%
    .header-container__left
      justify-self: start
    .header-container__right
      justify-self: end
    .default-logo-desk
      +max($mobile)
        display: none
    .default-logo-mobile
      display: none
      +max($mobile)
        display: block
        height: 40px
        margin: 20px 0
  .footer-error-page
    padding-top: 0
  &.wrapper
    background: url("/build/images/404_bg.svg")
    background-size: cover
  .error-message
    text-align: center
    color: $darkBlue
    margin: 0 auto 88px
    padding-top: 10%
    &__number
      grid-template-columns: repeat(3, 1fr)
      display: grid
      margin-bottom: 88px
    h1
      margin-bottom: 20px
      color: $mainBlack

    &__content
      max-width: 500px
      margin: 0 auto
    &__symbol
      font-size: 300px
      font-family: $avenirNextBold
      +max($mobile)
        font-size: 200px
      +max($mobileL)
        font-size: 100px
      &--first
        justify-self: end
        margin-right: 20px
        +max($mobileL)
          margin-right: 10px
      &--second
        justify-self: start
        margin-left: 20px
        +max($mobileL)
          margin-left: 10px
    &__symbol-image
      +size(451px, 484px)
      +max($tablet)
        align-self: center
        +size(300px)
      +max($mobile)
        +size(220px)
      +max($mobileL)
        justify-self: start
        +size(120px)
    p
      margin-bottom: 48px
      color: $mainBlack
