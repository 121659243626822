.my-review
  $t: &

  background-color: $mainWhite
  border: 1px solid #D2E1F5
  border-radius: 7px
  padding: 18px
  display: flex
  flex-wrap: wrap
  justify-content: space-between
   
  &__rating-wrap
    display: flex
    align-items: center
    width: 100%
    margin-top: 15px
    margin-bottom: 7px
    +max($mobile)
      justify-content: space-between

  &__message
    width: 100%
    color: $mainBlack
    opacity: .85
    font-size: $smallFontSize
    
  &__date
    color: $mainBlack
    opacity: .7
    font-size: 12px
  
  &__recommend
    display: flex
    align-items: center
    color: $mainGreen
    font: 13px $avenirNextMedium

    i
      margin-left: 5px
      font-size: $mediumFontSize
    &.negative
      color: $mainRed
      i
        color: $mainRed

  .author
    max-width: calc(100% - 120px)

    &__specializtion
      color: #1248B6
