.my-data-form
  padding: 30px
  background-color: $mainWhite
  border: 1px solid $halfGrey
  border-radius: 8px
  &__title
    font: $mediumFontSize $avenirNextDemi
    margin-bottom: 20px


  + .flesh-notification
    margin-top: 30px
    +max($mobile)
      width: 100%

  &.active
    display: block
    +max($mobile)
      width: 100%
      display: flex
      flex-direction: column
  form
    .form-wrap
      +max($mobile)
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
    +max($mobile)
      textarea
        height: 140px

  &__row
    display: flex
    justify-content: space-between
    width: 100%
    margin-bottom: 20px
    .form-group
      width: calc( (100% - 80px) / 4 )
      margin-top: 0

  &__bad
    .form-wrap &
    width: calc((100% - 20px) / 2)
    margin-right: 20px
    +max($mobile)
      margin-right: 0
      width: 100%
      margin-top: 10px
  &__allergy
    .form-wrap &
    width: 100%
    margin-right: 0

  &__chronichal
    .form-wrap &
    width: calc((100% - 20px) / 2)
    +max($mobile)
      margin-right: 0
      width: 100%
      margin-top: 10px
