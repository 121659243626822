.broadcasts
  padding-top: 90px
  &-block
    + .broadcasts-block
      margin-top: 60px
.last-broadcasts
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 30px
      

