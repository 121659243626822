.big-schedule
  &-table
    &-head
      display: flex
      align-items: center
      padding: 0 20px
      border-radius: 5px
      min-height: 50px
      background-color: $lightGrey
      margin-bottom: 10px
      &-item
        width: calc(100% / 4)
        color: $mainBlack
        font-family: $avenirNextDemi
        font-size: $smallFontSize
    &-row
      min-height: 50px
      border: 1px solid $halfGrey
      border-radius: 5px
      display: flex
      align-items: center
      margin-bottom: 5px
      padding: 0 20px
      &:last-child
        margin-bottom: 0
      &:hover
        border-color: $mainGreen
        box-shadow: 0 5px 10px 0 rgba(22,57,91,0.1)
    &-col
      font-family: $avenirNextMedium
      font-size: $smallFontSize
      width: calc(100% / 4)
      &-delete,
      &-edit
        background-color: transparent
        outline: none
        border: none
        .material-icons,
        .material-icons-outlined
          font-size: 18px
      &-delete
        &:hover
          color: $mainRed
      &-edit
        &:hover
          color: $mainBlue 
      &.big-schedule-dateHours
        font-family: $avenirNextRegular

    &.big-schedule-table--vacations
      .big-schedule-table-head-item,
      .big-schedule-table-col
        width: calc(100% / 3)

    .schedule-items-actions
      margin-top: 10px
      display: flex
      align-items: flex-end
      justify-content: flex-end
      button.non-active
        display: none