.laptop-container
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 90px
  position: relative
  z-index: 3

.laptop
  width: 920px
  max-width: 100%
  height: 570px
  perspective: 3600px
  perspective-origin: 50% 100%
  +max($tablet)
      height: 480px
  +max($mobile)
      height: 380px
  +max($mobileL)
      height: 300px
  +max($mobileM)
      height: 270px


.laptop-block
  position: absolute
  z-index: 6
  top: 0
  left: 0
  width: 100%
  height: 16px
  border-radius: 16px 16px 0 0
  background-image: linear-gradient(90deg, #E5EDF6 23%, #D2D9E6 91%)
  transform: scale(0)
  transition: all ease .32s

  &:after
    content: ""
    background-color: $mainWhite
    bottom: -3px
    left: 0
    width: 100%
    height: 3px
    position: absolute

.laptop-top
  position: relative
  width: 85%
  margin-left: auto
  margin-right: auto
  height: calc(100% - 32px)
  border-radius: 16px
  background-color: #E0E8F2
  box-sizing: border-box
  transform-style: preserve-3d
  transform-origin: 50% 100%
  transform: translate3d(0, 0, 0) rotateX(0deg)
  transition: all ease .32s

  &:before
    position: absolute
    z-index: 2
    content: ""
    width: 100%
    height: 40px
    left: 0
    bottom: 0
    background-color: inherit

  div
    position: absolute
    z-index: 3

    &:first-child
      left: 6px
      top: 4px
      width: calc(100% - 12px)
      height: calc(100% - 6px)
      background-color: transparent
      border: 1px solid rgba(176, 200, 216, 0.2)
      border-radius: 16px 16px 0 0
      border-bottom: 0

    &:last-child
      background-color: $mainWhite
      top: 24px
      left: 24px
      width: calc(100% - 50px)
      height: calc(100% - 40px)
      border-radius: 4px 4px 0 0
      overflow-y: scroll
      overflow-x: hidden
      -webkit-overflow-scrolling: touch
      +max($laptop)
        overflow: hidden

      img
        width: 100%

.laptop-bottom > div
  width: 100%

  &:first-child
    position: relative
    height: 32px

    > div
      width: 100%

      &:first-child
        position: relative
        z-index: 2
        height: 20px
        background-color: #D2D9E6
        border-radius: 4px
        overflow: hidden

        div
          &:first-child
            position: absolute
            left: 0
            top: 0
            width: 50%
            height: 100%
            background-image: linear-gradient(90deg, #E5EDF6 23%, #D2D9E6 91%)
            border-radius: 3px

          &:nth-child(2)
            transform: rotate(-180deg)
            position: absolute
            right: 0
            top: 0
            width: 50%
            height: 100%
            background-image: linear-gradient(90deg, #E5EDF6 23%, #D2D9E6 50%)
            border-radius: 3px

          &:last-child
            position: absolute
            width: 30%
            height: 200%
            background-color: #E8EFF8
            border-radius: 56px
            bottom: 0
            left: 50%
            margin-left: -15%
            box-shadow: inset -5px 0 3px 0 #F4F7FA, inset 9px 0 5px 0 rgba(0, 0, 0, 0.14)

      &:last-child
        position: absolute
        z-index: 1
        bottom: 0
        left: 0
        height: 100%
        background-color: #CFD3E2
        border-radius: 38px

@media screen and (max-width: 1000px)
  .laptop-bottom > div:first-child
    height: 24px

@media screen and (max-width: 1000px)
  .laptop-bottom > div:first-child > div:first-child
    height: 12px

.laptop--closed
  .laptop-block
    top: calc(100% - 50px)
    transform: scale(1)

  .laptop-top
    overflow: auto
    transform: translate3d(0, 0, 0) rotateX(-90deg)