.dropdown
  position: relative

  i
    pointer-events: none

  &_static,
  &:hover
    .dropdown-body
      opacity: 1
      visibility: visible
      pointer-events: auto
      &:after
        opacity: 1

.dropdown-body
  position: absolute
  top: calc(100% + 10px)
  right: 0
  background-color: $mainWhite
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 4px
  z-index: 2
  opacity: 0
  visibility: hidden
  pointer-events: none
  transition: .3s ease-in-out
  border: 1px solid $borderGrey
.dropdown-body--mr
  top: 100%
  .concilium-member
    transition: background-color .2s ease-out
    &:hover
      background-color: #fafafa
