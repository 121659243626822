
.form-group, .form-wrap
  margin-bottom: 0
  + .form-group,
  + .form-wrap
    margin-top: 22px
    +max($mobileL)
      margin-top: 20px 

.form-wrap
  display: flex
  align-items: baseline
  justify-content: space-between
  align-items: flex-end
  +max($mobile)
    align-items: flex-start
    flex-wrap: wrap
  .form-group
    margin-bottom: 0
    margin-top: 0
    width: calc(100% / 2 - 15px)
    +max($mobile)
      width: 100%
    + .form-group
      +max($mobile)
        margin-top: 20px
.form-description
    font-size: 12px
    color: $mainBlack
    display: block
    margin-bottom: 10px
    &--required
      position: relative
      &:before
        content: '*'
        color: $mainRed
        margin-right: 5px

