.autocomplete
  .dropdown
    &-body
      opacity: 1
      width: 100%

    &-item
      cursor: pointer
      min-height: 50px
      padding: 10px 20px
      pointer-events: auto
      white-space: normal
      font-family: $avenirNextMedium
      color: $mainBlack
      font-size: $regularFontSize
      transition: .3s ease-in-out
      &:hover
        color: $mainGreen
        background-color: rgba($mainGreen, .1)
