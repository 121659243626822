.wards-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 30px
  +max($laptopM)
    grid-template-columns: repeat(2, 1fr)
  +max($mobile)
    grid-template-columns: 1fr
    grid-gap: 20px

  .ward-item
    position: relative
    background: $mainWhite
    border: 1px solid $borderGrey
    border-radius: 8px
    display: flex
    flex-wrap: nowrap
    flex-direction: column
    justify-content: flex-start
    .context-menu
      position: absolute
      right: 10px
      top: 15px
    &__wrapper
      width: 100%
      display: flex
      flex-wrap: wrap
      margin-bottom: 16px
      justify-content: space-between
      align-items: center
      &--header
        padding: 20px 30px 0 30px
        border-bottom: 1px solid $borderGrey
      &--body
        padding: 20px 30px
    &__info

      width: calc( 100% - 65px)
    &__pic
      margin-right: 15px
      margin-bottom: 0
      img
        width: 50px
        height: 50px
    &__full-name
      font: $smallFontSize $avenirNextMedium
      color: $mainBlack
    &__username
      font: $smallFontSize $avenirNextMedium
      color: $darkGrey
      line-height: 20px

    &__info-title
      font: $smallFontSize $avenirNextMedium
      color: $textGrey
      line-height: 21px
      width: 100%
    &__info-desc
      font: $smallFontSize $avenirNextMedium
      color: $mainBlack
      width: 100%
      &--color-select
        color: $mainGreen
    &__helper-text
      color: #000000
      line-height: 18px
      margin-bottom: auto
    button
      width: 160px
    &__btn
      width: calc( (100% - 15px) / 2 )
