@font-face
  font-family: 'AvenirNextCyr-Light'
  src: url('/build/fonts/AvenirNext/AvenirNextCyr-Light.ttf')
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'AvenirNextCyr-Regular'
  src: url('/build/fonts/AvenirNext/AvenirNextCyr-Regular.ttf')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'AvenirNextCyr-Medium'
  src: url('/build/fonts/AvenirNext/AvenirNextCyr-Medium.ttf')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'AvenirNextCyr-Demi'
  src: url('/build/fonts/AvenirNext/AvenirNextCyr-Demi.ttf')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'AvenirNextCyr-Bold'
  src: url('/build/fonts/AvenirNext/AvenirNextCyr-Bold.ttf')
  font-weight: 700
  font-style: normal
