.documents-status
  padding: 22px 35px 22px 18px
  +max($mobile)
    padding: 15px

  &__item
    display: flex
    align-items: center
    margin-bottom: 22px
    
    &:last-child
      margin-bottom: 0

  &__nums
    margin-left: auto
  
  &__time
    margin-right: 20px

  &__color
    border: 2px solid #D2E1F5
    border-radius: 50%
    +size(14px)
    margin-right: 20px
    +max($mobile)
      margin-right: 10px

    &.blue
      background-color: #3EB5F1

    &.grey
      background-color: #BEC7D0

    &.yellow
      background-color: $mainYellow
      
    &.green
      background-color: #52AC62

    &.orange
      background-color: #E7A580

    &.red
      background-color: $mainRed

  &__name
    font-size: $smallFontSize
    color: $mainBlack
    +max($mobile)
      font-size: 13px

  &__nums
    font-size: $smallFontSize
    color: $mainBlack
    opacity: .7
    +max($mobile)
      font-family: $avenirNextMedium
      font-size: 12px 
      color: rgba($mainBlack, .6)
      padding: 5px 6px
      border-radius: 7px
      background-color: #E8F2FF

  &__time
    font-size: 12px
    color: $mainBlack
    opacity: 0.58
    