.index
    &-title
        font: $extraLargeFontSize / 1 $avenirNextMedium
        color: $darkBlue
        margin-bottom: 70px
        text-align: center
        width: 100%
        +max($laptop)
            margin-bottom: 50px
        +max($mobile)
            margin-bottom: 25px
            font-size: $bigFontSize
            font-family: $avenirNextDemi 
    &-page
        background-color: #F5FCFA
        .header
            background-color: transparent
            position: absolute
            top: 0
            width: 100%
            border-bottom: 1px solid rgba(255, 255, 255, 0.5)
            .news-nav__link
                color: rgba(255, 255, 255, .8)
                &:hover
                    color: $mainWhite
                    &::after
                        background-color: $mainWhite
            &-search-button
                i
                    color: $mainWhite
            .search
                .form-control
                    background-color: $mainWhite
                    color: $darkBlue
                    &::placeholder
                        color: $darkBlue
            &-container
                &__right
                    .button_small.button_small_secondary
                        background-color: transparent
                        border-color: $mainWhite
                        .material-icons,
                        .material-icons-outlined
                            color: $mainWhite
                        @media not all and (pointer: coarse)                        
                            &:hover
                                background-color: $mainWhite
                                .material-icons,
                                .material-icons-outlined
                                    color: $hoveredMainGreen

            .lang
                &__now
                    color: $mainWhite
            .btn-wrap.btn-wrap--no-margin
                .auth-btn
                    color: $mainWhite
                    background-color: transparent
                    border-color: $mainWhite
                    .material-icons,
                    .material-icons-outlined
                        color: $mainWhite
                    @media not all and (pointer: coarse)
                        &:hover
                            color: $hoveredMainGreen
                            background-color: $mainWhite
                            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25)
                            .material-icons,
                            .material-icons-outlined
                                color: $hoveredMainGreen
            .nav-btn
                color: $mainWhite                
        .notification-preview,
        .dialogs-preview
            &:hover
                background-color: rgba($mainWhite, .5)
            i
                color: $mainWhite
            > a
                color: $mainWhite
            &__title
                i
                    color: $mainBlue   

            

        .header-container.header-login
            .cabinet-preview
                &__element
                    span
                        color: $mainWhite
                        +max($mobile)
                            display: none