#driver-popover-item
  position: relative
  .driver-popover-tip.top,
  .driver-popover-tip.left
    &:before
      content: ''
      box-shadow: 0px 0px 6px 3px #25e859
      position: absolute
      width: 8px
      height: 8px
      background-color: #07D9B4
      border-radius: 50%
      display: flex
  .driver-popover-tip.top
    &:before
      top: -10px
      left: -5px

  .driver-popover-tip.left
    &:before
      top: -5px
      left: -10px

  .driver-popover-footer
    margin-top: 20px
    .driver-btn-group
      .driver-prev-btn
        background-color: $mainWhite
        color: $mainBlack
        border: 1px solid $mainGrey
        min-height: 36px
        padding: 0 20px
        font: $smallFontSize $avenirNextMedium
        +flex-center()
        min-width: 120px
        border-radius: 5px
        transition: .3s ease-in-out
        position: relative
        outline: none
        text-shadow: unset
        &:hover
          background-color: $hoveredGrey
          box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2)
      .driver-next-btn
        min-height: 36px
        border: 1px solid $mainGreen
        background-color: $mainGreen
        padding: 0 20px
        color: $mainWhite
        font: $smallFontSize $avenirNextMedium
        +flex-center()
        min-width: 120px
        border-radius: 5px
        transition: .3s ease-in-out
        position: relative
        outline: none
        text-shadow: unset
        &:hover
          color: $mainWhite
          text-decoration: none
          border: 1px solid $hoveredMainGreen
          background-color: $hoveredMainGreen
          box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2)

    .driver-close-btn
      position: absolute
      top: 10px
      right: 10px
      background: transparent
      border: 2px #ACB6DB solid
      border-radius: 50%
      width: 20px
      height: 20px
      line-height: 1
      display: flex
      justify-content: center
      align-items: center
      padding: 0
      i
        font-size: 15px
        line-height: 1
        color: #ACB6DB
        z-index: -1