.my-service-list
  display: grid
  overflow-wrap: anywhere
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 30px
  +max($desktop)
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 20px

  +max($mobile)
    grid-template-columns: 1fr


  .my-service,
  .no-my-service
    width: 100%
  .cab-exp-ins__item
    margin-bottom: 60px
    display: flex
    flex-wrap: wrap
  &.user-booked-services-list
    .my-service__info.my-service__info-full
      .my-service
        &__left,
        &__right
          width: 100%
  .my-service
    display: flex
    justify-content: flex-start
    flex-direction: column
  .no-my-service
    display: flex
    justify-content: space-between
    flex-direction: column

.my-service
  $t: &

  position: relative
  border: 1px solid $halfGrey
  background-color: $mainWhite
  padding: 30px
  border-radius: 5px
  +max($mobileM)
    padding: 15px
  .tag
    margin-bottom: 6px
    color: $mainBlue
    font-family: $avenirNextDemi
    font-size: $extraSmallFontSize
  &__info-wrap
    margin: 30px 0
  &__left-wrap
    + #{$t}__left-wrap
      margin-top: 30px
  &__right-wrap
    + #{$t}__right-wrap
      margin-top: 30px
  &__name
    color: $mainBlack
    font-size: $regularFontSize
    font-family: $avenirNextDemi
    line-height: 20px
    margin-bottom: 20px
    +max($mobile)
      font-size: $smallFontSize
  &__desc
    color: $textGrey
    font-size: $extraSmallFontSize
    font-family: $avenirNextRegular
    margin-bottom: 20px

  &__details
    margin-bottom: 20px
    display: flex
    align-items: center
    justify-content: space-between
    &-col
      display: flex
      align-items: center
      i
        margin-right: 10px
        padding: 7px
        border-radius: 50%
        background-color: $lightGrey
      &:first-child
        margin-right: 10px
  .button_regular
    margin-top: auto

  .author
    margin-bottom: 5px

    &__pic
      +size(30px)
      min-width: 30px

      img
        +size(100%)

    &__specializtion
      color: $mainBlack
      opacity: .7
  
  .client
    margin: 0 auto

    &:after
      content: ''
      border-bottom: 1px $halfGrey solid
      position: absolute
      width: 100%
      height: 2px
      left: 0
      right: 0
    
    &__service-name
      color: $mainBlack
      font: $regularFontSize $avenirNextMedium

    &__pic
      +size(90px)
      +flex-center
      margin: -65px auto 0
      border: 1px $halfGrey solid
      border-radius: 50%

      img
        +size(100%)
        border-radius: 50%

    &__name
      padding-top: 20px
      font: $regularFontSize $avenirNextMedium
      color: $mainBlack
      text-align: center
      +flex-center

    &__desc
      margin: 0 auto
      width: 200px
      flex-wrap: wrap
      +flex-center
      padding-bottom: 20px

    &__nickname
      font-size: $smallFontSize
      color: $mainBlack
      opacity: .7
      padding-top: 5px

    .client-btn
      position: absolute
      right: 0
      top: 20px
  
  .client-info
    padding-top: 20px

    &__address,
    &__phone-namber,
    &__email,
    &__doc-namber,
    &__doc-date
      color: #9E9E9E
      font: $smallFontSize $avenirNextMedium
      span
        color: $mainBlack
    &__doc-namber,
    &__doc-date,
    &__text
      padding-top: 10px

    &__text
      font: $regularFontSize $avenirNextMedium
      color: $mainBlack

    &__phone-namber,
    &__email
      padding-top: 15px

  &__info
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    &--column
      flex-direction: column
      margin-top: auto

    &-title
      color: $mainBlack
      font: $smallFontSize $avenirNextMedium
      text-overflow: ellipsis
      overflow: hidden

    &-desc
      color: $darkBlue
      font-family: $avenirNextDemi
      font-size: $extraSmallFontSize

  &__right,
  &__left
    width: calc(100% / 2 - 15px)
  .context-menu
    position: absolute
    top: 20px
    right: 15px
  .modal-info
    width: 130px

.btn-wrap.btn-wrap--end.btn-wrap--mt-auto
  &.btn-wrap--service  
    .button_regular
      width: 100%
      margin-right: 30px
    .btn-wrap.btn-wrap--mt-none.btn-wrap--end
      width: auto
      .button_demi
        + .button_demi
          margin-left: 10px

.main-dropdown.my-servise__modal
  .my-service__info
    flex-direction: column
    &-subtitle
      font-size: $demiFontSize
      +max($mobile)
        font-size: $smallFontSize
    &-title
      color: #4B7DAD
      font-family: $avenirNextLight
      font-size: $demiFontSize
      width: 120px
      +max($mobile)
        font-size: $extraSmallFontSize
    &-desc
      color: $mainBlack
      font-family: $avenirNextMedium
      font-size: $demiFontSize
      +max($mobile)
        font-size: $extraSmallFontSize
  .my-service__right-wrap,
  .my-service__left-wrap
    margin-top: 12px
    margin-bottom: 0
    display: grid
    grid-template-columns: 100px 1fr
    grid-column-gap: 20px