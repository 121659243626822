.dashboard-map
    &-holder
        height: 507px
        width: 100%
        position: relative
    &-form
        position: absolute
        right: 30px
        top: 45px
        padding: 30px
        border: 1px solid $borderGrey
        background-color: $mainWhite
        box-shadow: 0 10px 20px 0 rgba(46,91,255,0.07)
        border-radius: 8px
        z-index: 1
        width: 100%
        max-width: 330px 
        &__close
            display: none
            +max($mobile)
                width: 30px
                height: 30px
                display: flex
                justify-content: center
                align-items: center
                border-radius: 50%
                position: absolute
                right: 20px
                z-index: 1
                top: 5px
                border: 1px solid #D2E1F5
                i
                    font-size: $demiFontSize
        &__wrapper
            display: flex
            justify-content: space-between
            width: 100%
            align-items: baseline
            margin-bottom: 25px
            .form-group
                display: flex
                flex-direction: column
                margin-right: 30px
                .select-custom
                    min-width: 100px
                &:last-child
                    margin-right: 0
                input
                    min-height: 50px
                    border: 1px solid #C6D7EF
                    background-color: $mainWhite
                    border-radius: 6px
                    padding: 0 10px 0 20px
                    width: 100%
        .form-group
            label
                font-size: $regularFontSize
                font-family: $avenirNextMedium
                margin-bottom: 4px
                color: $darkBlue
            + .form-group
                margin-top: 20px
        .select-custom .select2-container .select2-selection--single
                min-height: 50px !important
        .select2-selection__rendered
            line-height: 38px !important
        .btn-wrap
            margin-top: 25px
