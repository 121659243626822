.nav-btn
  background-color: transparent
  position: relative
  border: 0
  font: $regularFontSize $avenirNextMedium
  color: $darkBlue
  padding: 0
  display: flex
  align-items: center
  &.main-dropdown-hide,
  &.menu-btn
    +max($mobile)
      display: none

  img
    margin-left: 10px
    width: 24px
    height: 24px
    pointer-events: none
.close-btn
  background-color: transparent
  border: 0
  padding: 0
  font-size: $demiFontSize
  align-items: center
  justify-content: center
  margin-left: auto
  img
    width: 24px
    height: 24px
  .material-icons,
  .material-icons-outlined
    color: $mainGrey
  &-absolute
    position: absolute
    top: 10px
    right: 10px
    +max($mobile)
      right: 20px


.main
  &-nav
    margin-bottom: 50px
    +max($mobile)
      margin-bottom: 30px
    &__item
      margin-bottom: 37px
      &:last-child
        margin-bottom: 0
      +max($mobile)
        margin-bottom: 20px
    &__link
      color: $mainBlack
      transition: .3s ease-in-out
      &:hover
        color: $mainGreen
        text-decoration: none
      +max($mobile)
        font-size: $bigFontSize
        color: $darkBlue
        font-family: $avenirNextDemi
  &-socials
    margin-bottom: 50px
    $t: &
    &-wrapper
      display: flex
      align-items: center
    &__item
      +size(40px)
      border-radius: 50%
      border: 1px solid $mainGrey
      background-color: $mainWhite
      transition: all .3s
      + #{$t}__item
        margin-left: 15px
      &:hover
        background-color: $hoveredGrey
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1)
    &__link
      +flex-center()
      +size(100%)
      text-decoration: none
      &:hover
        text-decoration: none
      img
        +size(28px)
        min-width: 28px
        max-width: 100%
    &__mail
      display: flex
      text-decoration: none
      color: $mainGreen
      font-family: $avenirNextDemi
      font-size: $regularFontSize
      transition: .3s ease-in-out
      margin-bottom: 20px
      &:hover
        text-decoration: underline
        color: $mainGreen


  &-footer
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    &__item
      font-family: $avenirNextMedium
      font-size: $smallFontSize
      color: $mainBlack
      a
        text-decoration: none
        &:hover
          text-decoration: none
          color: $mainBlack

.MuiListItem-button
  & a
    text-decoration: none

    &:hover
      color: unset