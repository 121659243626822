.dynamics-block
  $t: &

  &__wrap
    display: grid
    width: 100%
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 30px
    margin-top: 24px
    +max($mobile)
      grid-template-columns: 1fr 1fr
    +max($mobileL)
      grid-template-columns: 1fr
  &__item
    text-decoration: none
    +max($mobile)
      width: 100%
      margin-right: unset
    background-color: $mainWhite
    border: 1px solid $halfGrey
    border-radius: 10px
    overflow: hidden
    &-wrap
      display: flex
      flex-direction: column
      height: 100%
      width: 100%
      padding: 20px
    &:nth-child(3n)
      margin-right: 0
      +max($mobile)
      &:nth-child(3n)
        margin-right: 30px
      &:nth-child(2n)
        margin-right: 0
        +max($desktop)
        width: calc((100% - 60px) / 3)
        +max($mobile)
        width: 100%
        margin-right: 0
      &:nth-last-child
        margin-bottom: 0
      &:nth-child(3n)
        margin-right: 0
    &:hover
      text-decoration: none
      color: $mainBlack
  &__header
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
    color: $mainBlack
    &:hover
      color: $mainBlack

  &__body-button-active
    color: $mainBlack

  &__meta
    display: flex
    width: 100%
    grid-gap: 15px
    justify-content: space-between
    margin-top: 10px
    & .arrow-container
      display: flex
      color: $secondaryGrey
      height: 30px
      width: 65px
      grid-gap: 5px
      & .left-arrow
        background: $mainWhite
        border: 1px solid $borderGrey
        box-sizing: border-box
        border-radius: 5px
        width: 30px
        display: flex
        align-items: center
        justify-content: center
      & .right-arrow
        background: $mainWhite
        border: 1px solid $borderGrey
        box-sizing: border-box
        border-radius: 5px
        width: 30px
        display: flex
        align-items: center
        justify-content: center
  &__body
    width: 100%
    position: relative
    height: 100%
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 20px

  &__btn
    +max($mobile)
      margin-left: 0!important

  &__chart
    width: 100%
    position: relative
    height: 100%
    display: flex
    align-items: flex-end

  &__subtitle
    word-break: break-all

.dynamics-small-block
  width: 100%
  display: grid
  grid-template-columns: repeat(5, 1fr)
  grid-column-gap: 30px
  grid-row-gap: 30px
  margin-bottom: 30px
  +max($mobile)
    grid-template-columns: repeat(3, 1fr)
  +max($mobileL)
    grid-template-columns: repeat(2, 1fr)
  &__item
    border-radius: 10px
    border: 1px solid #C6D7EF
    overflow: hidden
    position: relative
    background-color: $mainWhite
    display: flex
    flex-direction: column
    justify-content: space-between
  &__title
    padding: 5px 10px 0px
    font-family: $avenirNextRegular
    font-size: $smallFontSize
  &__description
    font-family: $avenirNextMedium
    padding-left: 10px
    margin-bottom: 20px
  &__subdescription
    font-family: $avenirNextRegular
  &__list
    padding: 20px
    border-radius: 10px
    border: 1px solid #C6D7EF
    overflow: hidden
    position: relative
    background-color: $mainWhite
    display: flex
    flex-direction: column
    justify-content: space-between

.dynamics-big-block
  display: flex
  flex-wrap: wrap
  width: 100%
  background: transparent
  &__wrapper
    display: flex
    flex-direction: column
    margin-bottom: 30px
    padding: 20px 30px
    width: 100%
    position: relative
    border-radius: 10px
    background: $mainWhite
    border: 1px solid #C6D7EF
    .select-custom
      max-width: 420px
      position: relative
      margin-bottom: 15px
      +max($mobile)
      right: unset
      +max($mobile)
      overflow-y: hidden
  &__header
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center

  &__item
    width: 100%
    +max($mobile)
    overflow-x: auto
    overflow-y: hidden

    &__value
      margin-right: 5px
      &:before
        background-color: $mainGreen

  &__top-block
    display: flex
    width: 100%
    flex-wrap: wrap
    align-items: center
    justify-content: space-between

  .dynamics-slider
    width: calc(100% - 260px)
    margin-top: 20px
    display: flex
    flex-wrap: wrap
    align-items: center
    position: relative

    &__wrap
      display: flex
      width: 660px
      overflow-x: hidden

    &__graphic-name
      cursor: pointer
      font-size: $regularFontSize
      font-family: $avenirNextMedium
      color: $mainBlack
      padding: 10px 20px
      margin-right: 10px
      border-radius: 5px
      height: 36px
      display: flex
      align-items: center
      white-space: nowrap
      line-height: 1

      &.active
        background-color: #F1F6FF

    &__btn-wrap
      display: flex
      flex-wrap: wrap
      position: absolute
      right: 0

  .explanation
    width: 100%
    order: 2
    display: flex
    justify-content: center
    align-items: center
    margin: 20px 0
    &__value,
    &__norm
      display: flex
      align-items: center
      text-transform: uppercase
      color: $mainBlack
      font-size: 10px
      padding: 2px 6px
      background-color: $mainWhite
      border: 1px solid $halfGrey
      border-radius: 20px
      &-mark
        width: 8px
        height: 8px
        display: flex
        margin: auto
        border-radius: 50%
        margin-right: 5px
        &.value
          background-color: $mainGreen
        &.norm
          background-color: $halfGrey
    &__value
      margin-right: 10px
.indicators-entered
  $f: &

  display: flex
  flex-direction: column

  &__title
    font-family: $avenirNextMedium
    margin-bottom: 15px

  &__header
    padding: 25px 0

  &__header-wrap
    display: flex
    width: 100%

  &__header-item
    width: calc(100% / 4)

  &__body
    width: 100%

  &__body-item

    + #{f}__body-item
      margin-bottom: 10px

      display: flex
      flex-wrap: wrap
      width: 100%
      border-left: 4px $mainBlack solid
      background-color: $mainWhite
      padding: 15px 20px 15px 15px
      border-radius: 5px
      +max($mobile)
        padding: 10px

  &__entered-values
    display: flex
    flex-wrap: wrap
    align-items: center
    width: calc(100% / 4)

.measure-date
  #measure_group_values_date_date
  + #measure_group_values_date_time
    margin-top: 10px
  select
    border: 1px #d2e1f5 solid
    background-color: transparent
    height: 30px
    margin-right: 5px
    &:nth-child(2n),
    &:nth-child(3n)
      margin-left: 5px
    &:after
      content: '3'
      display: block

.measure-time
  display: flex
  width: 100%
  justify-content: space-between
  .measure-select
    width: calc(100% / 3 - 10px)

.tooltip-inner
  background-color: $mainWhite
  border: 1px solid $borderGrey
  color: $darkGrey
  font-size: 12px
  padding: 10px

.tooltip
  &.bs-tooltip-left
    & .arrow
      &:before
        border-left-color: $mainWhite !important
  &.bs-tooltip-right
    & .arrow
      &:before
        border-right-color: $mainWhite !important
  &.bs-tooltip-bottom
    & .arrow
      &:before
        border-bottom-color: $mainWhite !important
  &.bs-tooltip-top
    & .arrow
      &:before
        border-top-color: $mainWhite !important

.big-chart-tooltip
  background-color: #fff

.dynamic-create-modal .main-dropdown-content
  display: flex
  flex-direction: column

.measures-input-fields
  display: grid
  grid-template-columns: 1fr 1fr
  column-gap: 20px
  & .form-group
    align-self: self-end
