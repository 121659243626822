.profile
  display: grid
  grid-template-columns: 330px 1fr 330px
  grid-column-gap: 30px
  padding-top: 30px
  position: relative
  +max($laptopM)
    grid-template-columns: 100%

  &-additional-info,
  &-about,
  &-info
    min-height: 100px
  &-additional-info
    display: flex
    flex-direction: column
    +max($laptopM)
      margin-top: 30px

  &-about
    +max($laptopM)
      margin-top: 30px

  .profile-section
    width: 100%

  .profile-counters
    display: flex
    border-bottom: 1px solid $borderGrey
    height: 70px
    &__item
      width: 33.33%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      & + .profile-counters__item
        border-left: 1px solid $borderGrey
    &__value
      font: $demiFontSize $avenirNextBold
      color: $darkBlue
    &__title
      font: $extraSmallFontSize $avenirNextMedium
      color: $textGrayDarker

  &-section
    background: $mainWhite
    border-radius: 8px
    border: 1px solid $borderGrey

    & + .profile-section
      margin-top: 30px
    &__title
      color: $mainBlack
      font: $demiFontSize $avenirNextLight
      cursor: pointer
      padding: 0 30px
      height: 65px
      border-bottom: $borderGrey 1px solid
      align-items: center
      display: flex
      flex-wrap: nowrap
      justify-content: space-between
      width: 100%
      +max($laptopM)
        font-size: $mediumFontSize
      .material-icons
        transform: rotate(0)
        transition: transform 300ms ease
    &__content
      overflow-wrap: anywhere
      max-height: none
      overflow: hidden
      transition: max-height 300ms ease-in
    &.animation
      .profile-section__content
        overflow-wrap: anywhere
        max-height: 500
    &__wrapper
      padding: 20px 30px
      font: $regularFontSize $avenirNextRegular
      color: $mainBlack
      +max($mobile)
        padding: 20px 15px
      .expert-preview
        padding-bottom: 0
        &__hide
          position: relative
          visibility: visible
          opacity: 1
          border: 1px solid $halfGrey
          border-top: none
          box-shadow: none
          +max($laptop)
            padding-bottom: 20px
    &__empty
      font-size: $regularFontSize $avenirNextRegular
      color: $mainBlack
      text-align: center

    &.closed
      .profile-section__content
        max-height: 0
        transition: max-height 300ms ease-out
      .profile-section__title
        .material-icons
          transform: rotate(180deg)
    &__read-more
      color: $mainBlue
      font: $regularFontSize $avenirNextDemi
      margin-top: 20px
      display: block
      &:hover
        color: $hoveredMainBlue
        text-decoration: none
