.company__page
    &-heading
        display: flex
        justify-content: flex-start
        align-items: center
        &--info
            display: flex
            flex-direction: column
            margin-left: 35px
            +max($mobile)
                margin-left: 10px
        &--title
            font-size: $largerFontSize
            font-family: $avenirNextDemi
            color: $mainBlack
            +max($mobile)
                font-size: $mediumFontSize
        &--subtitle
            font-size: $mediumFontSize
            font-size: $avenirNextMedium
            color: $darkGrey    
            +max($mobile)
                font-size: $extraSmallFontSize