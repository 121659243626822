.wardship
    &-list
        max-height: 380px
        overflow-y: auto
        margin-right: -16px
        padding-right: 16px
        &__item
            border: 1px solid $halfGrey
            border-radius: 2px
            display: flex
            justify-content: space-between
            transition: .3s background-color ease-out
            &:hover
                background-color: $lightGrey
            &:first-child
                margin-top: 0
            &-container
                width: 100%
                padding: 15px 12px
                display: flex
            &-wrapper
                max-width: 100%   
                display: flex
                flex-direction: column
                justify-content: center
            &-link
                color: $mainBlack
                text-decoration: none   
                font-size: $minimalFontSize
                &:focus,
                &:active,
                &:hover,
                &:visited    
                    text-decoration: none 
                    color: $mainBlack
            &-img
                width: 40px
                height: 40px
                min-width: 40px
                margin-right: 10px 
            &-info
                font-size: $minimalFontSize
                &-row
                    display: flex
                    align-items: center
                    span
                        &:first-child
                            margin-right: 10px          