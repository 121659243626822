.cabinet-reviews
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  +max($mobile)
    width: 100%
  .btn-wrap
    width: 100%

  &__list
    width: 100%

  &__item
    margin-bottom: 30px