.breadcrumb
    background-color: transparent
    padding: 25px 0
    border-bottom: 1px solid $halfGrey
    margin-bottom: 0
    border-radius: 0
    +max($mobile)
        padding: 18px 0
    &__wrapper
        display: flex
        flex-wrap: wrap
        a
            color: $darkBlue
            text-decoration: none
            font-size: $regularFontSize
            font-family: $avenirNextLight
            +max($mobile)
                font-size: $minimalFontSize
            &:hover
                text-decoration: none
                color: $darkBlue
        span
            color: $darkBlue
            font-size: $regularFontSize
            font-family: $avenirNextRegular
            +max($mobile)
                font-size: $minimalFontSize
        .material-icons,
        .material-icons-outlined
            margin: 0 15px
            color: $darkBlue
            +max($mobile)
                font-size: 12px
                margin: 0 7px