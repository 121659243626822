.create-service
  border: 1px solid $halfGrey
  border-radius: 5px
  padding: 30px
  background-color: $mainWhite
  display: flex
  flex-direction: column
  +max($tablet) 
    width: calc( 100vw - 40px )
    padding: 20px
  +max($mobile) 
    padding: 20px
  &__wrap
    textarea
      height: 250px
      +max($mobile) 
        height: 150px
  &__info
    display: flex
    align-items: flex-end 
  &__duration
    .select-custom
      width: calc(100% / 2 - 15px)    
      +max($mobile) 
       width: calc(100% / 2 - 5px) 
  .form-group + .form-group
    +max($mobile) 
      margin-top: 20px
  .radio
    margin-bottom: 15px
  .btn-wrap--min-width 
    +max($mobile) 
      margin-top: 20px
      flex-direction: column
    .button
      +max($mobile) 
        min-width: 100%
        margin-left: 0
      &:first-child
        +max($mobile) 
          margin-bottom: 20px
  .checkbox__text
    transform: translateY(2px)