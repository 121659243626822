.dashboard-notifications
    .dashboard-item__content
        padding: 0
        overflow-Y: auto
        max-height: 333px
    &-item
        display: flex
        width: 100%
        align-items: baseline
        border-bottom: 1px solid $borderGrey
        margin-bottom: 10px
        padding-bottom: 10px
    &-date,
    &-message
        color: $mainBlack
    &-date
        font-size: 11px
        width: 45px
        margin-right: 15px
    &-message
        font-size: $smallFontSize
.dashboard-expert-notifications
    .dashboard-item__content
        max-height: 260px
                