.empty-list-message
  grid-column: 1/-1
  background: $mainWhite
  color: $darkBlue
  font: $regularFontSize $avenirNextLight
  display: flex
  justify-content: space-between
  padding: 30px
  border-radius: 8px
  border: 1px solid $borderGrey
  width: 100%
  +max($mobile)
    flex-direction: column
  .btn-wrap
    margin-top: 20px
  &__pic
    width: 33%
    +max($mobile)
      margin: 0 auto
    img
      max-width: 100%
      width: 100%
      display: block
      height: auto
  &__info
    width: 65%
    display: flex
    flex-direction: column
    +max($mobile)
      width: 100%
  &__title
    font: $biggerFontSize $avenirNextDemi
    color: $darkBlue
    margin-bottom: 20px
    +max($mobile)
      margin-bottom: 10px
      font: $regularFontSize $avenirNextDemi
  &__desc
    +max($mobile)
      font-size: $extraSmallFontSize
    ul
      counter-reset: listItems
      padding-left: 40px
      padding-top: 20px
      +max($mobile)
        padding-left: 30px
      li
        position: relative
        margin-bottom: 20px
        &:before
          counter-increment: listItems
          content: counter(listItems)
          display: inline-block
          position: absolute
          left: -40px
          background: $mainGreen
          border-radius: 50%
          color: $mainWhite
          font: $regularFontSize $avenirNextDemi
          text-align: center
          line-height: 24px
          +size(24px)
          +max($mobile)
            left: -30px
            +size(18px)
            font: $extraSmallFontSize $avenirNextDemi

.autocomplete
  &__input-wrapper
    position: relative

.disclaimer
  margin-bottom: 20px
  width: 100%
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  cursor: pointer
  &_ico
    color: $alertYellow
    font-size: 64px
    margin-right: 10px
  &_text
    font-size: 16px
    line-height: 1.4
