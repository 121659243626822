.result-experts
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-column-gap: 30px 
  grid-row-gap: 30px
  +max($desktop)
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 20px
  +max($laptopM)
    grid-template-columns: repeat(2, 1fr)
  +max($mobile)
    grid-template-columns: 1fr
  &__not-found
    margin-left: 15px
    &--title
      font-size: $bigFontSize
      color: $mainBlack
      font-family: $avenirNextRegular
      line-height: 30px
      +max($laptop)
        font-size: $mediumFontSize
      +max($mobile)
        font-size: $regularFontSize
  .expert-preview,
  .profile-serice
    width: 100%
    padding-bottom: 20px
    +max($mobile)
      min-width: 100%
      margin-right: 0
      margin-bottom: 20px
      &:last-child
        margin-bottom: 0
      &:not(:nth-child(6n))
        margin-right: 0

  + .btn-wrap
    margin-top: 30px
    +max($mobile)
      margin-top: 20px
    .btn
      &.btn--read-more
        +max($mobile)
          width: 100%
          max-width: 182px
          display: flex
          justify-content: center
          align-items: center
          padding: 8.5px 13px
      

  
  &.clinic-modify-list
    +max($mobile)
      grid-template-columns: repeat(2, 1fr)
    +max($mobileL)
      grid-template-columns: 1fr
    .company-category-item
      width: 100%
      margin-right: 0
      margin-bottom: 0
    &.search-experts-result
      +max($mobile)
        .expert-preview
          margin-top: 0
          margin-bottom: 0

.search-experts-result
  .button_regular
    +max($mobileM)
      width: 100%
  .expert-preview
    &__hide-footer
      .btn
        border: 1px solid $mainGreen
        width: calc(100%/2 - 5px)
        padding: 0 10px
        display: flex
        justify-content: center
        height: 40px
        background: #fff
        white-space: nowrap
        i
          display: none
        &:first-child
          width: calc(100% * 0.4138 - 5px)
          max-width: 120px
        &:last-child
          width: calc(100% * 0.5862 - 5px)
          max-width: 160px
.company-detail-info
  &__expert-request
    display: flex
    justify-content: center
    align-items: flex-start
    width: 100%
    margin-top: auto
    padding-top: 20px
    height: 100%
    max-height: 50px