.cabinet-mobile-menu
    display: none
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
    height: 100%
    &.active
        border-bottom: 1px solid $halfGrey
    .cabinet-sidebar
        &-block
            border: unset
            border-radius: none
            overflow-y: scroll
            -webkit-overflow-scrolling: touch
            height: 100%
        &-nav
            height: 50vh
            overflow-y: scroll
            -webkit-overflow-scrolling: touch
            &__item
                &.no-mobile
                    +max($laptop)
                        display: none
        