.add-document__wrap
    display: flex
    align-items: center
    width: 100%
    position: relative
    &-select
        width: 100%
        .select2-selection__rendered
            white-space: normal !important
            display: flex !important
            flex-wrap: wrap !important
            padding-right: 140px !important
            .select2-selection__choice
                white-space: normal
            .select2-selection__choice + .select2-selection__choice    
                margin-top: 5px 
            .select2-search__field
                width: 100% !important
        .select2-selection__arrow
            right: 170px !important
        &.add-document__state-documents
            li.select2-search
                width: 100%
            li[title=""]
                display: none
    .add-document
        border: unset
        &.add-document--expert
            overflow: hidden
            input
                width: 100%
                max-width: 80px
                overflow: hidden
    &.add-document-insurer-wrap
        .add-document 
            &.add-document--expert
                position: absolute
                width: unset
                right: 2px
                bottom: unset       
