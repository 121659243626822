.dashboard-monitoring
    &-item
        display: flex
        align-items: center
        width: calc(100% - 75px)
        &--title
            font-family: $avenirNextRegular
            color: $mainBlack
            font-size: $smallFontSize
            margin-bottom: 0
            width: 100%
            &.monitoring-item
                height: unset
                overflow: visible
                background-color: transparent
            span
                margin-left: 10px
            .monitoring-item__ico
                width: 16px
                height: 16px
                margin-left: 0
                i
                    font-size: $regularFontSize
    &-date
        font-family: $avenirNextRegular
        color: $mainBlack
        font-size: 11px
        text-align: right
        min-width: 75px