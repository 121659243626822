.study-system-button
  display: none
  position: fixed
  bottom: 180px
  right: 25px
  opacity: 0
  visibility: hidden
  z-index: 4
  &:hover
    cursor: pointer
  &:active
    background-color: #555
  &.show
    display: flex
    opacity: 1
    visibility: visible
.driver-default-item
  &__first
    .driver-popover-footer
      .driver-prev-btn
        display: none !important