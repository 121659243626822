.comments
  padding-top: 20px
  &__number
    color: $mainBlack
    opacity: .88
    font: 17px $avenirNextMedium
    margin: 20px 0
  &__title
    font: 24px $avenirNextMedium
    color: $darkBlue
    margin-bottom: 20px
  &-list
    margin-top: 40px
    padding-top: 40px
    border-top: 1px solid $borderGrey

.comment
  display: flex
  + .comment 
    margin-top: 20px
  &__pic
    +size(58px)
    min-width: 58px
    border: 1px solid $mainBlue
    border-radius: 50%
    margin-right: 20px
    overflow: hidden
    img
      max-width: 100%
      object-fit: cover
  &-add
    display: flex
    align-items: flex-start
    &__pic
      +size(58px)
      min-width: 58px
      border: 1px solid $mainBlue
      border-radius: 50%
      margin-right: 20px
      overflow: hidden 
      img
        max-width: 100%
        object-fit: cover  
      +max($mobile)
        +size(40px)
        min-width: 40px
        margin-right: 10px
  &__body
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    width: calc(100% - 40px - 14px)
  &__name
    color: $mainBlack
    margin-right: 20px
  a.comment__name
    color: $darkBlue
  &__date
    color: $mainGrey
  &__message
    margin-top: 13px
    padding: 16px
    background-color: $commentGrey
    font-size: $regularFontSize
    font-family: $avenirNextLight
    line-height: 20px
    border-radius: 4px
    width: 100%
    position: relative
    &-arrow
      width: 0
      height: 0
      position: absolute
      top: -16px
      left: 25px
      border-left: 12px solid transparent
      border-right: 12px solid transparent
      border-bottom: 18px solid $commentGrey


    