
.notification-preview,
.dialogs-preview
  +size(40px)
  transition: all .2s
  border-radius: 50%
  +max($mobile)
    +size(unset)
    margin-right: 10px

  &:hover
    background-color: #E0E7FF
    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.1)

  > a
    display: flex
    color: $mainWhite
    +size(100%)
    justify-content: center
    align-items: center
    transition: all .2s
    text-decoration: none

    &:hover
      color: $darkBlue
      text-decoration: none


  .dialogs-list
    + .btn-wrap
      padding: 10px 0 20px

    .dialogs-item
      border-bottom: 1px solid #C8DFF8
      padding: 15px 10px
      position: relative
      cursor: pointer

      &:last-child
        border-bottom: 0

      &:hover
        background-color: #EDF4FF

      &__message
        padding-right: 30px

      &__ellips
        color: $mainWhite
        +size(18px)
        background-color: #3A64DB
        border-radius: 50%
        font-size: $smallFontSize
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        right: 10px
        top: 34px
        padding-bottom: 2px