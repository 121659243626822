.expert-preview
  height: 100%
  padding-bottom: 30px
  position: relative
  display: flex
  flex-direction: column
  border: 1px solid $halfGrey
  border-radius: 10px
  background-color: $mainWhite
  max-width: 350px
  +max($laptop)
    justify-content: space-between
  &__link:hover
    text-decoration: none
  &__header
    display: flex
    width: 100%
    padding: 30px
    max-height: 125px
    height: 100%
    +max($mobile)
      align-items: center
  @media not all and (pointer: coarse)
    &:hover
      z-index: 2
      border-color: $mainGreen
      box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25)
      transition: box-shadow .3s ease-in-out
  &__wrap
    display: flex
    flex-direction: column
    align-items: center
    margin-right: 16px
  &__heart
    transition: .3s ease-in-out
    transform: scale(1)
    +size(30px)
    background-color: transparent
    +flex-center()
    position: absolute
    left: 15px
    top: 15px
    z-index: 1
    transform: scale(0)
    transition: .3s ease-in-out
    +max($laptop)
      transform: scale(1)
    .addFavorite
      background-color: transparent
      outline: none
      border: none
  &__pic
    position: relative
    margin-bottom: 2px
    img
      border: 2px solid $mainBlue
      +size(60px !important)
      min-width: 60px
      border-radius: 50%
      object-fit: cover
    .online,
    .offline
      border-radius: 50%
      +size(16px)
      border: 1px solid $mainWhite
      background-color: $mainGreen
      display: block
      position: absolute
      bottom: 0px
      left: 4px
    .offline
      background-color: $mainGrey
  &__status
    font-size: $extraSmallFontSize
    position: relative
    display: flex
    flex-direction: column
    text-align: center
    max-width: 80px
    .status-offline
      color: $mainGrey
      font-family: $avenirNextLight
      font-size: $minimalFontSize
    .status-online
      color: $mainGreen
      font-family: $avenirNextBold
      font-size: $minimalFontSize
  &__name
    font: $regularFontSize $avenirNextMedium
    color: $mainBlack
    line-height: 20px
    margin-bottom: 12px
    display: block
    &:hover
      text-decoration: none
      color: $mainBlack
    +max($mobile)
      margin-bottom: 5px
      font-size: $regularFontSize
  &__specialization
    font-size: $extraSmallFontSize
    font-family: $avenirNextMedium
    line-height: 15px
    color: $darkBlue
    overflow: hidden
    display: block
    position: relative
    transition: .3s ease-in-out
    +max($laptop)
      font-size: $smallFontSize
      margin-bottom: 5px

  &__body
    width: 100%
    margin-top: 16px
    display: flex
    flex-direction: column
    >.desk-expert__body
      display: flex
      flex-wrap: wrap
      flex-direction: column
    &.archive-preview__body
      height: 100%
  &__link-wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    width: 100%
    &:hover
      text-decoration: none

  &__statistic
    display: flex
    align-items: flex-start
    justify-content: space-between
    width: 100%
    padding: 15px 30px
    border-top: 1px solid $borderGrey
    border-bottom: 1px solid $borderGrey
    .statistic-block
      margin-top: 0
      flex-direction: column
      align-items: flex-start
      &__body
        display: flex
        align-items: center
        margin-top: 8px
        +max($mobile)
          margin-top: 4px
      &__desc
        font-size: $extraSmallFontSize
        color: $mainGrey
        font-family: $avenirNextMedium
      &__ico
        +size(unset)
        border-radius: unset
        background-color: transparent
        margin-right: 8px
        i
          width: unset
      &__result
        font-size: $extraSmallFontSize
        font-family: $avenirNextBold
  &__address
    font: $smallFontSize $avenirNextRegular
    color: $mainBlack
    padding: 20px 30px 0 30px
    min-height: 80px
    max-height: 100px
    height: 100%
    &-location
      display: flex
      flex-direction: column
      align-items: flex-start
    span
      font-size: $extraSmallFontSize
      font-family: $avenirNextLight
      color: $mainBlack
      &.place
        font-size: $extraSmallFontSize
        color: $mainBlack
        font-family: $avenirNextMedium
  &__company
    display: flex
    align-items: center
    &-logo
      +size(30px)
      display: flex
      min-width: 30px
      margin-right: 10px
    &-info
      display: flex
      flex-direction: column
      align-items: flex-start
    &-name
      font-size: $extraSmallFontSize
      font-family: $avenirNextMedium
      color: $hoveredMainBlue
  &__hide
    margin-top: 30px
    padding: 0 30px 0px 30px
    width: auto
    top: 0
    left: 1
    position: relative
    visibility: visible
    opacity: 1
    border: none
    box-shadow: none
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    +max($laptop)
      width: 100%
      padding: 20px 30px 0 30px
      border: none
      box-shadow: none
      opacity: 1
      position: static
      visibility: visible
    &-title
      font-size: $extraSmallFontSize
      color: $mainGrey
      font-family: $avenirNextMedium
      margin-bottom: 8px
    &-service
      font-size: $extraSmallFontSize
      font-family: $avenirNextLight
      color: $mainBlack
      span
        font-family: $avenirNextDemi
        white-space: normal
        color: $mainBlack
        display: inline-block
      + .expert-preview__hide-service
        margin-top: 5px
    &-footer
      display: flex
      justify-content: space-between
      align-items: flex-end
      height: 100%
      margin-top: 40px
      +max($laptop)
        margin-top: 20px
      +max($mobileL)
        .button_medium
          min-width: 90px
          padding: 0 15px
          &:first-child
            margin-right: 10px
      .button_medium
        min-width: auto
        &:first-child
          margin-right: 10px
  &__hiden
    z-index: 2
    padding: 0 30px 30px 30px
    width: calc(100% + 2px)
    position: absolute
    left: -1px
    right: 0
    top: calc(100% - 10px)
    pointer-events: auto
    border: 1px solid $mainGreen
    border-top: none
    background-color: $mainWhite
    border-radius: 0 0 10px 10px
    visibility: hidden
    opacity: 0
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25)
    transition: box-shadow .3s ease-in-out
    +max($laptop)
      width: 100%
      padding: 20px 30px 0 30px
      border: none
      box-shadow: none
      opacity: 1
      position: static
      visibility: visible
    &-title
      font-size: $extraSmallFontSize
      color: $mainGrey
      font-family: $avenirNextMedium
      margin-bottom: 8px
    &-service
      font-size: $extraSmallFontSize
      font-family: $avenirNextLight
      color: $mainBlack
      span
        font-family: $avenirNextDemi
        white-space: normal
        color: $mainBlack
        display: inline-block
      + .expert-preview__hide-service
        margin-top: 5px
    &-footer
      display: flex
      justify-content: space-between
      align-items: center
      margin-top: 40px
      +max($laptop)
        margin-top: 20px
      +max($mobileL)
        .button_medium
          min-width: 90px
          padding: 0 15px
          &:first-child
            margin-right: 10px
      .button_medium
        min-width: auto
        &:first-child
          margin-right: 10px

  &:hover
    .expert-preview__hide,
    .expert-preview__hiden
      opacity: 1
      visibility: visible
      display: flex
      flex-direction: column

  &.expert-preview__clinic
    transition: box-shadow .3s ease-in-out
    .expert-preview__hide,
    .expert-preview__hiden
      transform: translateY(-6px)
      +max($laptop)
        transform: translateY(0px)
      &-footer
        margin-top: auto
        padding-top: 20px
    &:hover
      transition: box-shadow .3s ease-in-out
      border-radius: 10px
  .company-detail-info__expert-request
    +max($mobile)
      margin-top: 10px
