.select-custom
  &__desc
    font: $regularFontSize $avenirNextMedium
    color: $mainBlack
    white-space: nowrap
    +max($mobile)
      margin-top: 10px
      margin-bottom: 20px

    span
      color: $mainBlack
      font-family: $avenirNextDemi
      margin: 0 4px



  .ui-slider
    width: 100%
    max-width: 330px
    height: 6px
    background-color: #D2E1F5
    border-radius: 5px
    border: none
    position: relative

  .ui-slider-range
    width: 100%
    background-color: $mainGreen
    height: 6px
    border-radius: 5px
    position: absolute

  .ui-slider-handle
    +size(24px)
    border-radius: 50%
    background-color: $mainGreen
    border: 4px solid $mainWhite
    display: block
    position: absolute
    top: 50%
    transform: translateY(-50%)
    cursor: pointer
    z-index: 1

    &:focus
      outline: none

    