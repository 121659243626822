.cabinet
  .fc
    &.fc-theme-standard
      .fc-scrollgrid,
      th, td
        border-color: $textGreyLight !important
      th
        z-index: 1
      .fc-day-other
        .fc-daygrid-day-number
          color: $mainGrey
        &.fc-day-past
          background-color: $commentGrey
        &.fc-day-future
          background-color: $lightGrey
    .fc-daygrid-day
      &.fc-day-sat,
      &.fc-day-sun
        .fc-daygrid-day-number
          color: $mainRed
      &-number
        font-size: $extraSmallFontSize
        font-family: $avenirNextBold
        padding: 10px 10px 0 0
      &-bottom
        margin: 0 8px 8px 8px
        .fc-daygrid-more-link
          color: $mainBlue
          font-size: $minimalFontSize
          font-family: $avenirNextRegular
    .fc-timegrid-event .fc-event-main
      width: 100%
      font-size: 12px
    .fc-toolbar.fc-header-toolbar
      padding: 18px 30px 16px 30px
      border-bottom: 1px solid $borderGrey
      margin-bottom: 25px
      +max($mobile)
        padding: 18px 15px 16px 15px
    .fc-view-harness
      margin: 0 30px 0 30px
    .fc-col-header-cell-cushion
      min-height: 50px
      +flex-center()
      background-color: $lightGrey
      font-size: $smallFontSize
      font-family: $avenirNextDemi
      color: $darkGrey
    .fc-col-header-cell .fc-day
      .fc-day-sat,
      .fc-day-sun
        .fc-col-header-cell-cushion
          color: $mainRed
    .fc-button
      &.fc-button-primary,
      &.fc-button-active
        &:focus,
        &:active
          box-shadow: none !important
          outline: none
          background-color: transparent
    &-toolbar
      &-chunk
        &:first-child
          div
            display: flex
            align-items: center
            .fc-button-primary
              border-radius: 50%
              +size(30px, 30px)
              min-width: 30px
              background-color: $mainWhite
              border: 1px solid $mainGreen
              padding: 0
              +flex-center()
              color: $mainGreen
              &:hover
                  border: 1px solid $hoveredMainGreen
                  color: $hoveredMainGreen      
              .fc-icon
                font-size: 16px                            
            .fc-toolbar-title
              font-family: $avenirNextMedium
              font-size: $regularFontSize
              margin: 0 16px
              text-transform: capitalize
              +max($mobile)
                margin: 0 8px
        .fc-showMenu-button
          padding: 20
          background-color: $mainWhite
          border-radius: 25
          color: $hoveredMainGreen
          border: 1px solid $hoveredMainGreen
          &:hover,
          &:focus
            background-color: $hoveredMainGreen!important
            color: $mainWhite
            border: 1px solid $hoveredMainGreen
            transition: .2s ease
      .fc-icon.material-icons.material-icons-important
        font-family: 'Material Icons' !important

        &.icon-after-arrow
          &-left:after
            content: "arrow_back"

          &-right:after
            content: "arrow_forward"

          &-dots:after
            content: "event_note"

      .fc-center
        display: none
      .fc-left
        div
          display: flex

          h2
            margin-left: 20px
        +max($mobileL)
          width: 100%
          div
            justify-content: space-between
    .fc-event
      &-container
        pointer-events: none
      &-status-vacation,
      &-status-accepted,
      &-status-pending,
      &-status-reserved,
      &-status-complete,
      &-status-complete_with_conclusion,
      &-status-in_progress
        border-right: 0
        border-top: 0
        border-bottom: 0
        border-radius: 2px
        min-height: 24px
        margin: 0 8px 8px 8px !important
        padding: 5px
        font-size: $minimalFontSize
        font-family: $avenirNextMedium
        display: flex
        align-items: center
        overflow: hidden
        color: $mainWhite
        position: relative
        cursor: pointer
        +max($mobile)
          margin: 0 2px 5px 2px !important
        b
          margin-right: 5px
      &-status-vacation
        background: $mainBlue
        border-left: 2px $mainBlue solid
      &-status-accepted
        background: $mainGreen
        border-left: 2px $mainGreen solid
        &.custom-booking
          background-color: transparent
          border: 2px solid $mainGreen
          color: $darkBlue
          .fc-event-main
            color: $darkBlue
      &-status-pending,
      &-status-reserved
        background: $mainYellow
        border-left: 2px $mainYellow solid
      &-status-complete_with_conclusion,
      &-status-complete
        background: $mainGrey
        border-left: 2px $mainGrey solid
        .complete.without-conclusion
          position: absolute
          right: 4px
          top: 50%
          transform: translateY(-50%)
          font-size: 16px
          +size(16px,16px)
          min-width: 16px
          +flex-center()
          border-radius: 50%
          background-color: $mainRed
          .material-icons,
          .material-icons-outlined
            font-size: 12px
      &-status-in_progress
        color: $mainBlack
        background: $mainGreen
        border: 1px solid $borderGrey
        .fc-list-event-title,
        .fc-list-event-graphic
          border: none !important
      .fc-event-main
        position: static
    .fc-list
      .fc-list-event-title
        border: none
        font-size: 14px
      .fc-list-event-graphic
        border: none
        display: none
      .fc-event
        &-status-vacation,
        &-status-accepted,
        &-status-pending,
        &-status-reserved,
        &-status-complete,
        &-status-complete_with_conclusion,
        &-status-in_progress
          color: $mainBlack
          margin: 4px 8px 8px 8px !important
          border-top: 1px solid $textGreyLight
          border-bottom: 1px solid $textGreyLight
          border-right: 1px solid $textGreyLight
          border-left-width: 5px
          background: $mainWhite
        &.custom-booking
          border: 2px solid $mainGreen
        .complete
          &.without-conclusion
            color: $mainWhite
        &:hover
          box-shadow: 0 5px 10px rgba($mainBlack, 0.15)
    .fc-list-event:hover td
      background: rgba(0,0,0,0)
    .fc-view-container
      .fc-today
        background: none
        .fc-day-number
          background: $mainGreen
          margin: 5px 10px 5px 0
          border-radius: 5px
          +size(30px)
          color: $mainWhite
          +flex-center
          +max($mobileL)
            width: 15px
            height: 15px
            font-size: 12px

      .fc-day-number
        height: 30px
        color: $mainBlack
        margin: 5px 10px 5px 0
        padding: 0
        font-family: $avenirNextMedium
        +max($mobileL)
          margin: 5px 5px 5px 0
          font-size: 12px

      .fc-sat .fc-day-number,
      .fc-sun .fc-day-number
        height: 30px
        color: $mainRed
      .fc-day-header
        height: 50px
        +max($mobileL)
          font-size: $smallFontSize
          height: unset
        &.fc-sat,
        &.fc-sun
          span
            color: $mainRed
        span
          +flex-center
          height: 100%
          font-family: $avenirNextMedium
          font-size: $regularFontSize
          color: $mainBlack
          text-transform: capitalize
          background: $lightGrey
          font-weight: 500
      .fc-dayGrid-view .fc-body .fc-row
        +max($mobileL)
          min-height: unset
      .fc-other-month
        background: #F7F8FE
        &.fc-past,
        &.fc-future
          span
            font-family: $avenirNextRegular
      .fc-popover
        .fc-body.fc-widget-content
          white-space: normal
          .fc-event-container
            .fc-event
              &-status-vacation,
              &-status-accepted,
              &-status-pending,
              &-status-reserved,
              &-status-complete_with_conclusion,
              &-status-complete
                height: auto
                .fc-content
                  white-space: normal
.fc-more-cell
  a.fc-more
    margin: 0 2px
    padding: 0 4px
    border-radius: 5px
    background-color: rgba($mainGrey, .5)
    font-size: 12px
    font-family: $avenirNextMedium
    color: $mainBlack
    display: flex
    align-items: center

.fc
  +max($mobile)
    .fc-scrollgrid-sync-table
      height: auto !important
    .fc-scroller
      position: static !important
    .fc-dayGridMonth-view,
    .fc-timeGridDay-view,
    .fc-listMonth-view
      position: relative !important
    .fc-view-harness
      height: auto !important
    .fc-timegrid
      .fc-daygrid-body
        z-index: 1 !important
