.profile-services
  display: grid
  grid-template-columns: repeat(2, calc(50% - 15px))
  grid-column-gap: 30px
  grid-row-gap: 30px
  +max($desktop)
    grid-template-columns: 100%
  +max($laptopM)
    grid-template-columns: repeat(2, calc(50% - 15px))
  +max($mobile)
    grid-template-columns: 100%
.profile-service
  $t: &
  display: flex
  flex-direction: column
  flex-wrap: wrap
  border: 1px solid $halfGrey
  background-color: $mainWhite
  padding: 30px
  border-radius: 8px
  position: relative
  min-height: 180px
  cursor: pointer
  transition: box-shadow .3s ease-in-out
  +max($tablet)
    width: 100%
  &:hover
    transition: box-shadow .3s ease-in-out
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25)
    border-color: $hoveredMainGreen  
  
  .tag
    margin-bottom: 20px

  &__name
    font-size: $mediumFontSize
    font-family: $avenirNextMedium
    margin-bottom: 10px

  &__title
    font-size: $extraSmallFontSize
    font-family: $avenirNextMedium
    color: $textGrey
    margin-bottom: 18px

  &__service
    height: 54px
    width: 100%

    span
      display: block
      font-size: $smallFontSize
      font-family: $avenirNextMedium
  &__description
    margin: 0 0 15px 0
    font-size: $regularFontSize
    font-family: $avenirNextMedium
    color: $mainBlack
    // height: 40px
    overflow: hidden
    line-height: 20px
    transition: .3s ease-in-out
    &:hover
      color: $mainGreen
  &__wrap
    display: flex
    justify-content: space-between
    width: 100%
    align-items: flex-start
    margin-top: auto
    &-price,
    &-time
      color: $darkBlue
      align-items: center
      display: flex
      font-size: $extraSmallFontSize
      font-family: $avenirNextMedium
      .material-icons
        font-size: 16px
        +size(32px)
        min-width: 32px
        background-color: $lightGrey
        border-radius: 50%
        +flex-center() 
        margin-right: 8px   
