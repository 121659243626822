.company-review
    &__heading
        display: flex
        align-items: flex-start
        margin-bottom: 16px
        +max($tablet)
            margin-left: 10px
        +max($mobile)
            margin-left: 20px
    &__img
        width: 100px
        height: 100px
        min-width: 100px
        border: 2px solid $mainBlue
        border-radius: 50%
        margin-right: 20px
        overflow: hidden
        +max($tablet)
            width: 80px
            height: 80px
            min-width: 80px
        +max($mobile)
            width: 60px
            height: 60px
            min-width: 60px 
    &__name
        font-size: $demiFontSize
        font-family: $avenirNextMedium
        line-height: 25px
        color: $darkGrey
        max-width: 230px
    &__type
        font-size: $demiFontSize
        font-family: $avenirNextBold
        color: $mainRed
        &.positive
            color: $hoveredMainGreen
    &__body
        padding: 30px 35px
        border-radius: 4px
        background-color: $commentGrey
        position: relative
        &-arrow
            width: 0
            height: 0
            position: absolute
            top: -16px
            left: 36px
            border-left: 12px solid transparent
            border-right: 12px solid transparent
            border-bottom: 18px solid $commentGrey 
    &__date
        font-size: $smallFontSize
        color: $mainGrey
        font-family: $avenirNextMedium
        display: flex
        align-items: center       
    &__comment
        font-size: $bigFontSize
        color: $mainBlack
        font-family: $avenirNextLight
        line-height: 29px
        word-break: break-word
        max-width: 100%              
    &__name,
    &__type,
    &__comment
        +max($laptopM)
            font-size: $regularFontSize
        +max($mobileL)
            font-size: $smallFontSize
