.visit
    background: $mainWhite
    border-radius: 8px
    border: 1px solid $borderGrey
    + .visit
        margin-top: 30px
    &__heading
        padding: 20px 30px
        color: $mainBlack
        font-size: $bigFontSize
        font-family: $avenirNextLight
        border-bottom: 1px solid $borderGrey    
    &__body
        padding: 30px
    &__conclusion
        font: $demiFontSize $avenirNextLight
        color: $mainBlack
        margin-bottom: 20px
    &__file-list
        display: flex
        flex-wrap: wrap
        li
            width: calc((100% - 25px)/2)
            margin-right: 25px
            margin-bottom: 20px
            margin-top: 0
            +max($mobile)
                width: 100%
                margin-right: 0
            &:nth-of-type(2n)
                margin-right: 0
                margin-top: 0
        .one-document
            &__name
                font: $smallFontSize $avenirNextMedium
                color: $mainBlack
            &__desc
                +max($mobile)
                    max-width: calc(100% - 82px)

            &__date,
            &__space
                font: $smallFontSize $avenirNextLight
                color: $mainBlack
            &__pic
                +max($mobile)
                    display: none
            &__delete i,
            &__download i
                color: $mainGrey


    &__row
        display: grid
        grid-template-columns: 250px auto
        grid-column-gap: 40px
        +max($mobile)
            grid-template-columns: 1fr
            grid-column-gap: 20px

        + .visit__row
            margin-top: 20px
        &-left
            min-width: 30%
            margin-right: 40px
            font-family: $avenirNextLight
            font-size: $demiFontSize
            color: $darkBlue
        &-right
            font-family: $avenirNextMedium
            line-height: 24px
            font-size: $demiFontSize
            color: $darkBlue
            display: flex
            span[data-role="status-text"]
                font-family: $avenirNextBold
                font-size: $demiFontSize
                color: $mainGrey
            span[data-role="check"]
                width: 24px
                height: 24px
                border-radius: 50%
                background: $mainGrey
                color: $mainWhite
                margin-right: 8px
                font-size: 20px
                +flex-center()
            &.status-complete.booking-without-conclusion
                span[data-role="check"]
                    background: $mainRed
                span[data-role="status-text"]
                    color: $mainRed
            &.status-pending
                span[data-role="check"]
                    background: $alertYellow
                span[data-role="status-text"]
                    color: $alertYellow
            &.status-accepted
                span[data-role="check"]
                    background: $hoveredMainGreen
                span[data-role="check"]
                    color: $hoveredMainGreen

    .video-wrap + .video-wrap
            margin-top: 25px