.recovery-passaword
  height: 100vh

  &__wrap
    width: 100% !important
    .form-group-pass-wrapper
     + .form-group-pass-wrapper
       margin-top: 30px
  &__form-wrap
    width: 440px
    margin: 10% auto auto auto
    justify-content: center!important
    +max($mobile)
      width: calc( 100% - 30px )
      padding: 30px 10px!important
    img
      width: 200px
      height: 50px
      margin: 0 auto 30px
    .show-pass
      img
        margin: unset

  &__title
    font-size: $mediumFontSize !important
    margin-bottom: 30px !important
    +flex-center
    
  .create-form .btn-wrap--mt
    margin-top: 35px