.company-gallery
  transform: translateY(-100%)
  margin: 10px 0 15px
  visibility: collapse
  transition: max-height .7s, all .5s ease-in-out
  opacity: 0
  height: 0
  z-index: 0
  overflow-x: hidden
  overflow-y: auto

  &.active
    transform: translateY(0%)
    visibility: visible
    transition: max-height .7s, all .5s ease-in-out
    opacity: 1
    height: 270px
    z-index: 0

  &__img
    margin-bottom: 5px
    height: 240px
    width: calc((100% - 16px) / 5)
    +max($tablet)
      width: calc((100% - 16px) / 3)
    +max($mobile)
      width: calc((100% - 16px) / 2)
    +max($mobileL)
      width: 100%

.img_width
  width: 100%
  margin-top: 10px

.gallery
  &__list
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 30px 20px
  &-item
    &__img
      width: 100%
      max-width: 480px
      max-height: 170px
      height: 100%
      object-fit: contain
      margin-bottom: 10px
    &__container
      display: flex
      flex-direction: column
    &__title
      font-size: var(--regularFontSize)
      line-height: 1.5
      font-weight: 700
    &__date
      font-size: 14px
      line-height: 1.5
