.all-documents
  width: 100%
  
  &__list
    display: flex
    justify-content: space-between
    flex-wrap: wrap

  &__item
    width: 100%
    + .all-documents__item
      margin-top: 10px
