.content-wrap
  h1
    font: $largeFontSize $avenirNextBold
    color: $darkGreen
    margin-bottom: 20px
    +max($mobile)
      font-size: $bigFontSize
      margin-bottom: 20px

  h2
    font-size: $demiFontSize
    font-family: $avenirNextDemi
    color: $darkGreen
    margin-top: 50px
    margin-bottom: 20px

  h3
    font-family: $avenirNextBold
    font-size: $bigFontSize

  h2,
  h3
    +max($mobile)
      font-size: $demiFontSize
      margin-bottom: 20px
      margin-top: 20px

  p
    font-size: $regularFontSize
    color: $mainBlack
    font-family: $avenirNextRegular
    line-height: 24px
    +max($mobile)
      font-size: 15px

    + p,
    + ul
      margin-top: 20px

  li
    font-size: $regularFontSize

    + li
      margin-top: 20px

  ul
    padding-left: 60px
    +max($tablet)
      padding-left: 60px
    @media(max-width: 400px)
      padding-left: 30px

    + p
      margin-top: 20px

    li
      position: relative
      //font:
      &:before
        text-align: center
        border-radius: 50%
        background-color: #1ea579
        margin-right: 12px
        content: ''
        position: absolute
        top: 2px
        left: -48px
        display: block
        background-image: url('/build/images/general/baseline-done-24px.svg')
        background-repeat: no-repeat
        background-position: center
        +size(28px)
        background-size: 90%
      @media(max-width: 400px)
        &:before
          +size(18px)
          left: -30px
    
  em
    color: $darkGreen
    
table
  &.table
    td
      +max($mobileL)
        padding: .25rem
      p
        +max($mobileL)
          font-size: 9px
