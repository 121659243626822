html,
body
  font-size: $regularFontSize
  font-family: $avenirNextRegular, sans-serif
  -webkit-font-smoothing: antialiased
  height: 100%
  color: $mainBlack
  background-color: $mainWhite
  &.active
    overflow: hidden
    // position: fixed
    width: 100%
.wrapper
  display: flex
  flex-direction: column
  background-color: $mainWhite
  &.wrapper-base
    background-color: $lightGrey
.content
  flex: 1 0 auto

.container
  margin: 0 auto
  max-width: $contentSize
  width: 100%
  padding: 0 15px
  +max($mobile)
    padding: 0 15px

.title
  font: $largeFontSize $avenirNextDemi
  color: $mainBlack
  +max($mobile)
    font: $mediumFontSize $avenirNextDemi
  &.title_mb
    margin-bottom: 30px
    word-wrap: break-word

.section
  + .section
    padding-top: 120px



.blocks-wrap
  display: flex
  justify-content: space-between
  margin-top: 16px
  .forgot-password
    color: $mainBlue
    text-decoration: none
    &:hover
      text-decoration: none
      color: $hoveredMainBlue
.form-group
  .checkbox__text
    color: $mainBlack
.form-group
  position: relative

.form-group>span,
.form-group>.form-group-wrapper>span
  font-size: 12px
  position: static
  color: $mainRed
  width: 400px
  z-index: 1

.notification-services
  position: fixed
  right: 25px
  bottom: 100px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  height: 64px
  width: auto
  padding: 0
  margin: 0
  border: 2px #FFA2A2 solid
  z-index: 99
  background: $mainWhite
  &:hover
    text-decoration: inherit
  +max($laptop)
    width: 64px
    &.active
      display: none  

  &__text
    padding: 0 20px
    color: #FF3C3C
    font-size: $mediumFontSize
    font-family: $avenirNextMedium
    +max($laptop)
      display: none

  &__icon
    background-color: #FFEBEB
    width: 64px
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    height: 100%

    i
      color: $mainRed
      border: 2px $mainRed solid
      border-radius: 50%
      padding: 5px
      &:hover
        color: $mainRed
textarea
  resize: none
section
  position: relative  
button:focus
  outline: none
label
  margin-bottom: 0

.has-click
  position: relative
  &::after
    content: ''
    position: absolute
    display: block
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 1

.accordion
  &__header
    cursor: pointer
  &__header.active i
    transform: rotate(-180deg)
    transition: transform .2s ease-out
  &__body
    max-height: 0
    overflow: hidden
    transition: max-height .3s ease-out