.profile-info
  .profile-person
    width: 100%
    background: $mainWhite
    border-radius: 8px
    overflow: hidden
    margin-bottom: 30px
    +max($mobile)
      margin-left: -15px
      margin-right: -15px
      width: auto
    &__top
      position: relative
    &__pic
      position: relative
      img
        width: 100%
        height: auto
        max-height: 100%
        max-width: 100%
      &:after
        content: ""
        width: 100%
        height: 100%
        display: block
        position: absolute
        left: 0
        top: 0
        background: $imageCover
    &__statuses
      padding: 0 30px
      +flex-content()
      position: absolute
      top: 30px
      left: 0
      width: 100%
      .favorite-study
        color: $mainWhite
      .net-status
        font: $regularFontSize $avenirNextMedium
        color: $mainWhite
        text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25)
        .status-ico
          width: 22px
          height: 22px
          border: 2px $mainWhite solid
          background: $textGrey
          display: inline-block
          vertical-align: middle
          margin-right: 10px
          border-radius: 50%
        &.online
          color: $hoveredMainGreen
          .status-ico
            background: $hoveredMainGreen

    &__info
      position: absolute
      bottom: 30px
      padding: 0 30px
    &__name
      font: $mediumFontSize $avenirNextDemi
      color: $mainWhite

    &__specialization
      font: $smallFontSize $avenirNextMedium
      color: $mainGrey
      opacity: 0.9
    &__actions
      padding: 30px

