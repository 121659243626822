.tab-search-form
  padding: 15px
  border-radius: 8px
  border: 1px solid $borderGrey
  background: $mainWhite
  display: flex
  margin-bottom: 20px
  .form-group
    width: 100%
    position: relative
    input::-webkit-search-cancel-button
      position: relative
      right: 40px
    .search__button
      display: none
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 5px
      .material-icons
        color: $mainGrey
      +max($tablet)
        display: block
  .btn-wrap
    margin-left: 20px
    +max($tablet)
      margin-left: 0
    button
      min-width: 130px
      +max($tablet)
        display: none
