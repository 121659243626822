.find-filter
  padding: 20px 30px 25px 30px
  background-color: $mainWhite
  border: 1px solid $borderGrey
  border-radius: 8px
  +max($mobile)
    padding: 20px
  label
    color: $darkBlue
    font-size: $regularFontSize
    margin-bottom: 2px
  &__name
    width: 100%
    grid-column: 1/3
    margin-bottom: 10px
    +max($mobileL)
      grid-column: unset

    @media(min-width: $tablet) and (max-width: $desktop)
      grid-column: 1/4

  &__price
    display: flex
    align-items: center
    width: 100%

  &__list
    display: grid
    grid-template-columns: repeat(5, 1fr) max-content
    grid-gap: 15px
    +max($desktop)
      grid-template-columns: repeat(3, 1fr)
    +max($tablet)
      grid-template-columns: repeat(2, 1fr)
    +max($mobileL)
      grid-template-columns: 1fr
    .select-custom
      width: 100%
      +max($mobileL)
        min-width: 100%
        width: 100%
      input::placeholder,
      input.select2-search__field::placeholder
        font-size: $regularFontSize
        font-family: $avenirNextLight!important
        color: $lightBlue!important
      &__desc
        margin-top: unset
        margin-bottom: unset
    .ui-slider-handle
      border: none
      +size(16)
    &.flex-filter
      justify-content: space-between
      width: 100%
  &__status
    display: flex
    align-items: center
  &__range
    display: flex
    flex-direction: column
    align-self: flex-start
    +max($mobileL)
      transform: translateY(0)
    .select-custom-wrapper
      display: flex
      flex-direction: column
      align-items: baseline
      justify-content: flex-start
    .ui-slider-wrapper
      display: flex
      align-items: center
      height: 50px
    .ui-slider
      max-width: calc( 100% - 24px )
      transform: translateX(10px)
      +max($mobileL)
        margin-bottom: 9px
  &__button,
  &__bottom_button
    margin-top: 26px
    flex-direction: column
    align-items: flex-end
    transition: .4s ease-out
    +max($tablet)
      grid-column: 1/3
    +max($mobileL)
      margin-top: unset
      grid-column: unset
    button
      min-width: 190px
      +max($desktop)
        min-width: 240px
      +max($tablet)
        width: 100%

  &__bottom_button
    margin-bottom: 20px

.button-hide,
.start-block,
.monitoring-block
  transition: .2s ease-out

.button-hide,
.start-block
  opacity: 0

.monitoring-block,
.start-block
  margin-top: -50px

.start-block
  margin-top: 0px

.monitoring-block
  opacity: 1
