.transaction
  $t: &
  display: flex
  align-items: center
  justify-content: space-between
  border: 1px solid $halfGrey
  background-color: $mainWhite
  padding: 20px
  border-radius: 5px
  + .transaction
    margin-top: 10px
  &.cabinet-item
    +max($laptopM)
      display: flex
      flex-wrap: wrap
    +max($mobile)
      padding: 15px   

  &__ico
    +size(34px)
    +flex-center()
    margin-right: 20px
    background-color: #E4EDFE
    border-radius: 50%
    min-width: 34px
  
  &__body
    display: flex
    align-items: center
    flex-wrap: wrap
    width: 100%
  &-body-wrapper
    display: flex
    align-items: flex-start  
    margin-bottom: 4px

  &__title
    color: $mainBlack
    font: $regularFontSize $avenirNextMedium
    margin-right: 12px
    +max($mobile)
      font-size: 15px
      margin-bottom: 5px

  &__date
    color: rgba($mainBlack, .7)
    font-size: 12px
    padding-top: 4px

  &__message
    font: $smallFontSize $avenirNextMedium
    opacity: 0.8
    color: $mainBlack
    width: 100%
    +max($mobile)
      font-size: 13px
  
  &__tag
    background-color: #D2E1F5
    font-size: 12px
    font-family: $avenirNextMedium
    padding: 0 12px
    max-width: 145px
    min-height: 20px
    border-radius: 5px
    color: $mainBlack
    
    &.ready
      background-color: #CBF7E9

    &.cancel
      background-color: #FFB9B9

  &__last
    text-align: right
    margin-left: auto
    font-family: $avenirNextDemi
    font-size: $smallFontSize
  &-body-wrapper
    +max($laptopM)
      display: flex
      width: 100%
      justify-content: space-between   
  &-status-wrapper
    display: flex
    align-items: center
    justify-content: flex-end
    max-width: 210px
    width: 100%
    +max($laptopM)
      padding-left: 54px
      max-width: 100%
      margin-top: 15px  

  &.red
    #{$t}__ico
      color: $mainRed

    #{$t}__last
      color: $mainRed

  &.green
    #{$t}__ico
      color: $mainGreen

    #{$t}__last
      color: $mainGreen
  &-wrap
    margin-top: 30px  
      
  