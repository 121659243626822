.news-nav
  display: flex
  align-items: center
  width: 100%
  border: 1px solid $borderGrey
  border-radius: 8px
  padding: 0 8px
  +max($tablet)
    overflow-x: auto
  &__wrapper
    width: 100%
    display: grid
    min-height: 50px
    grid-template-columns: 1fr 330px
    grid-gap: 50px
    margin-bottom: 50px
    max-width: 100vw
    overflow: hidden
    +max($laptopM)
      grid-template-columns: 1fr
      grid-gap: 15px
    +max($mobile)
      margin-bottom: 20px
    .search
      +max($laptopM)
        max-width: 100%
  &__item
    +flex-center()
    height: 100%
    &.dropdown
      transition: .3s ease-in-out
      +max($mobile)
      position: unset
      a:hover
        transition: .3s ease-in-out
        color: $mainGreen
      &[aria-expanded="true"]::after
        transition: transform .3s ease-in-out
        transform: rorate(180deg)
      .nav-link
        height: 100%
        padding: 0 30px
        +flex-center()
        &::after
          margin-left: 6px
        + .dropdown-menu
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
          border-radius: 4px
          border: 1px solid $borderGrey
          .dropdown-item
            padding: 11px 15px
            transition: .3s ease-in-out
            position: relative
            &:hover
              color: $mainGreen
              background-color: transparent
              transition: .3s ease-in-out
            &:not(:last-child)::after
              content: ''
              display: flex
              width: calc(100% - 30px)
              justify-content: center
              align-items: center
              height: 1px
              background-color: $borderGrey
              position: absolute
              left: 15px
              bottom: -1px
            &.active
              color: $mainGreen
              background-color: transparent
  &__link
    height: 100%
    padding: 0 15px
    +flex-center()
    position: relative
    color: $darkGrey
    +max($laptopM)
      padding: 15px 30px
      white-space: nowrap
    +max($mobile)
      padding: 15px 20px
    &:hover
      color: $darkGrey
      text-decoration: none
      &:after
        left: 0
        right: 0
        opacity: 1
    &.active
      color: $mainGreen
      &:hover
        color: $mainGreen
      &:after,
      &:hover:after
        left: 0
        right: 0
        opacity: 1
        background-color: $mainGreen
    &:after
      border-radius: 2px 2px 0 0
      content: ''
      height: 3px
      position: absolute
      bottom: 0
      background-color: $darkGrey
      border-radius: 5px
      left: 50%
      right: 50%
      transition: all .3s ease-in-out
      opacity: 0
