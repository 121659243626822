.nav-dropdown
  flex-direction: column
  justify-content: space-between
  max-height: 100vh
  height: 100%
  overflow: hidden auto
  padding-top: 20px
  min-height: 200px
  +max($mobile)
    padding-top: 0
    justify-content: unset
    overflow-y: auto
          