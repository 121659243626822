.patient-card
    display: flex
    flex-direction: column
    height: 100%
    border-radius: 8px
    background-color: $mainWhite
    border: 1px solid $borderGrey
    position: relative
    .context-menu
        position: absolute
        right: 31px
        top: 30px

    &__wrapper
        display: flex
        align-items: center
        gap: 10px


    &__heading
        display: flex
        align-items: center
        justify-content: space-between
        padding: 20px 30px
        position: relative
        border-bottom: 1px solid $borderGrey
        &-pic
            +size(50px)
            border-radius: 50%
            min-width: 50px

            position: relative

            .status
                +size(16px)

                border: 1px solid $mainWhite
            img
                border-radius: 50%
                border: 2px solid $mainBlue
                display: block
                +size(50px)
            .online,
            .offline
                border-radius: 50%
                +size(16px)
                border: 1px solid $mainWhite
                background-color: $mainGreen
                display: block
                position: absolute
                bottom: 0px
                left: 4px
            .offline
                background-color: $mainGrey

        &-name
            font-size: $smallFontSize
            font-family: $avenirNextMedium
            color: $darkBlue
            text-decoration: none
            padding-right: 60px
            &:hover
                color: $darkBlue
                text-decoration: none
    &__status
        font-size: $extraSmallFontSize
        position: relative
        display: flex
        flex-direction: column
        text-align: center
        max-width: 80px
        .status-offline
            color: $mainGrey
            font-family: $avenirNextLight
            font-size: $minimalFontSize
        .status-online
            color: $mainGreen
            font-family: $avenirNextBold
            font-size: $minimalFontSize


    &__body
        display: flex
        flex-direction: column
        height: 100%
        padding: 20px 30px
        &-info
            display: flex
            align-items: flex-start
            width: 100%
            flex-direction: column
            margin-bottom: 15px
            &--row
                justify-content: space-between
                flex-direction: row  
            &--title
                width: 100%
                font-size: $smallFontSize
                color: $textGrey
                font-family: $avenirNextMedium
                display: flex
                align-items: flex-start
                justify-content: space-between
            &--description
                font-size: $smallFontSize
                color: $mainBlack
                font-family: $avenirNextMedium
                width: 100%
                display: flex
                flex-wrap: wrap
                gap: 5px
                .client-tag
                    padding: 2px 4px
                    background: #b7b7b7
                    color: #2d2c2c
                    border-radius: 4px
                    word-break: break-word
                .hidden-tags .client-tag a
                    padding: 2px 4px
                    background: none
                .hidden-tags .client-tag-extra
                    padding: 2px 4px
                    background: #b7b7b7
                    color: #2d2c2c
                    border-radius: 4px
                    margin-right: 4px
                    margin-top: 4px
                    display: inline-block
                    word-break: break-word
                .hidden-tags .client-tag-extra:first-child
                    margin: 0px
                .client-tag:first-child
                    margin: 0px
                .client-tag-extra.hidden
                    display: none
                a.client-tag
                    color: #32D29F
                    background: transparent
                    cursor: pointer
                a.client-tag:hover
                    text-decoration: underline
                .tag
                    width: 100%
                    justify-content: space-between
                    max-width: unset
                    margin-bottom: 0
                    + .tag
                        margin-top: 5px
