.concilium-info
  display: flex
  align-items: flex-start
  justify-content: space-between
  +max($mobile)
    flex-direction: column
  &__video
    .concilium-video-item
    background: $mainWhite
    border: 2px solid $borderGrey
    border-radius: 8px
    padding: 30px
    + .concilium-video-item
      margin-top: 30px
    &__header
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 20px
    &__title
      font: $regularFontSize $avenirNextDemi
      color: $mainBlack
    &__date
      color: $secondaryGrey
    &__holder
      position: relative
      overflow: hidden
      margin-bottom: 20px
    &__video
      width: 100%
      border-radius: 7px
      position: relative
      z-index: 0
      display: block
    &__holder-wrapper
      position: absolute
      left: 0
      top: 0
      bottom: 0
      right: 0
      margin: auto
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 7px
      z-index: 1
      cursor: pointer
      &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        display: block
        background: $darkBlue
        opacity: 0.65
        z-index: 0
        +size(100%)

      &.active
        transition: all 1s ease-in-out
        z-index: -1
        opacity: 0
        visibility: hidden
        .concilium-video-item__play-btn
          transform: scale(1.2)
          transition: transform 1s ease-in-out
    &__play-btn
      background: $mainGreen
      border-radius: 50%
      z-index: 0
      +size(40px)
      +flex-center()
      img
        margin-left: 5px
        +size(30px)
  &__chat
    max-width: 330px
    width: 100%
    background-color: $mainWhite
    border-radius: 6px
    padding: 10px 0 10px 10px
    border: 1px solid $halfGrey
    +max($mobile)
      max-width: 100%
      margin-top: 30px
    .chating
      max-height: 548px
      .chat
        padding: 0
        &__wrapper
          display: flex
          flex-direction: column
          padding-right: 18px
          &-item
            min-width: 260px
            &-holder
              max-width: 260px
            + .chat__wrapper-item
              margin-top: 20px
            &.my-item
              align-self: flex-end
            &--name
              font-size: $smallFontSize
              color: $mainBlack
              font-family: $avenirNextMedium
              word-wrap: break-word
            &--message
              padding: 10px
              border-radius: 6px 6px 6px 0
              background-color: #EFF2FF
              margin: 5px 0
              font-size: $smallFontSize
              color: $mainBlack
              word-wrap: break-word
              &.my-message
                border-radius: 6px 6px 0 6px
            &--date
              font-size: 10px
              color: rgba(black, .6)
              font-weight: 300
      .message
        &__body
          padding-right: 10px
