.tabulator
    border: none
    background-color: $mainWhite
    +max($desktop)
        display: flex
        flex-direction: column
        width: 100%
        overflow-x: auto
        overflow-y: hidden
    &-tablewrapper
        padding: 20px 30px
        border-radius: 10px
        border: 1px solid #C6D7EF
        background-color: $mainWhite

        &__datatable
            display: flex
            width: 100%
            flex-direction: column

        &__header
            display: flex
            justify-content: space-between
            align-items: center
            min-height: 50px
            position: relative
            &-title
                font-size: $demiFontSize
                font-family: $avenirNextDemi
                color: $mainBlack
            .tabulator
                &-page-wrap
                    position: absolute
                    top: 0
                    right: 0
                    height: 100%
                    display: flex
                    align-items: center
                    label
                        color: $mainBlack
                        font-size: $smallFontSize
                        margin-bottom: 0
                        margin-right: 10px
                        font-family: $avenirNextMedium
                    .select2.select2-container.select2-container--default
                        min-width: 90px
    &-col-title
        font-size: $regularFontSize
        font-family: $avenirNextMedium
        color: $mainBlack
    &-data__wrapper
        display: flex
        flex-direction: column
        overflow: auto
        width: 100%
        background-color: $mainWhite
    & &-header
        background-color: $mainWhite
        padding: 30px 0 0
        border-bottom: none
        +max($desktop)
            display: flex
            width: 100%
        .tabulator-col
            background-color: #F1F6FF
            border: none
            padding: 0 10px
            cursor: pointer
            // interrupted the styles generated by the js library
            &:first-child
                width: 14px !important
                min-width: unset !important
                padding: 0
                +max($mobile)
                    padding: 0 20px 0 0
            &:nth-child(2)
                font-family: $avenirNextMedium
                padding: 0 10px 0 0
                +max($desktop)
                    padding: 0 20px 0 0
                +max($mobile)
                    min-width: 200px !important
                    width: 100% !important
                    font-family: $smallFontSize
            &:nth-child(3)
                font-family: $avenirNextRegular
                +max($mobile)
                    min-width: 100px !important
                    width: 100% !important
                    font-family: $extraSmallFontSize
            &:nth-child(4)
                +max($mobile)
                    min-width: 100px !important
                    width: 100% !important
            &:nth-child(5)
                +max($mobile)
                    min-width: 150px !important
                    width: 100% !important
            +max($desktop)
                padding: 0 20px 0 10
            + .tabulator-sortable
                position: relative
                &:hover
                    background-color: #F1F6FF
                &:before,
                &:after
                    direction: ltr
                    display: inline-block
                    font-family: 'Material Icons'
                    font-size: $bigFontSize
                    font-style: normal
                    font-weight: normal
                    letter-spacing: normal
                    line-height: 1
                    text-transform: none
                    white-space: nowrap
                    word-wrap: normal
                    -webkit-font-feature-settings: 'liga'
                    -webkit-font-smoothing: antialiased
                    position: absolute
                    right: 0
                    top: 0
            + .tabulator-sortable[aria-sort="desc"],
            + .tabulator-sortable[aria-sort="none"]
                &:before
                    content: 'arrow_drop_down'
            + .tabulator-sortable[aria-sort="asc"]
                &:after
                    content: 'arrow_drop_down'
                    transform: rotate(180deg)

        .tabulator-headers
            width: 100%
            height: 50px
            background-color: #F1F6FF
            display: flex
            justify-content: center
            align-items: center
            border-radius: 5px
            padding-right: 30px
            margin-left: -30px
            +max($mobileL)
                width: unset
    & .tabulator-tableHolder
        padding: 10px 0 20px 0
        background-color: $mainWhite
        &:focus
            border: none
        +max($desktop)
            display: flex
    & .tabulator-table
        display: flex
        flex-wrap: wrap
        background-color: $mainWhite
        .tabulator-row
            border: 1px solid $halfGrey
            border-radius: 5px
            overflow: hidden
            display: flex
            width: 100%
            &:not(:last-child)
                margin-bottom: 10px
            &:not(.tabulator-cell-actions)
                cursor: pointer
            &:hover
                .tabulator-cell
                    &.tabulator-cell-actions
                        display: flex
            .tabulator-cell
                border-right: none
                height: 100% !important
                min-height: 50px
                display: inline-flex
                align-items: center
                justify-content: flex-start
                padding: 0 10px
                font-family: $avenirNextDemi
                font-size: 15px
                color: $mainBlack
                // interrupted the styles generated by the js library
                &:first-child
                    width: 14px !important
                    padding: 0
                &:nth-child(2)
                    font-family: $avenirNextMedium
                    +max($mobileL)
                        width: 200px !important
                        font-family: $smallFontSize
                &:nth-child(3)
                    font-family: $avenirNextRegular
                    +max($mobileL)
                        width: 100px !important
                        font-family: $extraSmallFontSize
                &:nth-child(4)
                    +max($mobileL)
                        width: 100px !important
                &:nth-child(5)
                    +max($mobileL)
                        width: 180px !important
                .edit-values,
                .delete-values
                    color: $darkBlue
                    +flex-center()
                    text-decoration: none
                .delete-values
                    margin-left: 30px
                    color: $mainRed
                &:last-child
                    display: flex
                    justify-content: flex-end
                &.tabulator-cell-actions
                    display: none
                    +max($laptop)
                        display: flex
        .tabulator-row-even
            background-color: $mainWhite

        .tabulator-selectable
            &:hover
                background-color: $mainWhite
                border: 1px solid $mainGreen
                box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15)
    & &-col-resize-handle
        width: 0
    & &-footer
        border-top: none
        background-color: $mainWhite
        display: flex
        .tabulator-page
            display: inline-block
            +size(40px)
            font: $regularFontSize $avenirNextMedium
            background: transparent
            outline: none
            border: none
            color: $darkBlue
            margin-right: 5px
            border-radius: 5px
            &.active
                color: $darkBlue
                background-color: transparent
                border: 1px solid $mainGreen
            &:hover
                color: $mainWhite
                background-color: $mainGreen
            &:disabled
                display: none
    &-title
        top: 20px
        left: 30px
        position: absolute
        font: $demiFontSize $avenirNextMedium
        color: $mainBlack
