.expert-analyzes
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 30px
  +max($desktop)
    grid-template-columns: repeat(2, 1fr)
  +max($mobile)
    display: flex
    flex-direction: column

.analysis
  $t: &
  width: 100%
  display: flex
  flex-direction: column
  border: 1px solid $borderGrey
  border-radius: 8px
  background-color: $mainWhite
  min-height: 340px
  position: relative
  .context-menu   
    position: absolute
    right: 25px
    top: 25px
  &__head
    padding: 20px 60px 20px 30px
    display: flex
    align-items: center
    flex-wrap: wrap
    border-bottom: 1px solid $borderGrey
  &__fullname
    font-size: $extraSmallFontSize
    text-decoration: none
    &:hover
      text-decoration: none
      color: $mainBlack
  &__pic
    color: $mainBlack
    font-size: 15px
    font-family: $avenirNextMedium
    display: flex
    align-items: center
    img
      +size(50px)
      min-width: 50px
      border: 2px solid $mainBlue
      margin-right: 10px
      border-radius: 50%
      object-fit: cover
      display: block
  &__head-wrap
    display: flex
    justify-content: space-between
    width: 100%
  &__menu
    cursor: pointer
  &__client
    width: calc(100% - 55px)
    font: $regularFontSize / 22px $avenirNextMedium
    color: $mainBlack
  &__name
    color: $darkBlue
    line-height: 20px
    font-family: $avenirNextDemi
    font-family: $regularFontSize
    height: 100%
    margin-bottom: 20px
    overflow: hidden
  &__body
    display: flex
    flex-direction: column
    height: 100%
    padding: 20px 30px 20px 30px
  &__wrap
    width: 100%
    margin-bottom: 20px
  &__title,
  &__text
    font-family: $avenirNextMedium
    font-size: $smallFontSize
    word-break: break-word
  &__title  
    color: $textGrey
  &__text
    display: flex
    text-decoration: none
    &:hover
      color: $mainBlack
      text-decoration: none
    &.blue
      color: $mainBlue
      &:hover
        color: $mainBlue
        text-decoration: underline
    
  &__footer
    margin-top: auto
