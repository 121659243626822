.broadcasts-categories
  position: relative

  .prev
    left: -20px

  .next
    right: -20px
  
  .prev, .next
    top: 50%
    transform: translateY(-50%)
    
.broadcasts-category
  position: relative
  height: 280px
  display: block
  border-radius: 5px
  background-repeat: no-repeat
  background-size: cover
  color: $mainWhite
  
  &:hover
    color: $mainWhite
    text-decoration: none

  &:after
    content: ''
    display: block
    position: absolute
    bottom: 0
    right: 0
    left: 0
    top: 0
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, $darkBlue 100%)
    border-radius: 5px
    opacity: .5
    z-index: 0

  &__specialization,
  &__viewers
    z-index: 1

  &__viewers
    background-color: rgba($mainBlack, .6)
    border-radius: 4px
    display: flex
    align-items: center
    font: 12px $avenirNextMedium
    padding: 5px 10px
    position: absolute
    top: 20px
    left: 20px

    i
      font-size: $demiFontSize
      margin-right: 10px
  
  &__specialization
    position: absolute
    color: $mainWhite
    bottom: 28px
    left: 20px
    font: $bigFontSize $avenirNextDemi
    word-break: break-word
