.add-card
  +size(50px)
  border-radius: 50%
  +flex-center()
  background-color: $mainWhite
  border: 0
  padding: 0

  i
    color: $mainGreen
    font-size: 31px

.wallet
  &-wrap
    display: grid
    grid-template-columns: auto 330px
    grid-column-gap: 30px
    +max($mobile)
      flex-direction: column
      justify-content: flex-start
    .cabinet-content-title
      width: 100%
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 15px
      min-height: 30px

  &-content
    +max($mobile)
      width: 100%
      order: 2  

  &-cards
    width: 330px
    +max($mobile)
      width: 100%
      order: 1
      margin-bottom: 25px

  &-cards-list
    padding: 20px
    border: 1px solid $halfGrey
    background-color: $mainWhite
    border-radius: 10px
    +max($laptop)
      padding: 15px
    .btn
      width: 100%
      justify-content: center
      margin-top: 30px

  &-card
    border-radius: 6px
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    height: 180px
    padding: 20px
    box-shadow: 1px 5px 10px 0px rgba(0,0,0,0.1)
    + .wallet-card
      margin-top: 10px
    &.ms
      background: linear-gradient(71deg, #38BAA1 0%, #0FD0DA 100%)
    
    &.visa
      background: linear-gradient(71deg, #232E6E 0%, #0F35DA 100%)

    &__number-wrap
      margin-top: 55px
      width: 100%
      margin-bottom: 16px

    &__title
      color: $mainWhite
      font: 12px $avenirNextMedium
      opacity: 0.7

    &__number
      font: $mediumFontSize $avenirNextMedium
      color: $mainWhite
      margin-top: 5px

    &__name,
    &__date
      color: $mainWhite
      font: $smallFontSize $avenirNextMedium

.now-balance
  padding: 20px
  display: flex
  justify-content: space-between
  align-items: center
  border: 1px solid $halfGrey
  background-color: $mainWhite
  border-radius: 10px
  min-height: 100px
  + .now-balance 
    margin-top: 10px
  @media(max-width: 1250px)
    flex-wrap: wrap
    padding: 15px
  +max($mobile)
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    padding: 15px

  &__title
    color: $mainBlack
    font: $regularFontSize $avenirNextMedium
    margin-bottom: 5px
    +max($laptop)
      font-size: 15px
    +max($mobile)
      margin-right: 15px
      font-size: 15px  

  &__balance
    color: $darkBlue
    font: $bigFontSize $avenirNextBold
    +max($mobile)
      font-size: $mediumFontSize
      margin-top: 5px
    &.green
      color: $mainGreen
  &__left
    +max($mobile)
      display: flex
      margin-bottom: 10px
      flex-direction: column
    &-holder
      &--item
        @media(max-width: 1250px)
          margin-bottom: 15px    
  &__right
    display: flex
    align-items: center
    +max($laptop)
      width: 100%
    +max($mobileL)
      justify-content: space-between
    &-cashback--message
      max-width: 260px
      text-align: right
      font-size: $mediumFontSize
      font-family: $avenirNextRegular
      color: $mainBlack
