.dashboard-clients
  .dashboard-item__content
    display: flex
    align-items: flex-start
    width: 100%
    flex-wrap: wrap
    max-height: 685px
    min-height: 400px
    &--empty
      margin: auto
  .dashboard-client
    &__header
      width: 100%
      display: flex
      align-items: center
    &__item
      width: 100%
      border: 1px solid #E0E7FF
      background-color: #fff
      border-radius: 5px
      padding: 20px
      transition: .3s ease-in-out
      display: flex
      flex-wrap: wrap
      margin-bottom: 20px
      min-height: 217px
      &:last-child
        margin-bottom: 0px
      &:hover
        transition: .3s ease-in-out
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1)
    .dashboard-item__content
      flex-wrap: nowrap
      padding: 0
    &__role
      color: #3B3B3B
      font: 14px $avenirNextMedium

    &__name
      font-family: $avenirNextDemi
      font-size: 14px
      color: #000
      margin-bottom: 0
    &__avatar
      width: 44px
      min-width: 44px
      height: 44px
      background-color: #fff
      border-radius: 50%
      margin-right: 5px
      img
        border-radius: 50%
        max-width: 100%
        width: 100%
    &__content
      width: 100%
      display: flex
      flex-wrap: nowrap
      justify-content: space-between
      margin-top: 20px
    &__btn-wrapper
      width: 100%
      display: flex
      flex-wrap: nowrap
      justify-content: space-between
    &__secondary-btn
      width: calc( 50% - 5px)
      min-height: 36px
      background: #fff
      color: #000
      font: 14px $avenirNextMedium
      border: 1px solid #AFB9DD
      border-radius: 5px
      display: flex
      justify-content: center
      align-items: center
      text-decoration: none
      transition: all 0.3s ease
      &:hover
        text-decoration: none
        color: #000
        background: #E8E8E8
        box-shadow: 0 5px 10px rgba(0,0,0,0.2)

    &__main-btn
      width: calc( 50% - 5px)
      min-height: 36px
      background: #32D29F
      color: #fff
      font: 14px $avenirNextMedium
      display: flex
      align-items: center
      justify-content: center
      border-radius: 5px
      text-decoration: none
      cursor: pointer
      transition: all 0.3s ease
      &:hover
        text-decoration: none
        color: #fff
        background: #2BBB97
        box-shadow: 0 5px 10px rgba(0,0,0,0.2)
