.form-control
  display: flex
  background-color: transparent
  min-height: 50px
  padding: 0 20px
  border: 1px solid $textGreyLight
  border-radius: 2px
  font: $regularFontSize $avenirNextLight
  color: $darkBlue
  box-shadow: none
  transition: .3s ease-in-out
  width: 100%
  +max($mobile)
    padding: 0 10px
  &::placeholder
    color: $lightBlue
    font: $regularFontSize $avenirNextLight
  // only in mozilla we got trouble with placeholder vertical centering(autoprefixer has no power here :()
  &::-moz-placeholder,
  &:-moz-placeholder
    line-height: 48px !important
  &:focus
    border-color: $mainGreen
    box-shadow: none
    background-color: transparent
    outline: none
    &::placeholder
      opacity: 0
      color: transparent
    + .material-icons,
    + .material-icons-outlined
      color: $mainGreen
      transition: color .3s ease-in-out
  &:hover,
  &:focus
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25)
  &:hover:not(:focus)
    border-color: $textGreyLight
    color: $darkBlue
    &::placeholder
      color: $lightBlue
  &.form-control_disabled,
  &:disabled
    border-color: $borderGrey
    color: $lightBlue
    background-color: $backgroundGrey
    pointer-events: none
    font-family: $avenirNextLight
    &::placeholder
      color: $lightBlue

  + .material-icons,
  + .material-icons-outlined
    position: absolute
    right: 15px
    top: 0
    height: 100%
    +flex-center()
    color: $mainGrey
    transition: color .3s ease-in-out

.form-group
  $fg: &
  label,
  #{$fg}__label
    color: $darkBlue
    font-family: $avenirNextMedium
    font-size: $regularFontSize
    margin-bottom: 5px
    word-break: break-word
    +max($mobile)
      font-size: $regularFontSize
  & .dropdown-form__subtitle
    margin-bottom: 20px

  > span
    color: $mainRed
    &.checkbox__ico
      +max($mobile)
        margin-right: 10px
  &__error-list
    #{$fg}__error
      font-size: $minimalFontSize
      color: $mainRed
  &--fix-width
    width: 260px
  .input-calendar .form-control + .material-icons
    top: 15px
    font-size: 20px
    cursor: pointer
.form-errors
  li
    color: $mainRed
    font-size: 12px
textarea.form-control
  height: 160px
  padding: 15px 20px
  &::placeholder
    line-height: 1.5 !important

.has-pointer
  cursor: pointer