.cabinet-profile
    + .cabinet-profile
        margin-top: 30px
    &__body
        padding: 20px 30px
        border-radius: 0 0 8px 8px
        border: 1px solid $borderGrey
        background-color: $mainWhite
        margin-bottom: 30px
        &.itemGap
            display: grid
            grid-row-gap: 20px
            & .text-bolder 
                font-family: $avenirNextBold
            & .kved-list
                list-style-type: unset
                padding-left: 40px
        &:last-child
            margin-bottom: 0
        // todo: remake naming later
        .user-company-request + .user-company-request,
        .company-request-item + .company-request-item
            margin-top: 20px  
        // 
        +max($mobileL)
            padding: 15px    
        &.cabinet__body--full
            border-radius: 8px
        &-license
            display: flex
            flex-direction: column
            text-align: center
            justify-content: center
            align-items: center
            width: 100%
            &--title
                font-size: $biggerFontSize
                color: $darkBlue
                font-family: $avenirNextMedium
                margin-bottom: 40px
            &--desc
                font-size: $demiFontSize
                color: $darkBlue
                font-family: $avenirNextMedium
                line-height: 32px    
            &--row
                display: flex
                justify-content: space-between 
                align-items: flex-start   
            &--info
                display: flex
                flex-direction: column    
    &__heading
        padding: 20px 30px
        background-color: $mainWhite
        border: 1px solid $borderGrey
        border-bottom: 0
        border-radius: 8px 8px 0 0
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
        +max($mobileL)
            padding: 15px
        &-title
            font-size: $bigFontSize
            font-family: $avenirNextLight
            color: $mainBlack
            +max($mobileL)
                font-size: $demiFontSize
        &.temporary-profile__heading
            margin: 20px 0
            padding: 20px 0
            border-radius: 0
            border: none
            position: relative
            &:after,
            &:before
                position: absolute
                content: ''
                height: 1px
                width: calc(100% + 60px)
                background-color: $borderGrey
                left: -30px
            &:before 
                top: 0   
            &:after
                bottom: 0 
    &__langtabs-wrapper
        display: flex
        align-items: center
        +max($mobileL)
            div
                display: none 
    &__title
        margin-top: 22px         
    &__settings
        &-item
           margin-bottom: 20px
           +max($mobileL)
                margin-bottom: 15px
           .checkbox
                &__ico
                    +size(24px)
                    min-width: 24px
                    margin: 0 16px 0 0
                &__text
                    font-size: $regularFontSize
                    +max($mobileL)
                        font-size: 15px
           &:last-child
               margin-bottom: 0
    &__passwords
        display: flex
        flex-direction: column
        max-width: 400px
        width: 100%
    &__others-personal
        max-width: 550px
        display: flex
        align-items: baseline
        .form-group
            &:first-child
                width: 100%
                max-width: 300px
                margin-right: 30px 
                +max($mobileL)
                    max-width: 100%
                    margin-right: 0
    &__personal
        &-email
            max-width: 400px
        &-phone,
        &-sex
            max-width: 220px 
            +max($mobileL)
                max-width: 100%   
        &-adress
            max-width: 730px
            .form-group
                &:first-child
                    width: 100%
                    max-width: 400px
                    margin-right: 30px
                    min-height: 100px
                    +max($mobileL)
                        max-width: 100%
                        margin-right: 0
                &:last-child
                    min-height: 100px
        &-langs
            flex-direction: column
            align-items: flex-start
            .form-group
                &:first-child
                    max-width: 300px
                    +max($mobileL)
                        max-width: 100%
                &:last-child
                    label
                        margin-top: 15px      
            .button_borderless
                margin-top: 15px
        &-socials
            .personal-socials__link
                display: flex
                align-items: center
                margin-bottom: 10px
                &:last-child
                    margin-bottom: 0
                a:not(.links-effect)
                    padding-right: 8px
                .links-effect
                    font-family: $avenirNextMedium
                    font-size: $smallFontSize
                    color: $mainBlack
                    &:hover,
                    &:focus
                        color: $darkBlue                   
    &__company
        &-logo
            +size(100px, 100px)
            min-width: 100px
            max-width: 100%
            overflow: hidden
            border: 5px solid $mainBlue
        &-name,
        &-location
            max-width: 600px
        &-email
            max-width:  400px
    &__workinfo
        &-experience
            justify-content: flex-start
            .form-group
                &:first-child
                    max-width: 400px
                    margin-right: 30px
                    min-height: 100px
                    +max($mobileL)
                        margin-right: 0
                &:last-child
                    max-width: 220px
                    min-height: 100px
                    +max($mobileL)
                        max-width: 100%
        &-phone
            align-items: center
            justify-content: flex-start
            .form-group
                &:first-child
                    max-width: 220px
                    margin-right: 10px
                    +max($mobileL)
                        max-width: 185px 

    &__phones
        &:not(:first-child)
            margin-top: 22px
                                       