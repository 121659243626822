.modal-dialog
  &.modal-dialog--modified
    max-width: 600px
    border-radius: 4px
    .modal-header
      padding: 30px 40px
      border-bottom: 1px solid $borderGrey
      h5
        font: $bigFontSize $avenirNextMedium
        color: $mainBlack
      .close
        position: absolute
        right: 40px
        top: 30px
        cursor: pointer
        i
          color: $mainGrey
    .modal-body
      padding: 30px 40px
      font: $demiFontSize $avenirNextLight
      color: $mainBlack
    .modal-footer
      padding: 0 40px 40px
      border-top: none
      .btn-wrap
        margin-top: 0
        display: flex
        justify-content: center



