.schedule-dropdown
    &__datepicker-wrapper
        padding: 20px
        border: 1px solid $halfGrey
        border-radius: 5px
        .ui-widget.ui-widget-content.ui-datepicker
            transform: unset !important
            .ui-datepicker-calendar
                margin: 0 !important         
    &__wrapper
        margin-top: 30px
        min-height: 120px
        overflow: hidden
        position: relative
        &-title
            font-size: $mediumFontSize
            font-family: $avenirNextDemi
            margin-bottom: 15px
        &-preloader
            padding-top: 50px
            position: absolute
            top: 5%
            left: 0
            right: 0
            .spinner
                position: static
                margin: 0 auto
                display: block

    &-item
        border: 1px solid $halfGrey
        background-color: $mainWhite
        padding: 20px
        border-radius: 5px
        position: relative
        + .schedule-dropdown-item
            margin-top: 10px
        &:before
            transition: .3s ease-in-out
            +pseudoMaterial($el-width: 18px, $el-height: 18px, $content: 'keyboard_arrow_down',  $font-size: 18px)
            position: absolute
            right: 20px
            top: 20px
        .my-service
            &__name
                font-size: $regularFontSize
                font-family: $avenirNextDemi
                line-height: 20px
                color: $mainBlack
                display: none
            &__desc
                max-height: 55px
                height: 100%
                font-size: $smallFontSize
                color: $darkGrey
                line-height: 18px
                overflow: hidden   
            &__left
                width: 75%
            &__right
                width: 25%    
        .context-menu
            display: none
            margin-right: -6px 
            i    
                color: $mainBlack      
        &-heading
            .author
                align-items: flex-start
                justify-content: space-between
                width: 100%
                &__heading
                    display: flex
                    align-items: flex-start
                    width: 100%
                    margin-right: 20px
                &__specializtion
                    color: $darkGrey
                    font-size: $smallFontSize
                    font-family: $avenirNextRegular  
        &-content
            padding-top: 20px
            display: none
        &.status__accepted
            border-color: $mainGreen
        &.status__pending
            border-color: $mainYellow
        &.status__complete
            border-color: $mainGrey 
        &.active
            transition: 1s ease-in-out
            &:before
                transition: .3s ease-in-out
                transform: rotate(180deg)
            .my-service__name,
            .schedule-dropdown-item-content
                display: block
            .schedule-dropdown-item__main-info
                display: none    
            .context-menu
                display: flex
            &.status__accepted
                &:before
                    color: $mainGreen    
            &.status__pending
                &:before
                    color: $mainYellow
            &.status__complete
                &:before
                    color: $mainGrey     
                                 
        &__main-info
            display: flex
            margin-bottom: 0
            margin-top: 15px

    &-tips
        padding: 30px
        width: 100%
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-gap: 30px
        position: relative

        +max($mobile)
            grid-template-columns: repeat(2, 1fr)
            grid-gap: 10px
            padding: 15px

        &-item
            display: flex
            align-items: center
            padding-left: 28px
            color: $darkGrey
            font-size: $smallFontSize
            position: relative
            +max($mobile)
                font-size: $extraSmallFontSize
            &::before
                content: ''
                +flex-center()
                +size(20px)
                position: absolute
                left: 0
                top: 50%
                transform: translateY(-50%)
                border-radius: 2px
            &.status__pending
                &::before
                    background-color: $mainYellow
            &.status__accepted
                &::before
                    background-color: $mainGreen
            &.status__vacation
                &::before
                    background-color: $mainBlue
            &.status__complete
                &::before
                    background-color: $mainGrey
            &.status__custom-booking
                &::before
                    border: 2px solid $mainGreen 
            &.status__without-conclusion
                &::before
                    background-color: $mainGrey
                    color: $mainWhite
                &::after
                    content: 'no_sim'
                    font-family: 'Material Icons Outlined'
                    position: absolute
                    left: 2px
                    top: 50%
                    transform: translateY(-50%)
                    border-radius: 50%
                    background-color: $mainRed
                    +size(16px)  
                    +flex-center()
                    color: $mainWhite
                    font-size: 12px
                    line-height: 16px
                                     
    &__no-results
        &-img
            width: 120px
        &-title
            font-size: $demiFontSize
            font-family: $avenirNextDemi
            margin: 20px 0
        &-subtitle
            font-size: $smallFontSize
            color: $mainBlack
            line-height: 18px

.schedule-main-dropdown
    flex-direction: column
    .dropdown-form
        &.on-dateclick-form
            padding-top: 30px
            max-width: 470px
                




