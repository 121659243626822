.consultation-wrap
  display: flex
  align-items: flex-start
  justify-content: space-between

  .consultation-left
    width: calc(100% - 360px - 30px)

  .consultation-chat
    width: 360px

  .consultation-chat__item-chat
    width: 100%
    margin-top: 25px

.consultation-video
  $t: &

  &__companion
    width: 100%
    border-radius: 6px
  
  &__me
    position: absolute
    width: 150px
    border-radius: 6px
    right: 20px
    top: 20px

  &__btn
    +size(33px)
    border-radius: 50%
    +flex-center()
    padding: 0
    background-color: $mainWhite
    border: 0
    
    i
      color: $darkBlue
      font-size: 22px

  &__full
    position: absolute
    bottom: 20px
    right: 20px
    padding-top: 1px

    i
      color: $mainBlack
  
  &__call-end
    background-color: #FF2857

    i
      color: $mainWhite

  &__nav
    display: flex
    align-items: center
    +left()
    bottom: 20px

    #{$t}__btn
      margin: 0 5px

    #call,
    #answer
      border-radius: 50%
      background: #00d7a9
      width: 40px
      height: 40px
      display: flex
      flex-wrap: wrap
      padding: 6px

.consultation-chat
  background-color: $mainWhite
  border-radius: 6px
  border: 1px solid $halfGrey

  .chating-scroll
    height: 560px
    
  .chating
    height: 100%

  .message
    &__pic
      margin-left: 15px

  .message-sender 
    .message
      &__pic
        margin-right: 15px

  .add-message
    border-radius: 0 0 6px 6px

    &__filed
      padding: 9px 10px 0px

.consultation-member
  margin-top: 25px
  display: flex
  flex-wrap: wrap
  flex-direction: column
  
  .cabinet-content-title
    width: 100%

  &__item
    margin-right: -10px
    background: transparent
    border: none

    img
      +size(34px)
      border: 2px solid $mainWhite
      border-radius: 50%
