.lang
  font-size: $mediumFontSize
  font-family: $avenirNextMedium
  color: $darkBlue
  cursor: pointer
  display: block
  margin-left: 20px
  margin-right: 20px
  +max($mobile)
    display: none
  
  &__now
    display: flex
    align-items: center
    font-size: $regularFontSize
    +max($mobile)
      font-size: $smallFontSize

    .material-icons
      transition: .5s ease-in-out
      margin-left: 5px
    &:hover
      .material-icons
        transition: .5s ease-in-out
        transform: rotate(180deg)
  .dropdown-body
    width: 170px
    padding: 10px 0
    top: calc(100% + 40px)
    right: 0
    
    &:after
      left: 50%
      transform: translateX(-50%)
      right: auto
      
    &:before
      content: ''
      height: 40px
      position: absolute
      bottom: 100%
      display: block
      left: 0
      right: 0

  &__link
    height: 40px
    position: relative
    display: flex
    align-items: center
    padding-left: 20px
    font-size: $smallFontSize
    color: $mainBlack
    transition: all .2s

    &:hover
      background-color: rgba(#00D7A9, .1)
      color: $mainBlack
      text-decoration: none

      &:after
        opacity: 1

    &:after
      content: ''
      opacity: 0
      background-color: $mainGreen
      width: 4px
      position: absolute
      left: 0
      top: 0
      bottom: 0
      display: block
      transition: all .2s
    
    img
      margin-right: 20px
      width: 27px
      height: 16px
