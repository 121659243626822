.functions-wrap
  padding-top: 130px
  +max($mobile)
    padding-top: 70px
.functions
  display: flex
  justify-content: space-between
  +max($laptop)
    flex-direction: column

  +max($mobile)
    display: none
  &__left
    width: 40%
    +max($laptop)
      width: 100%
      padding-bottom: 40px
      .tab-content >.tab-pane
        padding-top: 25px
    +max($mobile)
      width: 38%
      padding-top: 25px
      .tab-content >.tab-pane
        padding-top: 0

  &__right
    margin: auto
    width: 58%
    +max($laptop)
      width: 100%
    +max($mobile)
      width: 62%

  &__btns
    display: flex
    align-items: center
    margin-bottom: 0
    flex-direction: row
    margin-top: 130px
    +max($desktop)
      margin-top: 40px
    +max($mobile)
      margin-top: 30px
      justify-content: space-between
    +max($mobileL)
      flex-direction: column

  &__readmore
    height: 60px
    background-color: transparent
    padding: 0 35px
    +flex-center()
    color: #12C48B
    font: $regularFontSize / 1 $avenirNextMedium
    border-radius: 30px
    border: 1px solid #18D766
    width: auto
    margin-right: 30px
    +max($mobile)
      width: 100%
      min-height: 50px
      height: unset
      padding: 0 20px
    +max($mobileL)
      margin-right: 0
      margin-bottom: 20px
      max-width: 324px

  &__start
    height: 60px
    display: flex
    align-items: center
    background-color: #12C48B
    box-shadow: 0 10px 20px 0px rgba(18,196,139,0.2)
    border-radius: 30px
    border: 0
    font: $regularFontSize / 1 $avenirNextMedium
    color: $mainWhite
    padding: 0 10px 0 20px
    justify-content: center
    width: auto
    text-decoration: none
    &:hover
      text-decoration: none
    +max($mobileM)
      padding: 0 5px 0 20px
    span
      margin-left: 35px
      +size(40px)
      +flex-center()
      border-radius: 50%
      background-color: $mainWhite
      i
        color: #12C48B

.functions-nav
  $t: &

  display: flex
  justify-content: space-between
  flex-wrap: wrap
  flex-direction: column
  @media (min-width: 575px)
    flex-direction: row

  &__link
    background-color: transparent
    border-radius: 4px
    position: relative
    padding: 0
    border: 1px solid #DEE6FF
    margin-bottom: 10px
    width: calc(50% - 5px)
    +max($laptop)
      width: calc(50% - 7.5px)
    +max($mobile)
      width: 100%
    &.active
      z-index: 1
      box-shadow: 0 10px 20px 0 rgba(2,200,191,0.2)
    &:nth-child(1)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/research_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/research.svg')

    &:nth-child(2)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/archive_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/archive.svg')
        +size(68px)

    &:nth-child(3)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/hosp_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/hosp.svg')

    &:nth-child(4)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/profile_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/profile.svg')
        +size(67px, 66px)

    &:nth-child(5)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/care_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/care.svg')
        +size(66px, 56px)

    &:nth-child(8)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/heart_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/heart.svg')
        +size(62px, 66px)

    &:nth-child(7)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/wallet_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/wallet.svg')
        +size(64px, 66px)

    &:nth-child(6)
      &.active
        #{$t}__ico
          background-image: url('/build/images/general/data_white.svg')

      #{$t}__ico
        background-image: url('/build/images/general/data.svg')
        +size(65px, 66px)

    &:after
      content: ''
      display: block
      position: absolute
      left: -20px
      top: -20px
      right: -20px
      bottom: -20px
      background: linear-gradient(64deg, #06CF9A 0%, #00D9D7 100%)
      border-radius: 4px
      z-index: 2
      opacity: 0
      +max($desktop)
        left: 0
        top: 0
        right: 0
        bottom: 0
      +max($laptop)
        left: -10px
        top: -25px
        right: -10px
        bottom: -25px
      +max($mobile)
        left: 0
        top: 0
        right: 0
        bottom: 0

    &.active
      background-color: transparent

      #{$t}__name,
      #{$t}__num
        color: $mainWhite

      &:after
        opacity: 1

  &__ico
    +size(66px)
    min-width: 66px
    margin-right: 40px
    background-repeat: no-repeat
    background-size: 100%
    +max($desktop)
      margin-right: 25px
      min-width: 48px
    +max($laptop)
      min-width: 64px
      margin-right: 30px
    +max($mobile)
      +size(48px)
      min-width: 48px
      margin-right: 30px

  &__name
    font: 25px $avenirNextMedium
    color: $darkBlue
    width: calc(100% - 140px)
    +max($desktop)
      width: 100%
      font-size: $demiFontSize
    +max($mobileL)
      font-size: $mediumFontSize

  &__num
    font: $mediumFontSize / 1 $avenirNextBold
    position: absolute
    top: 20px
    right: 30px
    color: $darkBlue
    +max($desktop)
      right: 20px
    +max($mobile)
      right: 15px
      top: 15px
    @media(max-width: 400px)
      font-size: $smallFontSize

  &__inner
    position: relative
    padding: 0 37px
    z-index: 3
    height: 140px
    display: flex
    align-items: center
    +max($desktop)
      padding: 0 20px
    +max($laptop)
      padding: 27px 30px
    +max($mobile)
      height: unset
      padding: 20px 24px 20px 30px

.function
  position: relative
  $t: &

  &:nth-child(1)
    #{$t}__ico
      width: 91px
      +max($tablet)
        width: 64px

  &__ico
    margin-bottom: 34px
    width: 91px

  &__title
    font: $largeFontSize / 1 $avenirNextDemi
    margin-bottom: 12px
    padding-right: 100px
    color: $mainBlack
    +max($desktop)
      padding-right: 30px
    +max($mobile)
      font-size: $mediumFontSize
      padding-right: 0
      margin-bottom: 20px

  &__desc
    font: $mediumFontSize / 30px $avenirNextRegular
    padding-right: 100px
    color: $mainBlack
    +max($desktop)
      padding-right: 30px
    +max($tablet)
      font: $regularFontSize / 30px $avenirNextRegular
    +max($mobile)
      font-size: $regularFontSize
      padding-right: 0
  &__ico,
  &__title,
  &__desc
    position: relative
    z-index: 2

  &__number
    font-size: 200px
    font-family: $avenirNextBold
    color: $mainWhite
    position: absolute
    right: 0
    top: 50%
    +max($laptop)
      top: -75px
      left: 165px
