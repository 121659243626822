.big-news
    padding: 50px
    border-radius: 8px 0px 0px 8px
    border-right: 2px solid $borderGrey
    box-shadow: 0 20px 20px 10px rgba(22,57,91,0.1)
    background-repeat: no-repeat
    background-size: cover
    color: $mainWhite
    display: flex
    text-decoration: none
    position: relative
    +max($desktop)
        height: 300px
    +max($mobile)
        padding: 30px
        height: 460px
    &:link, 
    &:visited,
    &:hover
        color: $mainWhite
        text-decoration: none
    &:after
        content: ''
        display: flex
        width: calc(100% + 1px)
        height: 100%
        position: absolute
        left: -1px
        right: 0
        top: 0
        bottom: 0
        background-color: rgba(22, 57, 91, 0.65)
        border-radius: 8px 0px 0px 8px
    &__overlay
        display: flex
        position: absolute
        left: 0
        top: 0
        bottom: 0
        right: 0
        height: 100%
        text-decoration: none
        z-index: 1
        &:hover,
        &:focus
            text-decoration: none   
    &__body
        height: 100%
        display: flex
        flex-direction: column
        justify-content: flex-end
        align-items: flex-end
        position: relative
    &__heading
        width: 100%
        margin-bottom: 20px
        position: relative
        z-index: 2
    &__title
        width: 100%
        font-size: $extraLargeFontSize
        font-family: $avenirNextDemi
        color: $mainWhite
        line-height: 44px
        +max($tablet)
            font-size: $biggerFontSize 
            line-height: 35px
        +max($mobileL)
            font-size: $demiFontSize 
            line-height: 25px
    &__category
        color: $mainGreen
        margin-right: 20px
        position: relative
        +max($mobile)
            font-size: $extraSmallFontSize
        &.links-effect
            &:hover,
            &:focus
                color: $mainGreen
    &__date
        color: $mainWhite
        +max($mobile)
            font-size: $extraSmallFontSize 
    &__data
        font-family: $avenirNextDemi
        font-size: $regularFontSize
        color: $mainWhite
        display: flex
        align-items: center
        .material-icons,
        .material-icons-outlined
            font-size: 18px
            margin-right: 9px
        + .big-news__data
            margin-left: 20px      
    &__footer
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        position: relative
        z-index: 2
        +max($mobile)
            flex-direction: column
            align-items: start
        &--left,
        &--right
            display: flex
            align-items: center        
        +max($mobile)
            &--left
                margin-bottom: 10px
