.moi-video
  position: relative
  max-width: 100%
  width: 100%
  max-height: 614px
  display: flex
  +max($mobile)
    max-height: 161px
  video
    width: 100%
    height: 100%
    object-fit: cover
    max-height: 614px
    display: flex
    +max($mobile)
      max-height: 161px

  &__overlay
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(0, 0, 0, .55)
    z-index: 2
    cursor: pointer
    transition: opacity .2s ease-in-out
    opacity: 1

    .loaded &
      opacity: 0

    &-btn
      cursor: pointer
      width: 80px
      height: 80px
      border-radius: 50%
      background: $mainGreen
      z-index: 2
      opacity: 1
      transition: opacity .2s ease-in-out
      &:hover
        opacity: .8
      +flex-center()
      +max($mobile)
        width: 50px
        height: 50px
      &.material-icons-outlined
        font-size: 46px
        color: $mainWhite
        +max($mobile)
          font-size: $extraLargeFontSize


  &__controls
    opacity: 0
    transition: opacity .2s ease-in-out
    width: 100%
    display: flex
    flex-wrap: wrap
    align-items: center
    z-index: 1
    padding: 20px 10px
    position: absolute
    bottom: 0

    .loaded:hover &
      opacity: 1

  &__play-btn
    color: $mainWhite
    cursor: pointer
    opacity: 1
    transition: opacity .2s ease-in-out

    &:hover
      opacity: .8

  &__timings
    font: $mediumFontSize $avenirNextRegular
    color: $mainWhite
    +max($regularFontSize)
      font-size: $bigFontSize
  &__progress-wrapper
    background: rgba(255,255,255,.5)
    height: 5px
    width: 100%
    margin: 5px 10px 0
  &__progress
    background: $mainGreen
    height: 5px
    transform-origin: left
    transform: scaleX(0)
    transition: transform .15s linear

.video-wrap
  display: flex
  flex-direction: column

  & > .btn-wrap
    margin-bottom: 20px

    .download-btn
      .material-icons-outlined
        margin-right: 12px

  +max($mobile)
    flex-direction: column-reverse

    & > .btn-wrap
      margin: 20px 0 0 0

      .download-btn
        width: 100%
        justify-content: center

