.ranged-data-picker
  display: none
  position: absolute
  left: 0
  top: 100%
  background: $mainWhite
  z-index: 100
  padding: 20px
  border: 1px $halfGrey solid
  border-radius: 10px
  box-shadow: 0 11px 17px 13px rgba(0, 0, 0, 0.06)
  &.active
    display: block
    position: absolute
    top: 55px
    left: 50%
    transform: translateX(-50%)

  .close
    position: absolute
    right: 10px
    top: 10px
    cursor: pointer
    color: $mainGrey

  &__header
    display: flex
    align-items: baseline

  &__today-button
    font: $smallFontSize $avenirNextDemi
    color: $mainGreen
    cursor: pointer

  &__left,
  &__right
    padding: 10px
    span
      font: $smallFontSize $avenirNextDemi

  &__title
    font: $regularFontSize $avenirNextDemi
    margin-right: 20px

  &__body
    display: flex
    justify-content: space-between
    +max($mobile)
      flex-direction: column
