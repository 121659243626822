.dashboard-booking
    padding: 0
    max-height: 219px
    .booking
        display: flex
        align-items: center
        padding: 0 25px 0 30px
        min-height: 73px
        width: 100%
        border-bottom: 1px solid $borderGrey
        &__expert
            display: flex
            align-items: center
            margin-right: 3%

            &-avatar
                +size(40px)
                border-radius: 20px
                flex-shrink: 0
                position: relative
                border: 2px solid $mainBlue
                margin-right: 10px
                img
                    width: 100%
                    height: auto
                    border-radius: 50%
                .status
                    display: block
                    position: absolute
                    bottom: -2px
                    left: -2px
                    width: 10px
                    height: 10px
                    border-radius: 5px
                    border: 1px solid $mainWhite
                    background: $halfGrey
                    &.online
                        background: $mainGreen
            &-name
                font: $extraSmallFontSize $avenirNextMedium
                color: $mainBlack
                width: 130px

        &__title
            width: 300px
        &__date
            width: 60px
        &__time
            width: 65px
        &__title,
        &__date,
        &__time
            font: $minimalFontSize $avenirNextMedium
            color: $darkGrey
            margin-right: 2%
        &__status
            font: $minimalFontSize $avenirNextDemi
            color: $darkGrey
            width: 110px
            margin-right: 3%
        &__price
            font: $extraSmallFontSize $avenirNextBold
            color: $darkBlue
            width: 80px
            margin-right: 3%
        &__actions
            display: flex
            margin-left: auto
            width: 48px
            .button_borderless
                i
                    font-size: 16px
                    color: $mainGrey
                    margin-right: 0
                    //pointer-events: none
                &:hover
                    i
                        color: $mainGreen
