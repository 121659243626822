.service-subscribe
  .dropdown-form__title
    font-size: $bigFontSize
    font-family: $avenirNextDemi
    margin-bottom: 30px
  
  &__title
    font: $regularFontSize $avenirNextMedium
    color: $mainBlack
    margin-bottom: 16px
    width: 100%
    &.service-date-title
      margin-bottom: 0
  &-photo
    cursor: pointer
    &.attached-photo
      margin-bottom: 30px
      +max($mobile)
        margin-bottom: 15px
      .all-documents__item
        &.uploaded-files
          width: 100%
          .one-document
            &__pic
              margin-right: 20px
            &__name
              word-break: break-word
  &-hours
    padding-top: 30px
  .btn-wrap
    margin-top: 30px
    .btn
      min-height: 40px
      +max($mobile)
        font-size: $smallFontSize
      &.btn--rounding.btn--middle.btn--green
        background-color: $mainGreen

  &__accept-rules
    display: block
    margin-top: 20px
    font-size: $smallFontSize
    font-family: $avenirNextRegular
    color: $mainBlack
    a
      text-decoration: underline
      color: #252aae

.subscribe-services
  margin-bottom: 40px
  +max($mobile)
    margin-bottom: 20px

  &__item
    border: 1px solid $mainGreen
    padding: 20px
    color: $mainBlack
    font: $regularFontSize / 20px $avenirNextBold
    border-radius: 6px
    display: block
    word-wrap: break-word
    word-break: break-word
    +max($mobile)
      padding: 15px
      font-size: $smallFontSize

    &:hover
      color: $mainBlack
      text-decoration: none
    
    .owl-item.active &
      border-color: $mainGreen
      color: $mainBlack

.service-subscribe-date
  position: relative
  +max($mobile)
    display: flex
    flex-direction: column
    width: 100%
    .ui-datepicker-calendar
      tbody
        .ui-state-default
          width: 36px
          height: 36px
          margin: 0 auto
  &.js-booked-service-data,
  &.insurance-case-block
    max-width: 419px
  .service-subscribe__title
    +max($mobile)
      font-size: 15px
      overflow: hidden
.service-subscribe-calendar
  max-width: 330px
  +max($mobileL)
    max-width: 100%
.service-subscribe__wrapper
  overflow: hidden auto
  +max($mobile)
    overflow: hidden 
    order: 3
  .service-subscribe__title
    &.on-mobile-title
      display: none
      padding-top: 20px
      margin-bottom: 15px
      +max($mobile)
        display: block
.service-subscribe-clock
  width: 100%
  max-width: 435px
  overflow: hidden
  min-height: 48px
  position: relative
  .spinner
    top: 50%
  +max($mobile)
    justify-content: flex-start
    max-width: 100%
    width: 100%
    position: relative
    top: 0
    left: 0
    .spinner
      top: 50%
  .service-subscribe__title
    margin-bottom: 17px
    width: 100%
    +max($mobile)
      &.no-mobile
        display: none
  &__item-wrapper
    display: grid
    grid-template-columns: repeat(8, 1fr)
    grid-column-gap: 5px
    grid-row-gap: 5px
    max-height: 112px
    overflow-x: hidden
    overflow-y: auto
    padding-right: 16px
    +max($mobileL)
      grid-template-columns: repeat(6, 1fr)

  &__item
    min-height: 24px
    border: none
    outline: none
    min-width: 48px
    display: flex
    justify-content: center
    align-items: center
    background-color: #E0E7FF
    color: $mainBlack
    font-size: 12px
    font-family: $avenirNextRegular
    border-radius: 5px
    +max($mobile)
      width: 100%
      min-width: unset
      &.no-free-hours-block
        max-width: 100%
        width: 100%
        min-width: 100%
        height: 100%
        max-height: unset
        margin-right: 0
.check-time-wrap
  margin: 30px 0
  display: flex
  align-items: center
  flex-wrap: wrap
  +max($mobile)
    .service-subscribe__title
      margin-bottom: 0
  .select-custom
    width: 100px
    margin-right: 20px
    +max($mobile)
      &:last-child
        margin-right: 0

.check-type-wrap
  margin-top: 30px
  .radio
    font-family: $avenirNextRegular
    &__ico
      width: 20px
      height: 20px
      &::after
        width: 10px
        height: 10px
  +max($mobile)
    margin-top: 25px

.subscribe-services-pay
  display: flex
  position: relative
  align-self: flex-end

  input
    display: none
  
  &__desc
    width: 250px
    display: flex
    align-items: center
    border: 1px solid $halfGrey
    border-radius: 4px
    padding: 20px

  &__ico
    background-color: #E0E7FF
    +size(40px)
    border-radius: 50%
    +flex-center()
    margin-right: 20px

  &__name
    color: #A7BAE7
    font-size: $mediumFontSize
    font-family: $avenirNextMedium
    