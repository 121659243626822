.company
    padding-top: 90px
    +max($laptop)
        padding-top: 50px
    .container
        +max($laptop)
            padding: 0 40px
        +max($mobile)
            padding: 0 20px
    &__title
        font-family: $avenirNextMedium
        font-size: $bigFontSize
        color: $mainBlack
        margin-bottom: 30px
        +max($tablet)
            font-size: $demiFontSize
    &__btn
        +max($tablet)
            position: absolute
            bottom: 0px
            left: 0
    &__section
        &-heading
            display: flex
            justify-content: space-between
            align-items: center
            width: 100%
            margin-bottom: 30px
            .company__title
                margin-bottom: 0           
            +max($mobile)
                margin-bottom: 20px
    &__main
        min-height: 600px
        display: grid
        grid-template-columns: repeat(2, 1fr)
        width: 100%
        border-radius: 4px
        overflow: hidden
        +max($laptop)
            grid-template-columns: 1fr
            width: calc( 100% + 40px )
            transform: translateX(-20px)
        &-info
            color: $mainWhite
            padding: 60px 90px 90px 90px
            background: $secondaryGradient
            position: relative
            z-index: 1
            +max($tablet)
                padding: 50px 90px
            +max($mobile)
                padding: 30px 40px
            +max($mobileL)
                padding: 30px 40px 30px 20px
            &--logo
                +size(100px)
                min-width: 100px
                margin-bottom: 20px
                border: none
                +max($mobile)
                    +size(60px)
                    min-width: 60px
                img
                    object-fit: cover
                    max-width: 100%
                    height: 100%
            &--name
                font-size: $extraLargeFontSize
                font-family: $avenirNextDemi
                +max($mobile)
                    font-size: $bigFontSize
            &--rating
                display: inline-flex
                align-items: center
                border: 2px solid $mainWhite
                border-radius: 4px
                height: 30px
                padding: 0 5px
                .material-icons
                    margin-right: 7px
                    +max($mobile)
                        margin-right: 2px
                        font-size: $regularFontSize
                span
                    font-family: $avenirNextBold
                    font-size: $demiFontSize
                    +max($mobile)
                        font-size: $regularFontSize
            &--type
                font-size: $regularFontSize
                font-family: $avenirNextMedium
                opacity: .8 
                margin-bottom: 40px
            &--address,
            &--contacts,
            &--website,
            &--reviews
                display: flex
                margin-bottom: 20px
                .material-icons,
                .material-icons-outlined
                    font-size: 24px
                span,
                .links-effect
                    font-size: $demiFontSize
                    font-family: $avenirNextMedium
                    line-height: 25px
                    +max($mobile)
                        font-size: $regularFontSize
                    &--holder
                        margin: 0 16px
                .context-menu
                    +size(unset, unset)
                    min-width: unset
                    display: inline-block
            .btn-wrap
                .button
                    border: 1px solid $mainWhite
                    background-color: transparent            
        &-more
            display: flex
            flex-direction: column
            +max($laptop)
                flex-direction: row
                background-size: cover
                background-position: top
            +max($mobile)
                flex-direction: column
            &--link
                color: $mainWhite
                display: flex
                align-items: center 
                .material-icons,
                .material-icons-outlined
                    margin-right: 12px 
                +max($mobile)
                    font-size: $regularFontSize   
        &-photos,
        &-map
            display: flex
            align-items: flex-end
            padding: 55px
            min-height: 300px
            height: 100%
            text-decoration: none
            position: relative
            cursor: pointer
            +max($laptop)
                flex-basis: 50%
            +max($mobile)
                flex-basis: 100%
                min-height: 220px
            +max($mobileL)
                min-height: 140px
            &:hover
                text-decoration: none
            &:after
                content: ''
                +flex-center()
                +size(100%, 100%)
                position: absolute
                left: 0
                right: 0
                top: 0
                bottom: 0
                margin: auto
                background: rgba(58, 89, 120, 0.8)
            .company__main-more--link
                position: relative
                z-index: 2    
        &-map
            background:url("/build/images/general/clinic_fake_map.png") center center no-repeat
            background-size: 100% 100%
            transition: background-size 1s ease-in-out
            cursor: pointer
            +max($laptop)
                background-size: cover
            &:hover
                transition: background-size 1s ease-in-out
                background-size: 125% 125% !important 
        &-photos
            background:url("/build/images/general/clinic_fake_photo.png") center center no-repeat
            background-size: 100% 100%
            transition: background-size 1s ease-in-out
            &:hover
                transition: background-size 1s ease-in-out
                background-size: 125% 125%  !important           
    &__about
        display: grid
        grid-template-columns: auto 330px
        grid-column-gap: 150px
        padding-top: 80px
        +max($laptop)
            grid-template-columns: auto 240px
            grid-column-gap: 40px
        +max($mobile)
            padding-top: 40px
            grid-template-columns: 1fr
        &-desc
            +max($mobile)
                margin-bottom: 40px
            &--content
                font-size: $demiFontSize
                color: $mainBlack
                font-family: $avenirNextLight
                +max($tablet)
                    font-size: $regularFontSize
                +max($mobileL)
                    font-size: $smallFontSize
        &-schedule
            &--list
                max-width: 330px
                width: 100%
            &--item
                padding-bottom: 12px
                display: flex
                justify-content: space-between
                width: 100%
                border-bottom: 1px solid $borderGrey
                &:not(:first-child)
                    padding: 12px 0
            &--day,
            &--time
                font-size: $demiFontSize
                color: $mainGrey
                font-family: $avenirNextMedium
                +max($tablet)
                    font-size: $regularFontSize
            &--time
                color: $darkGrey
    
    &__categories
        position: relative
        padding-top: 80px
        +max($tablet)
            padding-bottom: 60px
        +max($mobile)
            padding-top: 40px    
        &-list
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 30px
            +max($desktop)
                grid-template-columns: repeat(3, 1fr)
            +max($laptop)
                grid-template-columns: repeat(2, 1fr)
                grid-gap: 20px
            +max($mobileL)
                grid-template-columns: 1fr
        &-list--bg
            background-color: $mainWhite
        &-item
            .links-effect
                font-size: $demiFontSize
                font-family: $avenirNextMedium
                color: $mainBlack
                +max($tablet)
                    font-size: $regularFontSize
                +max($mobileL)
                    font-size: $smallFontSize
                span
                    color: $mainGrey
                &:hover,
                &:focus,
                &.active
                    color: $mainGreen
                    span
                        color: $mainGreen   
            
    &__experts,
    &__services
        position: relative
        padding-top: 80px
        +max($tablet)
            padding-bottom: 60px
        +max($mobile)
            padding-top: 40px    
            padding-bottom: 50px
        &-list
            position: relative
            .arrow__prev,
            .arrow__next
                z-index: 1
                top: calc(50% - 40px)
                +max($desktop)
                    height: 40px
                    width: 40px
                    min-width: 40px
            .arrow__prev
                position: absolute
                left: -80px
                +max(1600)
                    left: -30px
                +max($desktop)
                    left: -10px
                +max($laptop)
                    left: -15px
            .arrow__next
                position: absolute
                right: -80px
                +max(1600)
                    right: -30px
                +max($desktop)
                    right: -10px
                +max($laptop)
                    right: -15px
    &__experts,
    &__services
        &-list
            .swiper-container
                padding-right: 10px
                padding-bottom: 10px 
                margin-right: -10px
    &__experts
        padding-top: 70px
        +max($mobile)
            padding-top: 40px
    &__reviews
        padding-top: 70px
        +max($mobile)
            padding-top: 40px
        &-list
            display: grid
            grid-template-columns: repeat(3, 1fr)
            grid-gap: 30px
            +max($laptopM)
                grid-template-columns: repeat(2, 1fr)
                grid-gap: 30px 20px
            +max($mobile)
                grid-template-columns: 1fr
    &__news
        padding-top: 70px
        +max($mobile)
            padding-top: 40px
        &-list
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 30px
            +max($laptop)
                grid-template-columns: repeat(3, 1fr)
            +max($tablet)
                grid-template-columns: repeat(2, 1fr)
                grid-gap: 30px
            +max($mobileL)
                grid-template-columns: 1fr    
    &__header
        width: 100%
        background-color: $lightGrey
        box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25)
        border-top: 1px solid $halfGrey
        border-bottom: 1px solid $halfGrey
        transform: translateY(calc(-100% - 30px))
        position: fixed
        z-index: 3
        top: 0
        transition: .3s ease-in-out
        +max($tablet)
            display: none
        &.active
            transition: .3s ease-in-out
            transform: translateY(0)
        &-wrapper
            min-height: 80px
            padding-left: 150px
            display: flex
            align-items: center
            justify-content: space-between
            position: relative
        &-nav
            display: flex
            align-items: center  
            &--link
                font-size: $demiFontSize
                font-family: $avenirNextMedium
                color: $darkGrey
                +flex-center()
                min-height: 78px
                text-decoration: none
                transition: .3s ease-in-out
                &.news-nav__link
                    &:hover
                        color: $mainGreen
                        text-decoration: none 
                        &::after
                            background-color: $mainGreen
        &-callback
            display: flex
            align-items: center
            .company__main-info--contacts
                align-items: center
                margin-bottom: 0
                .material-icons,
                .material-icons-outlined
                    font-size: 20px
                .links-effect
                    margin-left: 10px
                    margin-right: 0
                    font-size: $regularFontSize
                    color: $darkBlue                     
        &--logo
            border-color: $halfGrey
            margin-bottom: 0
            position: absolute
            left: 0
            bottom: -30px