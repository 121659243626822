.notifications
  border-radius: 8px
  background: $mainWhite
  padding: 0
.notification
  $t: &
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid $borderGrey
  background-color: $mainWhite
  padding: 15px 18px 15px 30px
  width: 100%
  font-size: $smallFontSize
  +max($mobile)
    padding: 15px
  &.new
    background: $commentGrey
    &:hover
      background: $notificationGrey

  &#{$t}--dashboard
    font-size: $extraSmallFontSize
    padding: 9px 15px 9px 30px

  &__message
    color: $mainBlack
    font-family: $avenirNextRegular
    margin-bottom: 5px
    overflow-wrap: anywhere
    +max($mobile)
      font-size: $extraSmallFontSize
    a
      color: $mainBlue
      &:hover
        color: $hoveredMainBlue

  &__type
    display: flex
    align-items: center
    i
      color: $darkBlue
      font-size: 14px
      margin-right: 8px

  &__date
    color: $textGreyLight
    font: $minimalFontSize $avenirNextDemi

  &__actions
    .button_borderless
      color: $mainGrey
      i
        font-size: 16px
        margin: 0
      &:hover
        color: $mainGreen
      + .button_borderless
        margin-top: 8px

.notification1
  $t: &

  display: flex
  align-items: center
  justify-content: space-between
  border: 1px solid #C0D3FE
  background-color: $mainWhite
  padding: 20px
  border-radius: 6px
  width: 100%
  +max($mobile)
    align-items: unset

  + .notification
    margin-top: 10px
  &:hover
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1)

  &__wrap
    +flex-center

  &__money-spending
    color: #ED0000
    span
      color: $mainBlack
      margin-left: 5px

  &__money-replenishment
    color: #3FA700
    span
      color: $mainBlack
      margin-left: 5px

  &.new
    background-color: $mainWhite
    transition: all .2s

    &:hover
      .notification__delete
        color: rgba(#D93737, .6)
        box-shadow: 0 10px 20px 0px rgba(0,0,0,0.1)

  &__ico
    border-radius: 50%
    +size(34px)
    min-width: 34px
    background-color: #E4EDFE
    +flex-center()
    margin-right: 20px

    i
      color: $mainBlue
      font-size: 20px

  &__body
    width: 100%
    padding-right: 20px
    +max($mobile)
      display: flex
      flex-direction: column

    + #{$t}__delete
      margin-left: auto
      +max($mobile)
        margin-left: 25px
        min-width: 34px
        +size(34px)

    &-head
      min-width: 34%
      display: flex
      align-items: center
      justify-content: space-between
      +max($mobile)
        display: flex
        min-width: unset
        width: 100%
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start

  &__name
    color: $mainBlack
    font: $regularFontSize $avenirNextMedium

  &__date
    color: $mainBlack
    opacity: .7
    font: 11px $avenirNextMedium
    +max($mobile)
      margin: 5px 0

  &__message
    color: $mainBlack
    font-size: $smallFontSize
    line-height: 18px
    width: 100%
    display: flex
    flex-wrap: wrap
    padding-right: 20px
    &.notifications-panel-message
      display: block

  &__delete
    color: #ACB6DB
    transition: all .2s
    padding: 0
    background-color: transparent
    border: 0
    cursor: pointer

    &:hover
      color: rgba(#D93737, .6)

  &__money
    margin-left: auto
    margin-right: 90px
    font: $smallFontSize $avenirNextMedium
    color: $mainBlack

    &.plus
      span
        color: #3FA700

    &.minus
      span
        color: #ED0000

.checked-color
  color: $darkBlue!important

.checked-icon
  display: flex
  margin-left: -30px!important

.unchecked-icon
  display: none!important
