/* STYLE GUIDE FONTS MAP */
$avenirNextLight: 'AvenirNextCyr-Light'     //300
$avenirNextRegular: 'AvenirNextCyr-Regular' //400
$avenirNextMedium: 'AvenirNextCyr-Medium'   //500
$avenirNextDemi: 'AvenirNextCyr-Demi'       //600
$avenirNextBold: 'AvenirNextCyr-Bold'       //700


/* STYLE GUIDE FONT SIZES MAP */

$minimalFontSize: 10px
$extraSmallFontSize: 12px
$smallFontSize: 14px
$regularFontSize: 16px
$mediumFontSize: 18px
$demiFontSize: 20px
$bigFontSize: 24px
$biggerFontSize: 28px
$largeFontSize: 30px
$largerFontSize: 32px
$extraLargeFontSize: 36px


/* STYLE GUIDE COLORS MAP */

//default colors
$mainBlack: #000000
$mainWhite: #ffffff

//main gradient
$mainGradient: linear-gradient(222deg, #25AEF6 0%, #32D29F 100%)
$secondaryGradient: linear-gradient(135.38deg, #2BBB97 45.98%, #25AEF6 91.19%)

//cover image gradient
$imageCover: linear-gradient(180deg, rgba(22, 57, 91, 0) 0%, rgba(22, 57, 91, 0.9) 100%)


//green palette
$mainGreen: #32D29F //main green color
$hoveredMainGreen: #2BBB97 //hovered green color
$darkGreen: #169179


//blue palette
$mainAqua: #1FC5D0 //
$mainBlue: #25AEF6 // main blue color
$blue: #09B1E7


$hoveredMainBlue: #2B89D8 // hovered blue color
$darkBlue: #16395B
$lightBlue: #4B7DAD
$darkBlueGrey: #34495e

//yellow palette
$lightYellow: #FBF1B6 // warnings background
$mainYellow: #ebc00a // warnings
$darkYellow: #FBA40D // warnings icons
$alertYellow: #F79E1B //

//red palette
$mainRed: #E85164 //danger

//grey palette
$mainGrey: #AFB9DD
$textGrayDarker: #818CB7
$halfGrey: #C0D3FE
$helperGrey: #EDF4FF
$backgroundGrey: #F2F2F2
$lightGrey: #E5EEFF
$darkGrey: #3B3B3B
$disabledGrey: rgba(#D1D0D0, .4)
$secondaryGrey: #ACB6DB
$hoveredGrey: #E8E8E8
$borderGrey: #D2E1F5
$textGrey: #999999
$textGreyLight: #A7C0E2
$commentGrey: #f2f2f2
$notificationGrey: #E4E4E7
$pastGrey: #c4c4c4






/* STYLE GUIDE COMMON VARS */
$contentSize: 1440px




\:root
    /* STYLE GUIDE FONTS MAP */
    --avenirNextLight: #{$avenirNextLight}
    --avenirNextRegular: #{$avenirNextRegular}
    --avenirNextMedium: #{$avenirNextMedium}
    --avenirNextDemi: #{$avenirNextDemi}
    --avenirNextBold: #{$avenirNextBold}


    /* STYLE GUIDE FONT SIZES MAP */

    --minimalFontSize: #{$minimalFontSize}
    --extraSmallFontSize: #{$extraSmallFontSize}
    --smallFontSize: #{$smallFontSize}
    --regularFontSize: #{$regularFontSize}
    --mediumFontSize: #{$mediumFontSize}
    --demiFontSize: #{$demiFontSize}
    --bigFontSize: #{$bigFontSize}
    --biggerFontSize: #{$biggerFontSize}
    --largeFontSize: #{$largeFontSize}
    --largerFontSize: #{$largerFontSize}
    --extraLargeFontSize: #{$extraLargeFontSize}


    /* STYLE GUIDE COLORS MAP */

    //default colors
    --mainBlack: #{$mainBlack}
    --mainWhite: #{$mainWhite}

    //main gradient
    --mainGradient: #{$mainGradient}
    --secondaryGradient: #{$secondaryGradient}

    //cover image gradient
    --imageCover: #{$imageCover}


    //green palette
    --mainGreen: #{$mainGreen}
    --hoveredMainGreen: #{$hoveredMainGreen}

    //blue palette
    --mainAqua: #{$mainAqua}
    --mainBlue: #{$mainBlue}
    --blue: #{$blue}


    --hoveredMainBlue: #{$hoveredMainBlue}
    --darkBlue: #{$darkBlue}
    --lightBlue: #{$lightBlue}
    --darkBlueGrey: #{$darkBlueGrey}

    //yellow palette
    --lightYellow: #{$lightYellow}
    --mainYellow: #{$mainYellow}
    --darkYellow: #{$darkYellow}
    --alertYellow: #{$alertYellow}

    //red palette
    --mainRed: #{$mainRed}

    //grey palette
    --mainGrey: #{$mainGrey}
    --textGrayDarker: #{$textGrayDarker}
    --halfGrey: #{$halfGrey}
    --helperGrey: #{$helperGrey}
    --backgroundGrey: #{$backgroundGrey}
    --lightGrey: #{$lightGrey}
    --darkGrey: #{$darkGrey}
    --disabledGrey: #{$disabledGrey}
    --secondaryGrey: #{$secondaryGrey}
    --hoveredGrey: #{$hoveredGrey}
    --borderGrey: #{$borderGrey}
    --textGrey: #{$textGrey}
    --textGreyLight: #{$textGreyLight}
    --commentGrey: #{$commentGrey}
    --notificationGrey: #{$notificationGrey}
    --pastGrey: #{$pastGrey}
    --lightGrey: #{$lightGrey}






    /* STYLE GUIDE COMMON VARS */
    --contentSize: #{$contentSize}