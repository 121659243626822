@media screen and (max-width: 480px)
  #g-recaptcha
    transform: scale(0.5)
    -webkit-transform: scale(0.5)
    transform-origin: 0 0
    -webkit-transform-origin: 0 0

.one-news
  display: grid
  grid-template-columns: 1fr 330px
  grid-gap: 50px
  +max($laptop)
    grid-template-columns: 1fr
  &__right-block
    +max($laptop)
      display: none
    .news-block__title
      text-transform: uppercase
      font-family: $avenirNextDemi
      font-size: $bigFontSize
      margin-bottom: 20px
  &__subheader
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    +max($mobile)
      flex-direction: column
      align-items: start
    &-right,
    &-left
      display: flex
      align-items: center
  &__banner-img
    height: 440px
    display: flex
    justify-content: flex-start
    width: 100%
    height: 440px
    img
      height: 100%
  &__helper-title
    font-size: $demiFontSize
    font-family: $avenirNextLight
    color: $textGrey
    margin-right: 12px
    +max($mobile)
      font-size: $regularFontSize
  &__tags
    display: flex
    flex-wrap: wrap
    padding: 20px 0 8px 0
    border-bottom: 1px solid $borderGrey
    &-tag
      height: 32px
      padding: 0 16px
      position: relative
      overflow: hidden
      +flex-center()
      border: 1px solid $darkBlue
      border-radius: 2px
      background-color: $mainWhite
      font-size: $smallFontSize
      font-family: $avenirNextMedium
      color: $darkBlue
      margin-right: 12px
      margin-bottom: 12px
      transition: .3s ease-in-out
      &:hover
        transition: .3s ease-in-out
        background-color: $darkBlue
        color: $mainWhite
        text-decoration: none
  &__banner
    display: flex
    width: 100%
    height: 400px
    width: 330px
    background-size: 100% 100%
    background-repeat: no-repeat
    background-position: center
    object-fit: cover
    margin-bottom: 18px
    margin-top: 40px
    border-radius: 4px
    &--bottom
      display: flex
      border-radius: 4px
      width: 100%
      height: 140px
      margin-top: 60px
      +max($mobile)
        height: 100%
      img
        object-fit: contain
        width: 100%
        max-width: 100%
  &__header
    display: flex
    flex-direction: column
    width: 100%
  &__footer
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 20px 0
    border-bottom: 1px solid $borderGrey
    +max($mobile)
      flex-direction: column
      align-items: start
    &-left,
    &-right
      display: flex
      align-items: center
  &__author
    font-family: $avenirNextMedium
    font-size: $demiFontSize
    color: $mainBlack
    +max($mobile)
      font-size: $regularFontSize
  &__title
    font-size: $largerFontSize
    font-family: $avenirNextDemi
    color: $mainBlack
    margin-bottom: 20px
    line-height: 39px
    width: 100%
    +max($mobile)
      font-size: $demiFontSize
      line-height: 25px
      margin-bottom: 10px
  &__subtitle
    font-size: $demiFontSize
    font-family: $avenirNextMedium
    color: $mainBlack
    width: 100%
    margin-bottom: 30px
    +max($mobile)
      font-size: $regularFontSize
      margin-bottom: 20px
  &__date,
  &__category
    font-size: $demiFontSize
    font-family: $avenirNextMedium
    margin-right: 20px
    color: $mainGrey
    +max($mobile)
      font-size: $extraSmallFontSize
  &__category
    color: $mainGreen
    margin-right: 24px
    &.links-effect
      &:hover,
      &:focus
        color: $mainGreen
  &__pic
    width: 100%
    margin-top: 20px
    margin-bottom: 30px
    img
      width: 100%
      max-width: 100%
      border-radius: 4px
      display: block
      max-height: 600px
      object-fit: cover
      +max($mobileL)
        max-height: 200px
  &__body
    display: flex
    align-items: flex-start
    flex-direction: column
    object
      width: 85%
      display: flex
      margin: auto
      height: 1065px
    button
      display: flex
      margin: auto
  &__content
    font-size: $mediumFontSize
    color: $mainBlack
    width: 100%
    a
      color: #3598db
    ol, ul
      list-style-type: revert
      li
        margin-left: 30px
        padding-left: 10px
    +max($mobile)
      font-size: $smallFontSize
    h3
      font: $mediumFontSize / 24px $avenirNextBold
      color: $mainBlack
      + p
        margin-top: 20px
    p
      font-size: $mediumFontSize
      font-family: $avenirNextLight
      color: $mainBlack
      line-height: 25px
      +max($mobile)
        font-size: $smallFontSize
      + p,
      + h3
        margin-top: 20px
    > img
      width: 100%
      border-radius: 7px
      margin-bottom: 12px
      display: block
    figcaption
      color: $darkBlue
      font: $smallFontSize / 24px $avenirNextMedium
      text-align: center
      display: block
      width: 100%
      + p
        margin-top: 45px
