.expert-archive
  position: relative
  color: $mainBlack
  
  &__icon
    width: 36px
    height: 36px
    min-width: 36px
    margin-right: 10px
    img
      width: 100%
      height: 100%
      border-radius: 50%

  &__wrap-left-block
    display: flex
    flex-wrap: wrap
    width: 100%
    padding-bottom: 30px
    justify-content: space-between
    align-items: center
    +max($mobile)
      padding-bottom: 15px
    .expert-archive__patient
      width: 48.7%
      +max($mobile)
        width: 100%
        margin-bottom: 15px
    &__info
      width: 48.7%
      display: flex
      justify-content: space-between
      +max($mobile)
        width: 100%
        flex-wrap: wrap
        .expert-archive__category
          width: 100%
          margin-bottom: 15px
        .expert-archive__menu 
          &.context-menu
            position: absolute
            top: 10px
            right: 10px

  &__wrap-top-block
    display: flex
    justify-content: space-between
    flex-wrap: wrap

  &__separator-top-block
    border-bottom: 1px solid $halfGrey
  
  &__text-body
    width: 100%
    word-break: break-word
    &--big0-block
      width: 100%
    &.link
      color: $mainBlue
      font-size: $regularFontSize
      font-family: $avenirNextMedium
      +max($mobile)
        font-size: $smallFontSize
      &:hover
        color: $hoveredMainBlue
        text-decoration: unset
    +max($mobile)
      font-size: $smallFontSize
      color: $mainBlack
    p
      margin-bottom: 30px
      +max($mobile)
        font-size: $smallFontSize
        font-weight: 300

  &__title
    color: $mainBlack
    font-size: $smallFontSize
    font-family: $avenirNextDemi
    margin-bottom: 5px
    +max($mobile)
      color: #666666
      margin-bottom: 0

  &__conclusion-title
    color: $mainBlack
    font-size: $regularFontSize
    font-family: $avenirNextDemi
    margin-bottom: 5px
    +max($mobile)
      color: #666666
      margin-bottom: 0
      font-size: $smallFontSize

  &__patient-name
    width: 130px
    font-family: $avenirNextMedium
    +max($mobile)
      width: 100%
      color: $mainBlack

  &__wrap
    display: flex
    flex-wrap: wrap
    font-size: $smallFontSize
    align-items: center
    &.expert-archive-pacient
      +max($mobile)
        margin: 10px 0
        flex-wrap: nowrap
        align-items: center

  &__doctor-profecion,
  &__date-number,
  &__time-number,
  &__category-name
    +max($mobile)
      font-family: $avenirNextMedium
      color: $mainBlack

  &__list
    flex-direction: column
  
  &__item
    width: 65% !important
    +max($mobile)
      width: 100% !important
      .one-document__pic
        width: 40px
        min-width: 40px
  &__bottom-block
    margin-top: 20px
    +max($mobile)
      margin-top: 15px
    + .btn-wrap
      margin-top: 30px

  &__conclusion-block
    margin-top: 20px
    +max($mobile)
      margin-top: 15px 
    &:first-child
      margin-top: 30px
      +max($mobile)
        margin-top: 15px
