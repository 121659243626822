.archive-list
  width: 100%
  display: flex
  margin-top: 30px
  flex-wrap: wrap
  .archive-item
    width: calc((100% - 60px) / 3)
    margin-bottom: 30px
    margin-right: 30px

    &:nth-child(3n)
      margin-right: 0
    +max($desktop)
      width: calc((100% - 60px) / 2)
      &:nth-child(2n)
        margin-right: 0
      &:nth-child(3n)
        margin-right: 30px
    +max($mobile)
      width: 100%
      margin-right: 0
      &:nth-child(3n)
        margin-right: 0

.archive-item
  border: 1px solid $borderGrey
  display: flex
  flex-wrap: wrap
  background: $mainWhite
  padding: 30px
  color: $mainBlack
  position: relative
  border-radius: 8px
  transition: box-shadow .3s ease-in-out
  &:hover
    transition: box-shadow .3s ease-in-out
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25)
    border-color: $hoveredMainGreen 

  .context-menu
    position: absolute
    right: 30px
    top: 30px

  &__wrapper
    margin-bottom: 20px
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    width: 100%
  &__title
    word-break: break-word
    font: $regularFontSize $avenirNextMedium
    color: $mainBlack
    padding-right: 40px
    margin-bottom: 20px
  &__category
    color: $textGrey
    font-family: $avenirNextMedium
    font-size: $extraSmallFontSize
    width: 100%
  &__info
    width: 50%
    padding-right: 10px
    &-title
      font-size: $smallFontSize
      font-family: $avenirNextMedium
    &-desc
      font-size: $smallFontSize
      font-family: $avenirNextDemi
  &__files
    display: flex
    align-items: center
    width: 50%
    &-ico
      margin-right: 8px
      border-radius: 50%
      +flex-center()
      background-color: $lightGrey
      +size(32px)

      i
        font-size: 20px
        color: $darkBlue
    &-desc
      font-size: $extraSmallFontSize
      font-family: $avenirNextMedium
