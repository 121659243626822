.expert-card
  width: 300px
  box-shadow: 0px 0px 50px 0px rgba(46, 69, 184, 0.14)
  border-radius: 8px
  background-color: $mainWhite
  padding: 12px 0
  display: flex!important
  justify-content: center
  word-break: initial
  $ec: &
  &:after
    +pseudo()
    bottom: 100%
    right: 70px
    +arrow(up, $mainWhite, 13px, 9px)
  &.dashboard-expert-card
    width: 100%
    max-width: 280px
    justify-content: flex-start
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1)
    #{$ec}__pic
      min-width: 32px
      +size(32px)
      display: flex
      align-items: center
      justify-content: center
      margin-right: 10px
      i
        color: #4473E9
    #{$ec}__body
      width: 100%
    #{$ec}__name
      font-family: $avenirNextDemi
      margin-bottom: 0
    #{$ec}__value
      color: $hoveredMainBlue
      font-size: $smallFontSize
    #{$ec}__body-top
      margin-bottom: 20px  
    #{$ec}__body-top,
    #{$ec}__body-bottom
      display: flex
      align-items: flex-start 
    #{$ec}__location
      color: #6D6D6D
      font-size: 13px
    #{$ec}__clinic
      color: $mainBlack
      font-size: 13px
      font-family: $avenirNextMedium  
