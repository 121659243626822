.last-news
  display: grid
  grid-template-columns: 57% 43%
  overflow: hidden
  height: 560px
  max-height: 100%
  border-radius: 8px
  border: 2px solid $borderGrey 
  +max($desktop)
    height: auto
    grid-template-columns: 1fr
  &-list
    padding: 30px
    height: 100%
    display: grid
    grid-template-columns: 1fr
    max-height: 560px
    +max($laptopM)
      padding-bottom: 0
    +max($mobile)
      display: none
    &__item
      display: flex
      flex-wrap: wrap
      width: 100%
      height: 148px
    &__date,
    &__category
      width: auto
      font: $extraSmallFontSize $avenirNextMedium
      text-transform: uppercase
      color: $darkBlue
      &:hover
        color: $darkBlue
    &__date-wrap
      display: flex
      flex-wrap: wrap
      width: 100%
    &__date
      margin-right: 45px
    &__title
      margin-top: 10px
      color: $mainBlack
      max-width: 400px
      font-size: $demiFontSize
      font-family: $avenirNextDemi
      transition: all .2s
      line-height: 1
      &:hover
        text-decoration: none
        color: $mainGreen
