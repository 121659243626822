.mertis-wrap
  padding-top: 0
  position: relative
  z-index: 3
  .index-title
    color: $mainWhite
    +max($laptopM)
      color: $mainBlack
.mertis-list
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  overflow: hidden
  padding: 20px 20px 0 20px
  +max($mobileL)
    padding-top: 30px

.mertis
  $t: &

  display: flex
  flex-direction: column
  align-items: center
  margin-top: 50px
  visibility: hidden
  max-width: 175px
  &:nth-child(2n)
    margin-top: 200px
  +max($laptop)
    margin-top: 50px
    width: calc(100% / 3)
    max-width: 250px
    &:nth-child(2n)
      margin-top: 50px
      width: calc(100% / 3)
    &:last-child
      width: 100%
      max-width: unset
  +max($mobile)
    margin-top: 30px
    &:nth-child(2n)
      margin-top: 30px
    &:nth-child(3)
      margin-top: 0px
    &:nth-child(-n+2)
      margin-top: 0
  &__pic-wrap
    +size(100px)
    border-radius: 50%
    border: 2px solid $mainGreen
    +flex-center()
    margin-bottom: 20px
    +max($mobile)
      +size(70px)
      margin-bottom: 15px
  +max($mobileL)
    width: calc(100% / 2)
    max-width: 47%
    &:nth-child(3)
      margin-top: 30px
    &:nth-child(2n)
      width: 47%

  &__pic
    +size(98px)
    background-color: $mainWhite
    border-radius: 50%
    +flex-center()
    +max($mobile)
      +size(68px)

    span
      background-color: $mainWhite
      +size(90px)
      border-radius: 50%
      +flex-center()
      +max($mobile)
        +size(60px)

      i
        color: #00E89F
        font-size: 40px

    img
      width: 50px
      +max($mobile)
        width: 40px

  &__text
    text-align: center
    color: $darkBlue
    font-size: $mediumFontSize
    font-family: $avenirNextMedium
    line-height: 24px
    +max($mobile)
      font-size: $smallFontSize
      font-family: $avenirNextDemi
