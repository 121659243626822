a.links-effect
    text-decoration: none
    background-image: linear-gradient(currentColor, currentColor)
    background-position: 0% 100%
    background-repeat: no-repeat
    background-size: 0% 1.5px
    transition: background-size .3s
    &:hover,
    &:focus
        text-decoration: none
        color: currentColor
        background-size: 100% 1.5px