.dialogs
  &-list
    height: calc(100% - 80px)
    overflow-y: auto

.dialogs-item
  padding: 20px
  border-bottom: 1px solid #D2E1F5
  display: flex
  transition: all .2s
  cursor: auto

  &:hover
    text-decoration: none

  &.active
    background-color: #EFF2FF

  &.conference
    .dialogs-item__pic
      display: flex
      flex-direction: column

      img
        border: 2px solid $mainWhite

        + img
          margin-top: -20px

    .dialogs-item__date
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center

  &__pic
    margin-right: 10px
    position: relative
    margin-top: 4px

    img
      +size(34px)
      border-radius: 50%
      object-fit: cover

  &__body
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
  &__bodye
    width: 100%
  &__message
    width: 100%
    font-size: 13px
    color: #010101
    max-height: 40px
    overflow: hidden
    margin-top: 4px
    word-break: break-all
    .green-text
      color: $mainGreen

  &__name
    color: $mainBlack
    font: $smallFontSize / 1 $avenirNextMedium
    position: relative
    padding-right: 44px
    .cabinet-sidebar-nav__nums
      &.cabinet_responses-count
        right: 0


  &__date
    margin-top: 5px
    color: #778A9E
    font: 12px / 1 $avenirNextMedium

.dialogs-list-newchat
  height: 80px
  display: flex
  justify-content: center
  align-items: center

  &__btn
    font-size: $smallFontSize
    color: $mainBlack
    border-radius: 30px
    padding: 0 50px
    border: 1px solid $darkBlue
    background-color: transparent
    box-shadow: none

.dialogs-preview .dialogs-list .dialogs-item:hover
  background-color: transparent

.dialog
  &-selected__items
    margin-top: 5px
    .btn
      &.btn--multiple
        margin-right: 5px
        margin-top: 5px
        i
          font-size: $regularFontSize
          color: rgba($mainBlack, .6)
          margin-left: 10px
          cursor: pointer

.chat-frame-wrapper
  min-height: 400px
  height: calc(100vh - 206px) !important
  width: calc(100% - 280px)
  margin-left: auto
  margin-right: 20px

  @media (max-width: 1024px)
    width: calc(100% - 40px)

  @media (min-width: $mobileL)
    min-height: 460px