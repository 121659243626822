.progress-bar
    background-color: transparent
    display: flex
    flex-direction: row
    justify-content: space-between
    &__item
        margin-top: 60px
        margin-bottom: 20px
        position: relative
        width: 100%
        display: flex
        align-items: center
        &.active
            .progress-bar__item-progress::before
                background-color: $mainBlue
            .progress-bar__item-progress::after
                background-color: $mainBlue
            .progress-bar__item-number
                background-color: $mainBlue
            .progress-bar__item-title
                color: $darkBlue
        &.pending
            .progress-bar__item-progress::after
                background-color: $mainBlue
        &-title
            position: absolute
            top: -20px
            left: 0
            color: $lightBlue
            font-size: $smallFontSize
            +max($desktop)
                font-size: $extraSmallFontSize
            +max($laptopM)
                font-size: $minimalFontSize
            +max($laptop)
                display: none
        &-progress
            z-index: 1
            margin-left: -4px
            height: 16px
            width: calc( 100% + 4px )
            background-color: $mainWhite
            box-sizing: content-box
            transform: translateX(2px)
            +max($mobile)
                height: 4px
            &::before,
            &::after
                content: ''
                display: block
                position: absolute
                top: 50%
                left: 0
                height: 8px
                transform: translate(-3px, -50%)
                background-color: $halfGrey
                +max($mobile)
                    height: 4px
            &::before
                width: calc( 100% + 10px)
            &::after
                width: calc( 50% + 5px)
        &-icon
            position: relative
            margin-right: auto
            height: 32px
            width: 32px
            min-width: 32px
            text-align: left
            background-color: $mainWhite
            border-radius: 50%
            +max($mobile)
                height: 20px
                width: 20px
                min-width: 20px
            &--first-step
                top: 60px
        &-number
            z-index: 2
            display: flex
            align-items: center
            justify-content: center
            position: absolute
            top: 50%
            left: 50%
            height: 24px
            width: 24px
            font-size: $mediumFontSize
            font-family: $avenirNextBold
            border-radius: 50%
            background-color: $halfGrey
            transform: translate(-50%, -50%)
            &--blue
                background-color: $mainBlue
            +max($mobile)
                height: 20px
                width: 20px
                font-size: 0px
            .material-icons
                font-size: $mediumFontSize
    &__desc
        display: grid
        grid-column-gap: 40px
        grid-template-columns: 50px 1fr 160px
        padding: 30px 40px
        border: 2px solid $mainBlue
        border-radius: 8px
        background-color: $mainWhite
        +max($mobile)
            grid-template-columns: 1fr
            padding: 15px
        &--last-step
            grid-template-columns: 160px 1fr
            +max($mobile)
                grid-template-columns: 1fr
        img
            +max($mobile)
                display: none
        h3
            margin-bottom: 10px
            font-size: $demiFontSize
            line-height: 25px
            font-family: $avenirNextDemi
            color: $darkBlue
            +max($mobile)
                font-size: $extraSmallFontSize
                line-height: 15px
        p
            font-size: $regularFontSize
            line-height: 20px
            color: $darkBlue
            +max($mobile)
                font-size: $extraSmallFontSize
                line-height: 15px
        a
            align-self: center
            +max($mobile)
                margin-top: 10px
                justify-self: start
