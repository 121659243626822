
.select-with-background
  .select2-selection--single
    // background-color: $mainWhite

.select2
  width: 100% !important
.select-container
.select2-container
  &--default
    transition: color, border, box-shadow .3s ease-in-out
    .select2-selection
      &.select2-selection--multiple,
      &.select2-selection--single
        border: 1px solid $textGreyLight
        background-color: $mainWhite
        position: relative
        height: unset
        display: flex
        align-items: center
        min-height: 50px
        padding: 0 20px
        transition: color, border, box-shadow 1s ease-in-out
        border-radius: 2px
        &:hover
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25)
        .select2-selection__placeholder
          color: $mainBlack
        .select2-selection__rendered
          padding: 0 10px 0px 0
          font-family: $avenirNextLight
          font-size: $regularFontSize
          color: $mainBlack
          ~ .select2-selection__arrow
            height: 100%
            right: 15px
            b
              border-color: $darkBlueGrey transparent transparent transparent
              border-width: 7px 5px 0 5px
              margin-top: -5px
          .select2-selection__choice
            background-color: $lightGrey
            border-radius: 2px
            border: none
            font-size: $smallFontSize
            color: $mainBlack
            min-height: 30px
            +flex-center()
            font-family: $avenirNextRegular
            position: relative
            padding: 0 30px 0 10px
            white-space: normal
            transition: color, background-color .3s ease-in-out
            &__remove
              font-size: 24px
              +size(11px)
              top: -2px
              position: absolute
              right: 10px
              color: $mainGrey
            &:hover
              background-color: rgba($mainRed, .1)
              .select2-selection__choice__remove
                transition: .3s ease-in-out
                color: $mainRed

        .select2-search--inline .select2-search__field
          width: 100% !important
          margin-top: 0
          &::placeholder
            font-family: $avenirNextLight
            font-size: $regularFontSize
            color: $mainBlack
        &:hover
          border-color: $mainGrey
          transition: border-color .3s ease-in-out
          .select2-selection__arrow b
            border-color: $mainBlack transparent transparent transparent
          &::after
            border-color: $mainBlack transparent transparent transparent
      &.select2-selection--multiple
        &::after
          display: flex
          content: ''
          position: absolute
          right: 20px
          top: 22px
          border-color: $darkBlueGrey transparent transparent transparent
          border-width: 5px
          border-style: solid
    &.select2-container--open
      border: none
      .select2-search--inline.select2-search__field
        color: $mainBlack
        font-family: $avenirNextLight
        &::placeholder
          color: $mainBlack
          font-family: $avenirNextLight
      .select2-selection
        &.select2-selection--multiple,
        &.select2-selection--single
          border: 1px solid $mainGreen
          border-radius: 2px
          .select2-selection__rendered
            ~ .select2-selection__arrow b
              border-color: transparent transparent $mainBlack transparent
              border-width: 0 5px 7px 5px
              margin-top: -6px
          &::after
            border-color: transparent transparent $mainBlack transparent
            border-width: 0 5px 7px 5px
      .select2-dropdown
        &.select2-dropdown--below,
        &.select2-dropdown--above
          top: 0px
          border: 1px solid $mainGrey
          border-radius: 2px
          padding: 0
          .select2-search.select2-search--dropdown
            padding: 0 20px
            .select2-search__field
              padding: 0 40px 0 20px
              min-height: 50px
              border-radius: 2px
              border: 1px solid $mainGrey
              font-family: $avenirNextLight
              margin: 10px 0
              color: rgba($mainBlack, .4)
              transition: color, border-color .3s ease-in-out
              background: url('/build/images/svg/search.svg') right 10px top 50% no-repeat
              &::placeholder
                color: rgba($mainBlack, .4)
                font-family: $avenirNextLight
              &:focus
                border-color: $mainGreen
                color: $mainBlack
        &.select2-dropdown--above
          top: 0px
    .select2-results__option
      color: $mainBlack
      font-family: $avenirNextMedium
      font-size: $regularFontSize
      min-height: 50px
      padding: 10px 20px
      display: flex
      align-items: center
      background-color: transparent
      margin-bottom: 0
      transition: .3s ease-in-out
      + .select2-results__option
        // margin-top: 5px
      &--highlighted
        &[aria-selected],
        &[aria-selected=true]
          color: $mainBlack
          background-color: $lightGrey
      &[aria-selected="true"]
        background-color: transparent
        color: $mainGreen
      &:hover
        color: $mainBlack
        background-color: $lightGrey
