.add-favorite-expert
  border: 1px solid $halfGrey
  background-color: $helperGrey
  min-height: 340px
  width: 330px
  height: 100%
  display: flex
  flex-direction: column
  padding: 20px
  border-radius: 5px
  +max($mobile)
    height: unset
  &__desc
    color: $mainBlack
    line-height: 18px
    margin-bottom: auto