
.main-dropdown--service-helper
  .main-dropdown
    $md: &
    &-content
      max-width: 570px
      padding: 45px 60px
      +max($mobile)
        padding: 10px
  .close-btn-absolute
    position: absolute
    top: 20px
    right: 20px
  .dropdown-content
    padding-right: 0


.help-service-info
  &__header
    max-width: 100%
    width: 240px
    margin: 0 auto 55px

  &__image
    width: 100%
    padding-top: 100%
    position: relative
    img
      position: absolute
      margin: auto
      left: 0
      right: 0
      top: 0
      bottom: 0
      max-width: 100%
      max-height: 100%
  &__title
    font: $demiFontSize $avenirNextDemi
    color: $darkBlue
    margin-bottom: 18px
  &__content
    font: $regularFontSize $avenirNextLight
    color: $darkBlue
  ul
    display: block
    margin-top: 15px
    counter-reset: list
    li
      padding-left: 40px
      position: relative
      margin-bottom: 20px
      display: block
      &:before
        counter-increment: list
        content: counter(list)
        color: $mainWhite
        font: $regularFontSize $avenirNextDemi
        background: $mainGreen
        width: 24px
        height: 24px
        text-align: center
        line-height: 24px
        border-radius: 50%
        display: inline-block
        position: absolute
        left: 0
        top: 0
