.header
  z-index: 4
  min-height: 81px
  display: none
  align-items: center
  flex-direction: column
  position: relative
  background-color: $mainWhite
  border-bottom: 1px solid $mainGrey
  +max($tablet)
    min-height: unset
    display: flex
    flex-direction: column
    align-items: flex-start
  +max($mobile)
    min-height: 50px  
  &--logo-bg
    position: relative
  &__search
    .form-control
      background-color: $mainWhite
    &-wrapper
      width: 100%
      background-color: $lightGrey
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) 
      .search
        margin: 20px 0
    &-tip
      margin-top: 12px
      display: flex
      font-size: $extraSmallFontSize
      color: $darkBlue
      font-family: $avenirNextMedium
      .material-icons,
      .material-icons-outlined
        font-size: 16px
        margin-right: 6px 
    &-desk
      +max($mobile)
        display: none
    &-mobile
      display: none
      +max($mobile)
        display: flex   

.header-container
  position: relative
  z-index: 2
  grid-template-columns: 356px 1fr auto
  width: 100%
  display: grid
  grid-column-gap: 30px
  align-items: center
  min-height: 81px
  +max(1300)
    grid-template-columns: 156px auto
  +max($mobile)
    min-height: 50px
    grid-template-columns: 93px auto
    grid-column-gap: 20px   

  &.header-login
    
    .dialogs-preview
      .none-notification
        font-family: $avenirNextMedium
        font-size: $smallFontSize
        text-align: center
        padding: 10px

    .dialogs-preview,
    .notification-preview
      .cabinet-sidebar-nav__nums
        top: 25%
        right: 2px
        +flex-center()
        +max($mobile)
          top: 10%
          right: -5px
          min-width: 21px
          min-height: 21px
          height: 21px
        &:empty
          display: none

    .cabinet-preview 
      > a
        color: $darkBlue
        font-family: $avenirNextDemi
        font-size: $regularFontSize

  &__left,
  &__right
    display: flex
    align-items: center

  &__right
    position: relative
    z-index: 20
    justify-content: flex-end
    .button_small.button_small_secondary
      margin-right: 10px
      .material-icons,
      .material-icons-outlined
        color: $hoveredMainGreen
        +max($mobile)
          color: $darkBlue
          font-size: 20px
      +max($mobile)
        width: unset
        height: unset
        min-width: unset
        background-color: transparent  
        border: none  
      @media not all and (pointer: coarse)
        &:hover
          background-color: $mainGreen
          border-color: $mainGreen
          .material-icons,
          .material-icons-outlined
            color: $mainWhite
            
    .btn-wrap.btn-wrap--no-margin
      .button
        color: $hoveredMainGreen
        .material-icons,
        .material-icons-outlined
          color: $hoveredMainGreen
        &:hover
          color: $mainWhite
          background-color: $mainGreen
          border-color: $mainGreen
          .material-icons,
          .material-icons-outlined
            color: $mainWhite
        + .button
          margin-left: 10px
      +max($mobile)
        display: none       
    .lang
      &.header-container__lang
        +max($mobile)
          display: none
      &__now
        font-family: $avenirNextDemi
    .dropdown-menu
      padding: 20px
      border-radius: 4px
      left: -50% !important
      border: none
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25)
      background-color: $mainWhite
      border: 1px solid $mainGreen
      margin-top: 10px
      .search
        min-width: 480px
        .form-control
          background-color: $mainWhite
          &:focus
            background-color: $mainWhite     
    .nav-btn
      display: none
      +max(1440)
        display: flex
        margin-left: 16px
      +max($mobile)
        margin-left: 12px  
  &__center
    display: flex
    align-items: center
    min-height: 81px
    +max(1440)
      display: none
    +max($mobile)
      display: none
    .news-nav
      min-height: 81px
      height: 100%
      border: none
      border-radius: unset
      justify-content: center
      padding: unset
      &__link
        min-height: 81px
        font-family: $avenirNextDemi
        font-size: $regularFontSize
        color: $darkBlue
        white-space: nowrap
  &__lang
    margin-right: 0
    +max($mobile)
      display: none
    
  &__logo
    img
      width: 132px
      min-width: 132px
      object-fit: contain
      +max($mobile)
        width: 93px
        min-width: 93px
  &__menu
    display: flex
    align-items: center
    margin-left: 15px

  &__auth-btn,
  &__register-btn
    &.auth-btn,
    &.dropdown
      +max($mobile)
        margin-right: 10px
        padding: 12px 13px
        font-size: $regularFontSize
        i
          font-size: $regularFontSize
          margin-right: 10px