#modal-container
    position: fixed
    display: table
    height: 100vh
    width: 100%
    top: 0
    left: 0
    transform: scale(0)
    z-index: 20
    &.data-modal
        transform: scaleY(0.01) scaleX(0)
        animation: unfoldIn 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
        .gallery-modal-background .gallery-modal
            transform: scale(0)
            animation: zoomIn 0.5s 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
    &.out
        transform: scale(1)
        animation: unfoldOut 0.8s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards

        .gallery-modal-background .gallery-modal
            animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards

    .gallery-modal-background
        display: table-cell
        background: rgba(0, 0, 0, .7)
        text-align: center
        vertical-align: middle
        .gallery-modal
            background: black
            width: 100vw
            margin: auto
            display: flex
            flex-direction: column
            position: relative
            z-index: 21
            overflow: hidden
            &-close
                padding: 15px
                height: 60px
                min-width: 60px
                width: 60px
                border-radius: 50%
                background-color: $mainWhite
                border: 1px solid $mainGreen
                position: absolute
                top: 25px
                right: 25px
                cursor: pointer
                z-index: 25
                transition: .6s ease
                +max($mobile)
                  height: 40px
                  min-width: 40px
                  width: 40px
                  top: 20px
                &:after,
                &:before
                    content: ''
                    left: 0
                    right: 0
                    bottom: 0
                    top: 0
                    margin: auto
                    content: ''
                    height: 18px
                    width: 2px
                    background-color: $mainGreen
                    position: absolute
                &:before
                    transform: rotate(45deg) 
                &::after
                    transform: rotate(-45deg)
                &:hover
                  background-color: $mainGreen
                  transition: .3s ease-in-out
                  &:after,
                  &::before
                    transition: .3s ease-in-out
                    background-color: $mainWhite             
@keyframes unfoldIn
  0%
    transform: scaleY(0.005) scaleX(0)

  50%
    transform: scaleY(0.005) scaleX(1)

  100%
    transform: scaleY(1) scaleX(1)

@keyframes unfoldOut
  0%
    transform: scaleY(1) scaleX(1)

  50%
    transform: scaleY(0.005) scaleX(1)

  100%
    transform: scaleY(0.005) scaleX(0)       
@keyframes zoomIn
  0%
    transform: scale(0)

  100%
    transform: scale(1)

@keyframes zoomOut
  0%
    transform: scale(1)

  100%
    transform: scale(0)   