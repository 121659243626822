.arrow__next,
.arrow__prev
    +size(60px)
    min-width: 60px
    border: 1px solid $mainGreen
    border-radius: 50%
    +flex-center()
    color: $mainGreen
    background-color: $mainWhite
    transition: .3s ease-in-out
    cursor: pointer
    -webkit-touch-callout: none
    -webkit-user-select: none  
    -moz-user-select: none 
    -ms-user-select: none  
    user-select: none
    text-decoration: none
    +max($mobile)
      +size(40px)
      min-width: 40px
    &:hover,
    &:focus
        text-decoration: none
        transition: .3s ease-in-out
        color: $mainWhite
        background-color: $mainGreen
.arrow__prev--position,
.arrow__next--position
  position: absolute
  z-index: 5
  top: calc(50% - 20px)
  +max($desktop)
    height: 40px
    width: 40px
    min-width: 40px
.arrow__prev--position
  left: -70px
  +max($desktop)
    left: -10px
  +max($tablet)
    left: -15px
.arrow__next--position
  right: -70px
  +max($desktop)
    right: -10px
  +max($tablet)
    right: -15px