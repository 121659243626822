.dashboard-expert-messages
  position: relative
  padding: 0
  display: flex
  flex-direction: column
  justify-content: flex-start
  .dashboard-item__content
    padding: 20px
    overflow: visible
    max-height: 610px
    min-height: 400px
    &--empty
      margin: auto
      &-img
        margin: auto
  .dialogs-item
    display: flex
    justify-content: space-between
    flex-wrap: nowrap
    padding: 20px
    width: 100%
    &__pic
      width: 34px
      height: 34px
      img
        width: 34px
        height: 34px
        border-radius: 17px
    &__bodye
      display: flex
      flex-direction: column
      justify-content: space-between
      flex-wrap: wrap
      width: calc( 100% - 44px )
    &__top
      display: flex
      justify-content: space-between
      flex-wrap: nowrap
    &__date
      font: 12px $avenirNextRegular
      color: #778A9E
    &__full-name
      font: 14px / 19px $avenirNextDemi
      color: #000
    &__message
      width: 100%
      font: 13px $avenirNextMedium
      color: #010101
      margin: 0
      max-height: 13px
  &__btn-wrap
    position: absolute
    bottom: 0
    left: 0
    display: flex
    justify-content: center
    width: 100%
    background: #fff
    padding: 20px
  &__main-btn
    width: 100%
    min-height: 50px
    background: #32D29F
    color: #fff
    font: 16px $avenirNextMedium
    display: flex
    align-items: center
    justify-content: center
    border-radius: 5px
    text-decoration: none
    cursor: pointer
    transition: all 0.3s ease
    &:hover
      text-decoration: none
      color: #fff
      background: #2BBB97
      box-shadow: 0 5px 10px rgba(0,0,0,0.2)
