.likes
  display: flex
  align-items: center
  color: #C8C8C8
  opacity: .7
  font: 12px $avenirNextMedium

  span
    display: flex
    align-items: center
    
    &:first-child
      margin-right: 10px

      i
        position: relative
        top: 2px

    &:last-child
      margin-right: 10px

      i
        position: relative
        bottom: 2px
        
    i
      font-size: $regularFontSize
      margin-right: 4px