.modal-add-schedule
    &__preloader
        position: absolute
        left: 50%
        top: 50%
        width: 0
        height: 0
    .checkbox
        width: calc(100% / 3 - 15px)
        margin-top: 20px
        margin-bottom: 0
        +max($mobile)
            width: 50%
        &.invalid
            flex-wrap: wrap
            .form-group__error-list
                width: 100%
        &__text
            cursor: pointer
            font-size: $regularFontSize
            margin-bottom: 0
    .checkbox-table
        width: unset
        margin-top: 0
        margin-bottom: 0
    .vacation-edit
        margin-top: 30px
    &__workdays
        display: flex
        flex-wrap: wrap
    &__wrap
        margin-bottom: 30px
        .form-wrap
            max-width: 260px
            position: relative
            +max($mobile)
                flex-wrap: nowrap
            .select-custom  + .select-custom
                margin-left: 10px
        .text_medium
            margin-bottom: 20px
            &.mb-none
                margin-bottom: 0
        .form-group + .form-group
            margin-top: 20px
    &__row
        margin-bottom: 30px
    &__description
        font: $extraSmallFontSize $avenirNextMedium
        color: $mainBlack
        line-height: 24px
        a
            color: $mainBlue
            text-decoration: underline
    .radio-wrap
        .radio
            margin-bottom: 10px
        .radio__ico
            background: $mainWhite
        .radio__text
            font: $regularFontSize $avenirNextRegular
            color: $mainBlack

    .notification-block
        border: 1px solid $textGreyLight
        background: $backgroundGrey
        display: none
        &.active
            display: block
            animation: open 0.5s ease
        &__header
            padding: 20px 25px
            border-bottom: 1px solid $textGreyLight
        &__title
            font: $regularFontSize $avenirNextMedium
            color: $mainBlack
        &__body
            padding: 25px 25px 30px
        &__tab
            display: none
            &.active
                display: block
                animation: open 0.5s ease
            input.form-control
                background: $mainWhite
    .btn-wrap--mobile-fill
        +max($mobile)
            .button
                width: 100%
    & .modal-nav-tabs
        margin-bottom: 0px
        border: unset
        padding: 20px 0px 0px
        border-top: 1px solid grey
        border-radius: unset
    & .modal-schedule-tabs
        border: unset
        padding: 20px 0px 0px
        border-radius: unset

@keyframes open
    0%
        display: block
        opacity: 0
        visibility: hidden
    100%
        display: block
        opacity: 1
        visibility: visible
