.my-records
    .actions-wrapper
        grid-template-columns: 1fr 160px
        +max($mobile)
            grid-template-columns: 1fr
            .button
                width: 160px
        .search
            max-width: 100%
        &.actions-wrapper__filters
            grid-template-columns: 1fr 1fr 160px
            +max($mobile)
                grid-template-columns: 1fr 1fr
                .button
                    width: 160px
            +max($mobileL)
                grid-template-columns: 1fr