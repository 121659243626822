.concilium-panel
  width: 100%
  display: flex
  border-top: solid $borderGrey 1px
  border-bottom: solid $borderGrey 1px
  padding: 10px 0
  justify-content: space-between
  &__column
    margin-right: 20px
    &:last-child
      margin-right: 0
  &__info-title
    width: 100%
    display: inline-block
    font: $smallFontSize $avenirNextDemi
    color: $textGrey
    margin-bottom: 8px
  &__user
    display: flex
    width: 100%
  &__user-pic
    margin-right: 8px
    border: 1px solid $mainBlue
    overflow: hidden
    border-radius: 50%
    flex-shrink: 0
    display: inline-block
    +size(30px)
    &--range
      margin-right: 0
      margin-left: -20px
      &:first-child
        margin-left: 0
    img
      max-width: 100%
      max-height: 100%
  &__user-name
    display: block
    font: $extraSmallFontSize $avenirNextMedium
    color: $mainBlack
    &:hover
      color: $mainBlack
  &__user-specialization
    font: $minimalFontSize $avenirNextMedium
    color: $darkGrey
  &__status
    font: $smallFontSize $avenirNextMedium
    color: $mainBlack
    &--new
      color: $mainBlack
    &--confirmed
      color: $mainGreen
    &--pending
      color: $mainRed
    &--active
      color: $mainBlack
    &--confirmed
      color: $mainBlack
    &--without-conclusion
      color: $mainYellow
  &__start-time,
  &__duration
    font: $smallFontSize $avenirNextMedium
    color: $mainBlack
  &__user-quantity
    font: $regularFontSize $avenirNextDemi
    color: $mainWhite
    background: $mainBlue
    +size(30px)
    +flex-center()

.concilium-member
  display: flex
  border-bottom: 1px solid #D2E1F5
  align-items: center
  padding: 17px
  &__pic
    width: 60px
    height: 60px
    border: 2px solid $mainBlue
    margin: 0 15px 0 0
    border-radius: 50%
    overflow: hidden
    flex-shrink: 0
    img
      max-width: 100%
      max-height: 100%
  &__info
    margin-right: 15px
  &__name
    font: $smallFontSize $avenirNextMedium
    line-height: 15px
    margin-bottom: 10px
    color: $mainBlack
  &__specilisation
    font: $extraSmallFontSize $avenirNextRegular
    color: $mainGrey
    line-height: 15px
  &__price
    font: $demiFontSize $avenirNextMedium
    color: $darkBlue
    line-height: 25px
    margin-left: auto
    white-space: nowrap
    &--orginizer
      margin-right: 56px
  .btn-wrapper
    margin-left: 20px

.concilium-decline-member-modal
  .modal-header
    padding: 30px 40px
  h5
    font: $bigFontSize $avenirNextMedium
    color: $mainBlack
    line-height: 29px
  .close
    top: 28px
    right: 30px
    span
      color: $mainGrey
      font-size: $demiFontSize
  &__body
    font: $demiFontSize $avenirNextMedium
    color: $mainBlack
  .btn-wrap
    border-top: none
  &.modal
    .modal-body
      padding: 30px 40px
    .modal-footer
      padding: 0 40px 30px
