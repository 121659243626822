.dashboard
    &-body
        display: flex
        justify-content: space-between
        width: 100%
        flex-wrap: wrap
    &-item
        background-color: $mainWhite
        border-radius: 8px
        border: 1px solid $borderGrey
        margin-top: 30px
        overflow: hidden
        &:nth-child(-n+2)
            margin-top: 0
            +max($tablet)
                margin-top: 30px
        &:first-child
            margin-top: 0        
        &__subheading
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%
            padding: 0 20px 0 20px
            &-right,
            &-left
                display: flex
                align-items: center
        &__heading
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            width: 100%
            min-height: 70px
            align-items: center
            padding: 10px 20px 10px 20px
            border-bottom: 1px solid #D2E1F5
            &.no-border
                border: none
            &-title
                font-family: $avenirNextRegular
                font-size: $demiFontSize
                color: $mainBlack
            &-more
                color: $mainBlue
                font: $regularFontSize $avenirNextDemi
                text-decoration: none
                line-height: 20px
                height: 20px
                i
                    color: $mainBlue
                    text-decoration: none
                    vertical-align: middle
                    line-height: 20px
                    height: 20px
                    margin-bottom: 2px
                &:hover
                    font: $regularFontSize $avenirNextDemi
                    color: $hoveredMainBlue
                    height: 20px
                    line-height: 20px
                    i
                        color: $hoveredMainBlue
                        text-decoration: none

            .context-menu
                min-width: 40px
                height: 39px
                display: none
            &-actions
                height: 100%
                display: flex
                align-items: center
                +max($laptopM)
                    width: 100%
                &.dashboard-item__heading-selects
                    +max($laptopM)
                        margin-top: 15px
                    .select-custom
                        + .select-custom
                            margin-left: 15px
                &.dashboard-nowrap-actions
                    +max($laptopM)
                        width: unset
                    .dashboard-filter-btn
                        display: none
                        +max($mobile)
                            width: 30px
                            height: 30px
                            display: flex
                            justify-content: center
                            align-items: center
                            border-radius: 50%
                            border: 1px solid #D2E1F5
                            i
                                font-size: $demiFontSize
            &-tabs
                min-height: 39px
                display: flex
                align-items: center
                +max($laptopM)
                    width: 100%
            &-tab
                height: 100%
                min-height: 39px
                font-size: $smallFontSize
                display: flex
                align-items: center
                color: $mainBlack
                cursor: pointer
                position: relative
                +max($laptopM)
                    width: calc(100% / 3)
                    justify-content: center
                &:after
                    content: ''
                    display: flex
                    width: 0
                    height: 1px
                    position: absolute
                    bottom: -11px
                    left: 0
                    right: 0
                    background-color: $mainGreen
                    transition: .5s ease
                &.active,
                &:hover
                    color: $mainGreen
                    transition: .5s ease
                    &:after
                        width: 100%
                        transition: .5s ease
                        
                + .dashboard-item__heading-tab
                    margin-left: 20px
                &:last-child
                    margin-right: 20px        

        &.small-item
            width: calc(100% - 66.6666% - 20px)
            @media(max-width: 1400px)
                width: calc(50% - 15px)
        &.half-item
            width: calc(100% / 2 - 15px)
            height: 400px
        &.middle-item
            width: calc(100% - 33.3333% - 10px)
            height: 435px
        &.small-item
            height: 435px
        &.full-item 
            width: 100%
        &.small-item,
        &.half-item,
        &.middle-item,
        &.full-item
            +max($tablet)
                width: 100%    
        &__content
            display: flex
            flex-wrap: wrap
            padding: 20px
            position: relative
            overflow-x: hidden
            overflow-y: auto
            max-height: 500px
            &.full-height
                max-height: none
                padding: 0
            &.graph-height
                padding-top: 0
                padding-bottom: 0
                +max($mobile)
                    max-height: 450px  
            &-monitoring
                display: flex
                justify-content: space-between
                align-items: center
                width: 100%
                margin-bottom: 5px
                padding: 12px 10px
                border: 1px solid #D2E1F5
                border-radius: 8px
                &:last-child
                    margin-bottom: 0
            &-personaldata
                display: flex
                justify-content: space-between
                align-items: baseline
                width: 100%
                flex-wrap: wrap
            &-graphs    
                display: flex
                flex-direction: column
                width: 100%
                .dynamics-big-block
                    margin-bottom: 0
                    padding: 0
                    +max($mobile)
                        margin-top: 0   
                    &__item
                        padding: 0
                        border-radius: 0
                        margin-top: -40px
                        +max($mobile)
                            overflow-y: auto
            &--empty
                display: flex
                flex-direction: column
                width: 100%
                height: 100%
                &-img
                    width: 180px
                    margin: 0 auto
                &-message
                    font-size: $smallFontSize
                    color: $mainBlack
                    margin-bottom: 20px
                &-link
                    color: #2C4D6E                     
            .owl-dots
                padding-top: 20px                 
        &__actions
            padding: 20px 0  
        &__bottom-actions
            padding: 0 20px 20px 20px
            .checkbox
                margin-bottom: 0
            &.graphs-bottom-actions
                display: none                
    &-arrow
        display: flex
        align-items: center
        justify-content: center
        min-width: 30px
        width: 30px
        height: 30px
        border-radius: 50%
        border: 1px solid #D2E1F5
        background-color: $mainWhite
        cursor: pointer
        -webkit-user-select: none
        -moz-user-select: none
        -khtml-user-select: none
        -ms-user-select: none
        i
            font-size: $demiFontSize
        + .dashboard-arrow
            margin-left: 10px    
    &-controls
        display: flex
        align-items: center
    &-explanation-holder
        display: none    
    &-explanation
        display: flex
        justify-content: space-between
        width: 100%
        transition: .3s ease
        padding: 10px 20px 0 20px
        +max($desktop)
            flex-wrap: wrap
        +max($mobileL)
            flex-wrap: wrap
        &-borders
            +max($mobileL)
                width: 100%
                text-align: center
                order: 2
                margin-top: 5px
            span
                font-size: $smallFontSize
                font-family: $avenirNextMedium
                color: #5F5F5F
        &-diff
            display: flex
            align-items: center
            +max($desktop)
                width: 100%
                justify-content: center
                order: 3
                margin-top: 15px
            +max($mobileL)
                width: 100%
                justify-content: center
            .explanation
                &__value,
                &__norm 
                    display: flex
                    align-items: center
                    text-transform: uppercase
                    color: $mainBlack
                    font-size: 10px
                    padding: 2px 6px
                    background-color: $mainWhite
                    border: 1px solid $halfGrey
                    border-radius: 20px
                    &-mark
                        width: 8px
                        height: 8px
                        display: flex
                        margin: auto
                        border-radius: 50%
                        margin-right: 5px
                        &.value
                            background-color: $mainGreen
                        &.norm
                            background-color: $halfGrey
                &__value
                    margin-right: 10px
                        
        &-controls
            display: flex
            align-items: center
            +max($mobileL)
                width: 100%
                justify-content: space-between
            .periods
                min-width: 60px
                display: flex
                justify-content: center
                align-items: center
                font-size: 13px
                color: $mainBlack
                font-family: $avenirNextMedium
            .dynamic-control
                outline: none
                border: none
                background-color: transparent
                display: flex
                align-items: center
                justify-content: center
.dashboard-body--expert
    .dashboard-item
        padding-bottom: 20px



