.dashboard-personal-data
    .dashboard-item__content
        padding: 20px 30px
        height: 370px
    .personal-data
        width: 100%
        &__wrapper
            line-height: 17px
            margin-bottom: 15px
            &:last-child
                margin-bottom: 0
        &__row
            width: 100%
            display: flex
            margin-bottom: 20px
            .personal-data__wrapper
                width: 50%
                margin-bottom: 0
        &__title
            font: $smallFontSize $avenirNextMedium
            color: $textGrey
            display: block
        &__value
            font: $smallFontSize $avenirNextMedium
            color: $mainBlack
