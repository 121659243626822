//extended date-picker
.wrap-calendar
  position: absolute
  padding: 20px
  border-radius: 5px
  background-color: $mainWhite
  display: none
  margin: 10px 0 20px 0
  +max($mobileM)
    padding: 15px 10px
  &__heading
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-bottom: 5px
    &-title
      font-family: $avenirNextDemi
    &-today
      background-color: transparent
      border: none
      outline: none
      color: $mainGreen
      font-family: $avenirNextDemi
      padding: 0

  &.active
    display: block
    min-width: 300px
    max-width: 330px
    min-height: 420px
    border: 1px solid $halfGrey
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05)
    +max($mobileM)
      min-width: 280px