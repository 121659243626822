footer
  flex: 0 0 auto

.footer
  background-color: $lightGrey
  +max($mobile)
    margin-top: 40px
  .container
    +max($laptop)
      padding: 0 39px
    +max($mobile)
      padding: 0 20px
  &-apps
    background: linear-gradient(175.17deg, #2BBB97 39.05%, #25AEF6 70.06%)
    min-height: 100%
    &__wrapper
      display: grid
      grid-template-columns: 330px auto 330px
      grid-gap: 30px
      +max($laptop)
        padding-top: 7px
        grid-template-columns: repeat(2, 1fr)
        grid-row-gap: 40px
      +max($mobile)
        padding: 20px 0
        grid-template-columns: 1fr
        grid-row-gap: 15px
    &__left
      display: flex
      justify-content: space-between
      align-items: flex-end
      width: 100%
      position: relative
      margin-top: -70px
      overflow: hidden
      +max($laptop)
        display: none
      img
        max-width: 100%
        height: 100%
    &__centerContainer
      height: 94px
      overflow: hidden
      display: flex
      justify-content: center
    &__center
      +flex-center()
      color: white
      font-size: $biggerFontSize
      font-family: $avenirNextDemi
      overflow: hidden
      +max($laptop)
        font-size: $largeFontSize
      +max($mobile)
        justify-content: start
        font-size: $regularFontSize
    &__right
      margin-top: -65px
      +flex-center()
      +max($laptop)
        justify-self: end
        margin-top: -35px
      +max($mobile)
        justify-self: start
        margin-top: -35px
      a
        text-decoration: none
        transition: opacity .3s ease-in-out
        &:hover
          text-decoration: none
          transition: opacity .3s ease-in-out
        + a
          margin-left: 10px
        img
          max-width: 100%
  &-main
    display: grid
    grid-template-columns: 330px auto 330px
    grid-gap: 30px
    padding: 60px 0
    +max($laptop)
      grid-template-columns: repeat(2, 1fr)
    +max($mobile)
      grid-template-columns: 1fr
      padding: 60px 0 30px 0
    &__block
      &--first
        +max($laptop)
          margin-right: 30px
        +max($mobileL)
          margin-bottom: 30px
      &--holder
        display: flex
        justify-content: space-around
        +max($laptop)
          grid-column: 1/-1
        +max($mobileL)
          flex-wrap: wrap
          justify-content: start
        .footer-main__block
          +max($laptop)
            flex-basis: 50%
          +max($mobileL)
            flex-basis: 99%
      &.lang-block
        justify-self: center
        +max($laptop)
          justify-self: start
          grid-row: 1/2
          grid-column: 2/3
        +max($mobile)
          grid-row: auto
          grid-column: auto
    &__title
      font-size: $mediumFontSize
      font-family: $avenirNextDemi
      margin-bottom: 30px
      +max($mobile)
        margin-bottom: 20px
    &__subtitle
      font-size: $smallFontSize
      font-family: $avenirNextLight
      line-height: 17px
      margin-bottom: 24px
      color: $darkGrey

    &__link
      display: block
      opacity: .9
      font-size: $regularFontSize
      margin-bottom: 15px
      font-family: $avenirNextMedium
      color: $darkBlue
      line-height: 1
      +max($mobile)
        font-size: $smallFontSize
        font-family: $avenirNextRegular
      &:last-child
        margin-bottom: 0

      &:hover
        text-decoration: underline
    &__socials
      display: flex
      align-items: center
      margin-bottom: 24px
      &--item
        + .footer-main__socials--item
          margin-left: 7px
  &-mail,
  &-phone
    margin-bottom: 15px
    font-size: $regularFontSize
    font-family: $avenirNextRegular
    color: $darkBlue
    display: flex
    align-items: center
    .material-icons-outlined,
    .material-icons
      margin-right: 10px
    a
      color: $darkBlue
      transition: .3s ease-in-out
      &:hover
        transition: .3s ease-in-out
        color: $mainGreen
        text-decoration: underline

  &-bottom
    border-top: 1px solid $halfGrey
    padding: 30px 0
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 30px
    &__wrap-copyright
      display: flex
      align-items: center
      color: $darkBlue
      font-size: $regularFontSize
      .copyright
      +max($mobile)
        font-size: 14px
    &__dreamext-logo
      text-align: right
    &__footer-logo
      min-width: 22px
      width: 22px
      margin-right: 10px
    &__nav
      justify-self: flex-end
      display: flex
      align-items: center
      &-item
        margin-right: 12px
        &:last-child
          margin-right: 0
      &-link
        color: $darkGrey
        font-size: 13px
        font-family: $avenirNextRegular
        transition: .3s ease-in-out
        &:hover
          color: $mainGreen
          text-decoration: none
    &__payment-systems
      display: flex
      justify-content: flex-end
      align-items: center
      .visa-logo
        width: 93px
        height: 30px
        +max($laptop)
          width: 60px
          height: 20px
      .master-card-logo
        width: 46px
        height: 30px
        margin-right: 30px
        +max($laptop)
          width: 32px
          height: 22px
          margin-right: 2px
  &__nav
    &-link
      color: $darkBlue
      text-decoration: underline
      font: $smallFontSize $avenirNextMedium
      margin-right: 38px
      &:last-child
        margin-right: 0
      &:hover
        text-decoration: none

.footer-phone
  display: flex
  min-height: 100px
  align-items: baseline
  &-hide
    display: none
  &-show
    display: block
    cursor: pointer
    color: #16395B
    transition: .3s ease-in-out
    &:hover
      color: var(--mainGreen)
