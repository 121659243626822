.concilium-card
  padding: 30px
  background: $mainWhite
  border-radius: 8px
  border: 1px solid $borderGrey
  margin-top: 30px
  &--without-conclusion
    border: 2px solid $mainYellow
  &__wrapper
    display: flex
    align-items: center
    margin-bottom: 20px
    &--space-between
      justify-content: space-between
  &__title
    font: $demiFontSize $avenirNextMedium
    color: $mainBlack
  &__price
    font: $demiFontSize $avenirNextDemi
    color: $darkBlue
    display: flex
    justify-content: flex-end
    i
      margin-right: 10px
  &__desc
    font: $regularFontSize $avenirNextLight
    color: $mainBlack

