.text_extra-large
    font: $extraLargeFontSize $avenirNextBold     // 36px Bold - h1
.text_large
    font: $largeFontSize $avenirNextBold          // 30px Bold - h2
.text_big
    font: $bigFontSize $avenirNextDemi            // 24px Demi - h3
.text_demi
    font: $demiFontSize $avenirNextDemi           // 20px Demi - h4
.text_medium
    font: $mediumFontSize $avenirNextMedium       // 18px Medium - h5
.text_regular
    font: $regularFontSize $avenirNextMedium      // 16px Medium - h6
.text_small
    font: $smallFontSize $avenirNextRegular       // 14px Regular - p
.text_extra_small
    font: $extraSmallFontSize $avenirNextRegular  // 12px Regular - p

.text_extra_small,
.text_small,
.text_regular,
.text_medium,
.text_demi,
.text_big,
.text_large,
.text_extra-large
    &.text_green
        color: $mainGreen
        text-decoration: none
        &:hover
            text-decoration: none
            color: $mainGreen



