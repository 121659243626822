.lang-list
    display: flex
    align-items: center
    &__item
        + .lang-list__item
            margin-left: 12px
        &--link
            opacity: .4
            img
                +size(40px)
            &.active
                opacity: 1