.statistic-block
  display: flex
  align-items: center
  text-decoration: none
  margin-top: 15px
  &:hover
    text-decoration: none
  $t: &
  &.statistic-block__counters
    align-items: flex-start
  &.statistic-block-medium
    #{$t}__result
      font-size: $smallFontSize
      font-family: $avenirNextMedium
  &.green-title
    #{$t}__result
      color: $mainGreen
  &__body
    line-height: 1.1
  &__ico
    +size(32px)
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    background-color: $lightGrey
    margin-right: 10px
    i
      color: $mainBlue
      font-size: 18px
      width: 32px
      +flex-center
  
  &__result
    color: $mainBlack
    font-size: $regularFontSize
    font-family: $avenirNextDemi

  &__desc
    font-size: 13px
    color: $darkGrey
