.create-form
  border: 1px solid $halfGrey
  border-radius: 6px
  padding: 30px
  background-color: $mainWhite
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  
  .form-group
    + .form-wrap,
    + .form-group
      margin-top: 15px
  
  .form-wrap
    + .form-group,
    + .form-wrap
      margin-top: 15px
  
  .btn-wrap
    width: 100%
    margin-top: 60px

    &--mt
      margin-top: 15px
      +flex-center


  .radio
    &__ico
      margin-right: 11px

    &__text
      color: $mainBlack

  &__wrap
    width: calc(50% - 15px)
.form-group--big
  width: 640px
  +max($mobile)
    width: 100%
.form-group--medium
  width: 420px
  +max($mobile)
    width: 100%
.form-group--small
  width: 150px
  +max($mobile)
    width: 50%   