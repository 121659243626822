.info-nav
    max-width: 384px
    width: 100%
    background-color: transparent
    border: 1px solid $darkBlue
    border-radius: 6px
    padding: 8.5px 15px
    box-sizing: border-box
    cursor: pointer
    display: none
    &:hover
        .dropdown-body
            opacity: 1
            pointer-events: auto
    +max($tablet)
        display: block
    +max(400)
        padding: 10px 15px      
    &__body
        opacity: 0
        padding: 7px 0
        left: 0
        right: unset
        width: 100%
        margin-top: 5px
        transition: all .2s
        &:before
            content: ''
            height: 40px
            position: absolute
            bottom: 100%
            display: block
            left: 0
            right: 0
            top: -25px
        &:after
            display: none
        +max(400)
            padding: 4px 0    
    &__now
        display: flex
        align-items: center
        height: 100%
        font-size: $smallFontSize
        justify-content: space-between
        &-icon
            width: 10px
            height: 10px   
        +max(400)
            font-size: 12px  
        span
            +max($tablet)
                width: 100%
                display: flex
                align-items: center
                height: 100%      
    .static-nav__link    
        &.info-nav__link
            padding: 7px 15px
            font-size: $smallFontSize
            position: relative
            display: flex
            align-items: center
            +max(400)
                padding: 4px 15px
                font-size: 12px