.dashboard-expert-bookings
  &-items
    display: flex
    align-items: flex-start
    width: 100%
    overflow-x: auto
    overflow-y: hidden
    @media(max-width: 1024px)
      overflow-x: unset
      overflow-y: unset
    .owl
      &-item
        max-width: 284px
        @media(max-width: 1300px)
          max-width: unset
        img
          height: 100%
      &-stage
        display: flex
        padding-bottom: 10px
        &-outer
          @media(max-width: 1024px)
            overflow: unset !important

  &__item
    border: 1px solid #E0E7FF
    background-color: #fff
    border-radius: 5px
    padding: 20px
    transition: .3s ease-in-out
    display: flex
    flex-direction: column
  &__heading
    display: flex
    align-items: flex-start
    margin-bottom: 20px
  &__avatar
    width: 34px
    min-width: 34px
    height: 34px
    background-color: #fff
    border-radius: 50%
    margin-right: 10px
    img
      border-radius: 50%
      max-width: 100%
      width: 100%
  &__name
    font: 14px $avenirNextMedium
    color: #010101

  &__title
    font: 16px $avenirNextMedium
    color: #010101
    margin-bottom: 30px


  &__place
    display: flex
    align-items: center
    margin-bottom: 20px
    i
      margin-right: 10px
      color: #0943BF
      font-size: 16px
  &__location
    font: 14px $avenirNextMedium
    color: #010101
  &__datetime
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 20px
    i
      margin-right: 10px
      color: #0943BF
  &__date,
  &__time
    display: flex
    align-items: center
    width: 45%
    i
      font-size: 16px
      margin-right: 5px
    span
      font-size: 14px
      color: #010101
      font-family: $avenirNextMedium
  &__btn-wrapper
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
  &__secondary-btn
    width: calc( 50% - 5px)
    min-height: 40px
    background: #fff
    color: #000
    font: 14px $avenirNextMedium
    border: 1px solid #AFB9DD
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    text-decoration: none
    transition: all 0.3s ease
    &:hover
      text-decoration: none
      color: #000
      background: #E8E8E8
      box-shadow: 0 5px 10px rgba(0,0,0,0.2)

  &__main-btn
    width: calc( 50% - 5px)
    min-height: 40px
    background: #32D29F
    color: #fff
    font: 14px $avenirNextMedium
    display: flex
    align-items: center
    justify-content: center
    border-radius: 5px
    text-decoration: none
    cursor: pointer
    transition: all 0.3s ease
    &:hover
      text-decoration: none
      color: #fff
      background: #2BBB97
      box-shadow: 0 5px 10px rgba(0,0,0,0.2)
