.covid-window
  position: fixed
  bottom: 0
  left: 40px
  padding: 20px
  border-width: 1px
  border-style: solid
  border-radius: 5px 5px 0 0
  border-bottom: 0
  border-color: $halfGrey
  width: 100%
  max-width: 450px
  background: $mainWhite
  z-index: 100
  display: flex
  justify-content: space-between
  align-items: flex-start
  visibility: hidden
  transform: translateY(100%)
  transition: .6s ease-in-out
  @media(max-width: 575px)
    left: 15px
    max-width: 340px
  $t: &
  &.active
    transition: .6s ease-in-out
    visibility: visible
    transform: translateY(0)
  &.hidden
    transform: translateY(94%)
    #{$t}__close
      &::after
        transition: .6s ease-in-out
        transform: rotate(180deg)
  &__close
    color: $mainBlack
    width: 40px
    height: 21px
    border-width: 1px
    background-color: $mainWhite
    border-style: solid
    border-radius: 5px 5px 0 0
    border-color: $halfGrey
    border-bottom: 1px solid $mainWhite
    position: absolute
    +flex-center()
    top: -20px
    right: 30px
    text-decoration: none
    @media(max-width: 575px)
      right: unset
      left: 15px
    &:hover
      color: $mainBlack
      text-decoration: none
    &::after
      content: 'expand_more'
      direction: ltr
      display: inline-block
      font-family: 'Material Icons'
      font-size: 18px
      font-style: normal
      font-weight: normal
      letter-spacing: normal
      line-height: 1
      text-transform: none
      white-space: nowrap
      word-wrap: normal
      -webkit-font-feature-settings: 'liga'
      -webkit-font-smoothing: antialiased
      transform: rotate(0deg)
      color: $mainBlack
      transition: .6s ease-in-out
  &__img
    width: 100%
    max-width: 87px
    margin-right: 20px
  &__title
    color: $mainBlack
    line-height: 32px
  &__text
    color: $mainBlack
    line-height: 18px
    margin: 20px 0 40px 0