.tag
    +flex-center()
    padding: 0 14px
    min-height: 30px
    background-color: $lightGrey
    border-radius: 5px
    font-family: $avenirNextMedium
    font-size: $smallFontSize
    color: $mainBlack
    max-width: fit-content
    + .tag
        margin-left: 10px