// Big form devided into sections
.form-section
    padding: 30px 30px 40px 30px
    border: 1px solid $borderGrey
    border-radius: 8px
    background-color: $mainWhite
    &__heading
        padding-bottom: 20px
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
        font-size: $mediumFontSize
        font-family: $avenirNextMedium

.form-section + .form-section
    margin-top: 30px

// Group of fields which has 'add more' and 'delete' buttons 
.fieldset
    margin-top: 10px
    padding: 30px
    border: 1px solid $textGreyLight
    border-radius: 10px
    background-color: #FCFCFC
    &__btn
        margin-top: 20px

