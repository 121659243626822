.add-document
  min-width: 120px
  min-height: 50px
  display: flex
  justify-content: space-between
  align-items: center
  padding: 4px
  background-color: transparent
  border-radius: 2px
  border: 1px solid $halfGrey
  cursor: pointer
  position: relative
  &__wrap
    display: flex
    justify-content: space-between
  &__wrap-select
    width: 100%
  &--expert
    position: absolute
    right: 0
  +max($mobile)
    height: 36px

  &.record-add-document
    width: 100%
    position: static
    left: unset
    right: unset
    justify-content: space-between
  +max($mobile)
    height: 40px
  &--big-input
    width: 100%
    justify-content: space-between
    position: relative
  .form-group &
    margin-bottom: 0

  input
    position: absolute
    opacity: 0
    width: 100%
    height: 100%
    cursor: pointer

  &__text
    margin-left: 12px
    font-size: $smallFontSize
    font-family: $avenirNextMedium
    color: $mainBlack
    text-overflow: ellipsis
    white-space: nowrap
    width: 250px
    overflow: hidden
    display: flex
    align-items: center
    span
      +elipsis
    +max($mobile)
      display: flex
      align-items: center
      width: 100%
      margin-left: 6px
      span
        font-size: 12px

    i
      color: $mainBlack
      margin-right: 10px

  &__btn
    background-color: $mainWhite
    +flex-center()
    color: $hoveredMainGreen
    font-size: $smallFontSize
    font-family: $avenirNextDemi
    border: 1px solid $hoveredMainGreen
    height: 100%
    max-width: 100%
    padding: 5px 20px
    border-radius: 2px
    display: flex
    min-height: 40px
    align-items: center
    +max($mobile)
      font-size: 12px
