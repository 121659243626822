.profile-section
  .schedule
    &__item
      height: 44px
      width: 100%
      +flex-content()
      align-items: center
      & + .schedule__item
        border-top: 1px solid $borderGrey
    &__day
      font: $regularFontSize $avenirNextMedium
      color: $mainGrey
    &__time
      font: $regularFontSize $avenirNextMedium
      color: $darkGrey
