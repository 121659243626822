.cabinet-page
  position: relative
  overflow-x: hidden
  min-height: 100vh
  //background-color: $lightGrey
  ~ .footer
    background-color: $lightGrey
.cabinet-content-wrapper
  padding: 0px 32px
.cabinet
  // display: grid
  // grid-template-columns: 240px 3fr
  display: flex
  flex-direction: row
  align-items: stretch
  +max($laptop)
    display: flex
    flex-direction: column
    // grid-template-columns: auto
    // grid-column-gap: unset
  +max($mobile)
    //padding-top: 18px
  +max($mobileL)
    display: flex
    flex-direction: column
  &-content
    .no-chat-message
      display: none
  +max($mobile)
    //padding-top: 50px
    &-no-mobile
      .cabinet-content-body
        display: none
      .no-chat-message
        font-size: $smallFontSize
        padding: 15px
        width: 100%
        display: flex
        border: 1px solid $mainGreen
        background-color: $mainWhite
        border-radius: 7px
      &.cabinet-no-mobile-concilium
        .cabinet-content-header
          display: none
