.add-card
  &-info-form-wrap
    position: relative
    .card-date-error-js,
    .card-name-error-js,
    .card-card-error-js
      font-size: 12px
      color: $mainRed
      text-align: left
    .add-card-dropdown
      .form-wrap
        .dropdown
          &.bootstrap-select
          &.button-select
          &.button-select--big
            width: 100%
            margin-top: 20px
            +max($mobile)
              margin-top: 0
      .form-wrap
        &.add-card-refill-wrap
          flex-direction: column
          align-items: flex-start
          .form-group
            width: 100%
          .btn-wrap
            margin-top: 40px

      .btn-wrap
        margin-top: 60px

.form-wrap
  &.add-card-form-wrap
    display: flex
    align-items: center
    justify-content: space-between
    .form-group
      width: calc((100% - 30px) / 4)
      margin-bottom: 0
      .form-control
        text-align: center
.tooltip-container
  display: flex
  align-items: flex-end
  justify-content: space-between
  .measure_description
    cursor: pointer
    margin-bottom: 5px
    color: $mainBlue
