.cabinet-content
  // max-width: 1050px
  +max($laptop)
  width: 100%
  .contracts-wrapper
    .cabinet-content-header
      margin-bottom: 50px
      +max($mobile)
      margin-bottom: 0
  &-tooltip
    &-wrapper
      display: flex
      align-items: center
      .cabinet-content-title
        &.cabinet-monitoring-title
          margin-bottom: 0
          margin-right: 10px
    &-icon
      display: flex
      justify-content: center
      align-items: center
      min-width: 16px
      width: 16px
      height: 16px
      border-radius: 50%
      border: 1px solid $darkBlue
      img
        max-width: 100%
        width: 100%
  &-subheader
    display: flex
    width: 100%
    background-color: $mainWhite
    border-radius: 10px
    border: 1px solid $halfGrey
    padding: 15px 40px 20px 20px
    flex-direction: column
    margin: 24px 0
    &.client-screaning-subheader
      margin-top: 0
      +max($mobile)
      padding: 20px
    .monitoring-block-info
      font-size: $smallFontSize
    .btn-wrap
      margin-top: 5px
      &.btn-wrap-column
        +max($mobileL)
          flex-direction: column
        .button_borderless
          width: 100%

      .btn
      + .btn
        margin-left: 50px
        +max($mobileL)
          margin-left: 0
          margin-top: 20px

  .gallery-photo
    display: flex
    margin-right: 30px
    margin-bottom: 30px
    width: calc((100% - 60px) / 3)
    padding-top: 25.7802%
    position: relative
    img
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      object-fit: cover
    &:nth-child(3n)
      margin-right: 0
      +max($mobile)
      width: calc((100% - 40px) / 2)
      &:nth-child(3n)
        margin-right: 30px
      &:nth-child(2n)
        margin-right: 0
        +max($mobileL)
        width: 100%
        margin-right: 0
        padding-top: 50%
      &:nth-child(3n)
        margin-right: 0px
  i[data-modal-helper]
    cursor: pointer
.cabinet-content-header
  $cabinetContentHeader: &
  color: $mainBlack
  position: relative

  &.horizontal
    display: flex
    flex-direction: row
    width: 100%
    justify-content: space-between
    position: absolute
    top: 190px

    & #{$cabinetContentHeader}__left
      display: flex

    & #{$cabinetContentHeader}__right
      display: flex
      flex-shrink: 0
      width: unset

    & #{$cabinetContentHeader}__left,
    & #{$cabinetContentHeader}__left .cabinet-content-title
      margin-bottom: 0

  &--permissions,
  &--client-inshurer-contracts,
  &--cosultant-clients,
  &--client-booked-services
    flex-wrap: wrap
    .cabinet-content-subheader
      padding: 15px 50px 20px 30px
      .monitoring-block-info
        margin-bottom: 0
  &.documents-header
    +max($mobile)
    margin-bottom: 0

  .cabinet-content-title
    font: $bigFontSize $avenirNextDemi
    margin-bottom: 5px
    display: flex
    align-items: center
    .material-icons
      margin-left: 10px
      cursor: pointer
      +max($mobile)
      font-size: $mediumFontSize

  &.flex
    display: flex
    justify-content: space-between
    align-items: flex-end
    +max($mobile)
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      margin-bottom: 20px
    .button_borderless
      margin-right: 10px
      +max($mobile)
        justify-content: start
        transform: translateX(-5px)
        margin-left: 0
    &.cabinet-content-hr-tip
      flex-wrap: wrap
      .cabinet-content-header__right
        width: 100%
        margin-top: 20px
        +max($mobile)
          margin-top: 0
          margin-bottom: 20px
  &.cabinet-content-header-wrap
    flex-wrap: wrap
  &.cabinet-header-client-screaning
    flex-direction: column
    align-items: flex-start
    .cabinet-content-header__right
      .btn-wrap
        margin-top: 30px
        +max($mobile)
        margin-top: 0
  //&__left
  //  +max($mobile)
  //    margin-bottom: 15px

  &__right
    +max($mobile)
      width: 100%
    .btn
      &.btn-service-creation
        width: 100%
        max-width: 575px
        margin-left: 0
    .button_borderless
      +max($mobile)
        font-size: $smallFontSize
      i
        +max($mobile)
          font-size: $mediumFontSize
    .btn-wrap
      +max($mobile)
        width: 100%
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
      &.archive-btn-wrap
        flex-direction: column
        align-items: start
        .button_borderless
          margin-left: 0
          .btn
            margin-top: 0
            max-width: unset
            width: unset
            + .btn
              margin-left: 30px

      .btn + .btn
        margin-left: 25px
        +max($mobile)
        margin-left: 0
      .btn
        +max($mobile)
        width: 100%
        margin-top: 10px
        max-width: 400px
        &:first-child
          margin-top: 0
    &.dynamic-header-right
      +max($mobile)
      .btn-wrap
        flex-direction: row
        align-items: center
        justify-content: space-between
        .btn
          width: auto
          max-width: unset
          &:last-child
            margin-top: 0
.cabinet-content-subheader
  position: relative
  .content-show-button
    display: none
  &.expand-block
    .cabinet-content-subheader__wrapper
      max-height: 83px
      overflow: hidden
      transition: max-height 500ms ease
      position: relative
      &::after
        position: absolute
        content: ''
        display: block
        width: 100%
        height: 1em
        background: linear-gradient(0 , $mainWhite, rgba(255,255,255,0.001))
        opacity: 1
        bottom: 0
        left: 0
        transition: all 2000ms ease
      &.opened
        max-height: 1000px
        transition: all 2000ms ease
        &::after
          opacity: 0
    .filter-subheader
      max-height: 150px
    .content-show-button
      display: block
      position: absolute
      bottom: 10px
      right: 10px
      cursor: pointer
      width: 24px
      height: 24px
      i
        color: $mainBlue
.cabinet-content-title
  font-size: $mediumFontSize
  font-family: $avenirNextDemi
  margin-bottom: 30px
  color: $mainBlack
  +max($laptop)
  margin-bottom: 15px
  +max($mobile)
  font-size: 15px

.cabinet-content-subtitle
  max-width: 500px
  font: $smallFontSize $avenirNextMedium
  color: $mainBlack
  &-divider
    padding: 0 5px
.cabinet-content-subtitle__wrapper
  display: flex
  .cabinet-content-subtitle
    font: $smallFontSize $avenirNextLight
    +max($mobileM)
    font: $minimalFontSize $avenirNextMedium
  &__date
    margin-bottom: 5px
  &.documents-subtitle
    +max($mobile)
    font-size: 13px
    margin-top: 10px
    font-weight: 300
    &:first-child
      margin-top: 0

.cabinet-content-row
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  + .cabinet-content-row
    margin-top: 30px

.cabinet-block
  border: 1px solid #D2E1F5
  background-color: $mainWhite
  border-radius: 5px

.cabinet-block-wrap
  +max($mobile)
  width: 100%
  max-width: 100%
  &--documents-status
    width: calc(40% - 15px)
    +max($mobile)
    width: 100%
    margin: 25px 0

  &--add-document
    width: calc(60% - 15px)
    .add-file__error
      color: #FF3535
      font: 12px $avenirNextRegular
      +max($mobile)
      width: 100%
      .add-file__left
        width: 100%
      .add-file__name,
      .add-file__line
        label
          font-size: $smallFontSize
        .file-error-js
          font-size: 12px
      .add-file__name
        input
          border-radius: 7px
      .add-file__field
        height: 40px
        .add-file__btn
          height: 30px
          font-size: 12px

.cabinet-item
  border: 1px solid $halfGrey
  background-color: $mainWhite
  padding: 20px
  border-radius: 7px
  &:hover
    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.1)
    border: 1px solid $mainAqua
  .btn-wrap
    justify-content: space-between
    .btn-wrap__insure-customer-request
      flex-wrap: nowrap
      justify-content: space-between
      display: flex
      width: 100%
      .btn--cabinet
        padding: 0
        display: flex
        justify-content: center
        align-content: center
      .btn--cabinet.green
        width: calc(60% - 20px)
      .btn--cabinet.btn--grey
        width: 40%

  .btn
    text-align: center
    background-color: #F6F4FC
    justify-content: center
    margin-top: 30px
    &.border-green
      background: $mainWhite

    &.green
      background-color: $mainGreen

    &.grey
      background-color: #DCDCDC
      color: $mainBlack

.cabinet-large-wrapper
  background-color: $mainWhite
  border: 1px solid $halfGrey
  border-radius: 7px
  padding: 30px
  width: 100% // don`t touch
  +max($mobile)
    padding: 15px
    max-width: 100%
  form
    .form-group
      margin-top: 15px

.header-bottom
  margin-bottom: 10px

.screaning_content_button
  width: 100%
  .btn-wrap
    justify-content: end

.more-filter-info-container
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 10px 20px

.content-subheader-filter
  padding: 15px 40px 0px 20px
