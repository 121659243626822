.context-menu
  +size(32px)
  min-width: 32px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  position: relative
  user-select: none /* standard syntax */
  -webkit-user-select: none /* webkit (safari, chrome) browsers */
  -moz-user-select: none /* mozilla browsers */
  -ms-user-select: none

  i
    color: $mainGrey
    font-size: 30px

  &.active
    background-color: $lightGrey

    i
      color: $darkBlue

    .context-menu-list
      display: block
      position: absolute
      right: 0
      margin-top: 4px
      width: 230px

  &-list
    position: absolute
    top: calc(100% - 6px)
    right: 0
    background-color: $mainWhite
    display: none
    width: auto
    border-radius: 2px
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25)
    border: 1px solid $halfGrey
    padding: 0 0 10px 0
    z-index: 1
    +max($mobile)
      right: -50px
    &__heading
      font-size: $smallFontSize
      font-family: $avenirNextLight
      color: $mainBlack
      padding: 20px 35px
      border-bottom: 1px solid $halfGrey
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      &--title
        margin-right: 25px
      &--icon
        i.material-icons,
        i.material-icons-outlined
          cursor: pointer
          display: flex
          color: $textGreyLight
          font-size: 16px

  &-item
    font-size: $regularFontSize
    color: $darkBlue
    font-family: $avenirNextMedium
    padding: 5px 20px
    white-space: nowrap
    margin: 20px 0px 10px
    display: flex
    cursor: pointer
    i
      font-size: 24px
      margin-right: 10px
    + .context-menu-item
      margin-top: 10px
    &:hover
      color: $darkBlue
      text-decoration: none
    &__btn
      display: flex
      align-items: center
      font-size: unset
      font-family: $avenirNextMedium
      line-height: 1
      padding: 0
      text-decoration: none
      outline: none
      background-color: transparent
      color: $mainBlack
      border: none
      &:hover
        text-decoration: none
        color: $darkBlue
      &,
      i,
      span
        font-size: $regularFontSize
        color: $darkBlue
      i
        font-size: 24px
        margin-right: 10px

  &.without-ripple
    width: unset
    height: unset
    min-width: unset
