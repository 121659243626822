.modal-expert-map
  .modal-dialog
    max-width: 1172px
    max-height: 80vh
    height: 660px
    position: relative
  
  .modal-content
    border-radius: 10px
  
  &.modal
    .modal-body
      padding: 0
      border: 0
      box-shadow: 0 10px 20px 0px rgba(0,0,0,0.2)
      height: 80vh
      min-height: 500px
      
      .expert-map-card
        position: absolute
        left: 30px
        bottom: 30px

.expert-map-card
  background-color: $mainWhite
  box-shadow: 0 5px 10px 0px rgba(0,0,0,0.15)
  padding: 20px
  border-radius: 6px
  width: 330px
  display: flex
  justify-content: space-between
  flex-wrap: wrap

  &__user
    display: flex
    width: 100%
    align-items: center
    margin-bottom: 16px

  &__pic
    border-radius: 50%
    object-fit: cover
    +size(40px)
    margin-right: 9px

  &__name
    width: calc(100% - 9px - 40px)
    color: $mainBlack
    font: $regularFontSize / 1 $avenirNextMedium

    span
      display: block
      
      &:nth-child(2)
        margin-top: 3px
        color: #6D6D6D
        font-size: $smallFontSize
  
  &__adress-wrap
    width: 100%
    margin-bottom: 15px

  &__title
    color: $mainBlack
    font: $smallFontSize / 1 $avenirNextMedium
    margin-bottom: 7px

  &__adress
    font-size: 13px
    color: $mainBlack
    
    span
      display: block

      &:first-child
        color: #2E66E7

      &:last-child
        opacity: .7


  &__text
    opacity: 0.7
    color: $mainBlack
    font-size: 13px

    span
      display: block

  &__distance
    padding-right: 64px
    position: relative

    &-btn
      +size(34px)
      position: absolute
      right: 0
      top: 0
      border-radius: 50%
      +flex-center()
      padding: 0
      background-color: #E4EDFE
      border: 0

      i
        color: #0044D5
        font-size: $mediumFontSize
  
  &__btn
    margin-top: 60px
    width: 100%
    padding: 0
    align-items: center
    justify-content: center
    background-color: $mainGreen
    border-radius: 40px
    box-shadow: none
    color: #mainWhite
    font-size: $smallFontSize
    height: 40px  

