.partners-news
    &__list
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 40px
        +max($laptopM)
            grid-template-columns: repeat(2, 1fr)
        +max($mobile)
            grid-template-columns: 1fr
    &__item
        display: flex
        flex-direction: column
        height: 430px
        max-height: 100%
        +max($laptopM)
            height: auto
    &__pic
        min-height: 310px
        height: 310px
        max-width: 440px
        border-radius: 4px
        width: 100%
        margin-bottom: 30px
        +max($laptopM)
            height: 230px
            min-height: 230px
            min-width: 100%
            margin-bottom: 20px
        +max($mobileL)
            height: 150px
            min-height: 150px
            margin-bottom: 10px
    &__content
        height: 100%
        display: flex
        margin-bottom: 15px   
    &__title
        height: 100%
        max-height: 58px
        color: $darkBlue
        line-height: 29px
        overflow: hidden
        +max($laptopM)
            max-height: none
        &.links-effect
            +max($laptopM)
                font: $demiFontSize $avenirNextDemi
            +max($mobile)
                font: $regularFontSize $avenirNextDemi
            &:hover,
            &:focus
                color: $darkBlue
    &__links-wrapper
        height: 100%
        max-height: 58px
        overflow: hidden 
        +max($laptopM)
            max-height: none
    &__footer
        display: flex
        align-items: flex-end
        margin-top: auto
    &__date
        font-size: $extraSmallFontSize
        color: $mainGrey
        font-family: $avenirNextMedium         