.experts-reviews
  max-width: 768px
.review
  display: flex
  margin-top: 30px
  + .review
    margin-top: 40px

  &__pic
    margin-right: 40px
    +max($mobile)
      margin-right: 15px

    
    img
      +size(50px)
      border-radius: 50%
      object-fit: cover
  
  &__body
    width: calc(100% - 90px)
    display: flex
    flex-direction: column
    +max($mobile)
      width: 100%
    &-heading
      display: flex
      align-items: center
      margin-bottom: 20px
  
  &__author
    font-size: $smallFontSize
    font-family: $avenirNextDemi
    color: $mainBlack
    margin-right: 7px

  &__date
    font: 12px $avenirNextMedium
    opacity: .7
    color: $darkBlue

  &__message
    font-size: $smallFontSize
    color: $mainBlack
    font-family: $avenirNextRegular
    line-height: 20px
    margin-top: 20px
    
