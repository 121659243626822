.one-news__socials
    display: flex
    align-items: center
    &-item
        +size(32px)
        min-width: 32px
        margin-right: 2px
    &-link
        +size(24px)
        padding: 2px
        min-width: 24px  
        img
            max-width: 100%
            width: 100%