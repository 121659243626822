.profile-about
  .contact-info
    &__wrapper
      position: relative
      & + .contact-info__wrapper
        margin-top: 20px
    &__title
      font: $regularFontSize $avenirNextMedium
      color: $textGrayDarker
      margin-bottom: 8px
      +max($mobile)
        font-size: $extraSmallFontSize
      +flex-content()
    &__value
      font: $regularFontSize $avenirNextRegular
      color: $mainBlack
      +max($mobile)
        font-size: $extraSmallFontSize

  .profile-section--services
    .profile-section__wrapper
      padding: 30px
      +max($mobile)
        padding: 15px
  .profile-section--reviews
    .profile-section__wrapper
      padding: 0  0 30px 0
      .profile-section__empty
        padding: 20px 0 0 0
      .profile-section__read-more
        margin-left: 30px
      .experts-reviews
        max-width: none
        width: 100%
        .review
          display: flex
          margin-top: 0
          border-bottom: 1px solid $halfGrey
          padding: 30px

          &__body-heading
            margin-bottom: 8px
            +flex-content()
          &__pic
            margin-right: 20px
            img
              border: 2px solid $mainBlue
              width: 60px
              height: 60px
              border-radius: 50%
              object-fit: cover
          &__author
            font: $mediumFontSize $avenirNextMedium
            color: $mainBlack
          &__date
            font: $mediumFontSize $avenirNextMedium
            color: $mainGrey
          .my-review__recommend
            display: flex
            align-items: center
            color: $mainGreen
            font: $mediumFontSize  $avenirNextDemi
            i
              margin-left: 0
              margin-right: 10px
            &.negative
              color: $mainRed
          &__message
            font: $regularFontSize $avenirNextRegular
            color: $mainBlack
            margin-top: 15px

