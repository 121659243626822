.btn-wrap
  display: flex
  align-items: center
  width: 100%
  margin-top: 40px

  &__x-left-btn
    margin-right: 20px

  .button + .button,
  .button_medium + .button_medium,
  .button_small + .button_small,
  .button_smaller + .button_smaller,
  .button_borderless + .button_borderless,
  .button_regular + .button_regular
    margin-left: 30px
  .btn + .btn
    margin-left: 30px

  .button + .button-header
    margin-left: 0px
    margin-right: 30px
    &:last-child
      margin-right: 0px

  &--jcsb
    justify-content: space-between
  &--start
    justify-content: flex-start
  &--end
    justify-content: flex-end
  &--mt-none
    margin-top: 0
  &--mt20
    margin-top: 20px
  &--mt30
    margin-top: 30px
  &--mt40
    margin-top: 40px
  &--mt60
    margin-top: 60px
  &--mb20
    margin-bottom: 20px
  &--mb30
    margin-bottom: 30px
  &--mb40
    margin-bottom: 40px
  &--mb60
    margin-bottom: 60px  
  &--mr10
    margin-left: 10px
    +max($tablet)
      margin-left: 0       
  &--fill
    a,
    button
      width: 100%
  &--min-width
    width: unset
    a,
    button
      min-width: 180px
  &--unset-width
    width: unset
  &--column
    flex-direction: column
    .button + .button,
    .button_medium + .button_medium,
    .button_small + .button_small,
    .button_smaller + .button_smaller,
    .button_borderless + .button_borderless,
    .button_regular + .button_regular
      margin-left: 0
      margin-top: 20px
  &--mt-auto
    margin-top: auto
  &--no-margin
    margin: 0
    button,
    a,
    .button + .button,
    .button_medium + .button_medium,
    .button_small + .button_small,
    .button_smaller + .button_smaller,
    .button_borderless + .button_borderless,
    .button_regular + .button_regular
      margin: 0

  //old      
  &--dp-mt
    margin-top: 30px
    display: flex
    justify-content: space-between
  &__archive-circle-wrap
    display: flex
    flex-wrap: wrap
    width: 100%
  &--center
    justify-content: center
  &--documents
    margin-top: 25px
  &--home
    margin-bottom: 140px
  &--mt
    margin-top: 25px
  &--mb-none
    margin-bottom: 0
  &--indicators-entered
    justify-content: flex-end
  &--mb-20
    margin-bottom: 20px
  // old end    
