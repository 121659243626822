.btn-circle
  min-width: 20px
  margin: 0 0 0 10px
  height: 20px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  font-size: 14x
  i
    color: #ACB6DB
//.cabinet-sidebar
//  +max($laptop)
//    display: none
.cabinet-sidebar-block
  width: 100%
  border: 1px solid $halfGrey
  background-color: $mainWhite
  border-radius: 10px

  + .cabinet-sidebar-block
    margin-top: 30px
  &.banner-sidebar-block
    display: block
    margin-top: 30px
    img
      max-width: 100%

.cabinet-sidebar-title
  font-size: $mediumFontSize
  font-family: $avenirNextMedium
  line-height: 1
  color: $mainBlack
  padding: 0 21px
  height: 60px
  border-bottom: 1px solid #D2E1F5
  display: flex
  align-items: center

  i
    color: #ACB6DB
    margin-right: 20px

  .context-menu
    margin-left: auto

    i
      margin-right: 0

.cabinet-sidebar-nav
  padding: 10px 0

  &__item
    display: flex
    align-items: center
    height: 50px
    padding: 0 20px 0 0
    font-family: $avenirNextLight
    font-size: $regularFontSize
    transition: all .3s ease-in-out
    color: $darkBlue
    position: relative
    &.on-mobile
      display: none
      +max($laptop)
        display: flex
    &.no-mobile
      display: flex
      +max($laptop)
        display: none
    &:hover,
    &.active
      text-decoration: none
      color: $hoveredMainGreen
      background-color: rgba(31, 197, 208, 0.1)

      &:after
        opacity: 1

      i
        color: $hoveredMainGreen

      .help-tooltip
        i
          color: #ACB6DB
      .menu-screaning-status
        &.status-checked
          border: 2px solid $mainGreen
          &::after
            color: $mainGreen
        &.status-alert
          background: $mainYellow
          &::after
            color: $mainGreen
        &.status-danger
          background: $mainRed
          &::after
            color: $mainWhite
    .material-icons,
    .material-icons-outlined
      margin: 0 20px
      color: #ACB6DB
      transition: all .2s

    &:after
      content: ''
      position: absolute
      width: 4px
      top: 0
      bottom: 0
      background-color: $hoveredMainGreen
      opacity: 0
      transition: all .2s
      left: -1px

    &-help
      position: relative

      .help-tooltip
        position: absolute
        left: calc(100% + 3px)
        top: -1px

        i
          margin-right: 0
          font-size: $regularFontSize

        &:hover
          i
            color: $darkBlue
    .menu-screaning-status
      width: 23px
      height: 23px
      margin-left: auto
      &.status-checked
        border: 2px solid $mainGrey
        &::after
          content: 'check'
          font: $mediumFontSize 'Material Icons'
          font-weight: bold
          color: $mainGrey
      &.status-alert
        background: $mainGrey
        &::after
          content: 'priority_high'
          font: $mediumFontSize 'Material Icons'
          color: $mainWhite
      &.status-danger
        background: $mainGrey
        &::after
          content: 'priority_high'
          font: $mediumFontSize 'Material Icons'
          color: $mainWhite

  &__nums
    background-color: $mainGreen
    font-size: 12px
    border-radius: 20px
    color: $mainWhite
    padding: 2.5px
    display: flex
    justify-content: center
    align-items: center
    min-width: 23px
    min-height: 23px
    position: absolute
    top: 50%
    transform: translateY(-50%)
    right: 20px

    &:empty
      display: none

.not-available
  color: $mainRed
  & .material-icons, & .material-icons-outlined
    color: $mainRed
