.permissions__list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 30px
  grid-row-gap: 30px
  +max($laptopM)
    grid-template-columns: repeat(2, 1fr)
  +max($mobile)
    grid-template-columns: 1fr

  .permission-item
    background: $mainWhite
    border: 1px solid $borderGrey
    border-radius: 8px
    padding: 30px 0 30px 0
    display: flex
    flex-wrap: nowrap
    flex-direction: column
    position: relative
    transition: box-shadow .3s ease-in-out
    &:hover
      transition: box-shadow .3s ease-in-out
      box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25)
      border-color: $hoveredMainGreen 
    .context-menu
      position: absolute
      right: 30px
      top: 30px
      button
        background-color: white
        transform: translate(-5px)
    &__heading
      display: flex
      align-items: center
      width: 100%
      padding: 0 30px 30px 30px
      margin-bottom: 14px
      border-bottom: 1px solid $borderGrey
    &__wrapper
      width: 100%
      display: flex
      flex-wrap: wrap
      margin-top: 16px
      justify-content: space-between
      padding: 0 30px
    &__expert-pic
      +size(50px)
      min-width: 50px
      overflow: hidden
      border-radius: 50%
      margin-right: 8px
      border: 2px solid $mainBlue
      img
        +size(46px)
    &__expert-desc
      width: 100%
      padding-right: 40px
      display: flex
      flex-direction: column
    &__expert-name
      font: $extraSmallFontSize $avenirNextMedium
      line-height: 15px
      color: $darkBlue
      margin-bottom: 5px
    &__specialisation
      font: $extraSmallFontSize $avenirNextRegular
      color: $darkGrey
      line-height: 15px
    &__info-title
      font: $smallFontSize $avenirNextMedium
      color: $textGrey
      line-height: 17px
      width: 100%
    &__info-desc
      font: $smallFontSize $avenirNextMedium
      color: $mainBlack
      line-height: 17px
      width: 100%
    &__footer
      .button_regular
        width: 100%
        margin-right: 30px
      .btn-wrap.btn-wrap--mt-none
        max-width: 90px
