.local-archive
    padding: 40px 30px
    display: grid
    grid-template-columns: 290px 1fr
    grid-gap: 15px
    background-color: $mainWhite
    border: 1px solid $borderGrey
    border-radius: 8px
    font-size: $regularFontSize
    font-family: $avenirNextLight
    +max($laptopM)
        grid-template-columns: 1fr
    +max($laptop)
        grid-template-columns: 290px 1fr
    +max($tablet)
        grid-template-columns: 1fr
    +max($mobile)
        display: none
    &__mobile
        display: none
        +max($mobile)
            display: flex
            flex-direction: column
            align-items: center
            p
                text-align: center
                font-family: $avenirNextDemi
    &__about
        margin-bottom: 60px
    &__download
        margin-bottom: 40px
    &__copy
        &-btn.button_regular.button_regular_secondary
            margin-top: 20px
            i.material-icons-outlined
                color: $mainGreen
                font-size: 18px
                margin-right: 14px
    &__btn-wrap
        margin-top: 20px!important
    &__col
        justify-self: center
    &__toggle
        display: grid
        grid-template-columns: repeat(2, max-content)
        grid-gap: 15px
        label
            transform: translateY(-3px)
            font-family: $avenirNextRegular
        +max($mobileM)
            grid-template-columns: 1fr
    &__status
        display: flex
        justify-content: center
        +max($laptopM)
            justify-content: start
        +max($laptop)
            justify-content: center
        +max($tablet)
            justify-content: start
        &--online
            font-weight: 600
            color: $mainGreen
        &--offline
            font-weight: 600
            color: $borderGrey
    &__title
        margin-bottom: 30px
    &__text
        color: $darkBlue
        p
            margin-bottom: 30px
        strong
            font-weight: 600
    &__accordion
        &-content
            padding-top: 30px
        &-title
            display: flex
            align-items: center
            padding-top: 30px
            color: $mainBlue
            font-family: $avenirNextDemi
            font-size: $regularFontSize
    &__copy-document
        padding: 15px 0
        display: flex
        justify-content: space-between
        align-items: center
        border-top: 1px solid $halfGrey
        border-bottom: 1px solid $halfGrey
        &-text
            font-family: $avenirNextMedium
        &-btns
            transform: translateY(3px)
            transition: color .3s ease-out
            i
                font-size: 20px
                color: $textGreyLight
                margin-right: 15px
                &:hover
                    color: $mainGreen
    .button_medium img
        margin-right: 15px