.open-map
  color: $mainGreen
  display: flex
  align-items: center
  font-size: $smallFontSize
  font-family: $avenirNextMedium
  border: 0
  background-color: transparent
  padding: 0
  
  i
    margin-right: 6px
    font-size: 17px

  span
    border-bottom: 1px solid $mainGreen
    margin-bottom: 2px