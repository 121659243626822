.broadcast-wrap
  display: grid
  grid-template-columns: 1fr 450px
  grid-gap: 30px
.broadcast
  width: 100%
  .comments
    width: 100%
    margin-bottom: 20px
    .comment-add
      display: flex
      flex-direction: column
      .form-input
        display: flex
        width: 100%
        margin-bottom: 15px
    .comment-add__field
      border: 0
      border-bottom: none
      height: 90px
      border-radius: 10px
      font-size: $smallFontSize
      color: $mainBlack
      width: 100%
      background-color: $mainWhite
      padding: 8px
      border: 1px solid #E0E7FF
    .btn-wrapper
      width: 100%
      display: flex
      justify-content: flex-end
    .btn--cancel
      width: 120px
      height: 40px
      border-radius: 20px
      background: #E8E8E8
      font-size: $smallFontSize
      margin-right: 20px
      font-family: $avenirNextDemi
    .comment__name
      color: $mainBlack
    .comment__pic
      margin-right: 14px


.broadcast
  &__info
    flex-direction: row
    &-date
      display: flex
      align-items: center
      padding-right: 12px
      margin-right: 8px
      position: relative
      &::after
        content: ''
        +size(4px)
        border-radius: 50%
        position: absolute
        right: 0
        background-color: $halfGrey
    &-category
      word-break: break-word    
  &__video
    &-wrap
      height: 507px
      border-radius: 4px 
      overflow: hidden
      margin-bottom: 30px
      iframe
        +size(100%)
    &-info
      display: flex
      align-items: center    
  &__name
    color: $mainBlack
    font-size: $largerFontSize
    font-family: $avenirNextDemi
    margin: 20px 0
    line-height: 39px
  &__description
    padding: 20px 0
    border-bottom: 1px solid $borderGrey
    margin-bottom: 20px
  &__link
    margin-top: 20px
    .links-effect
      color: $mainBlue
      word-break: break-all
      &:hover,
      &:focus
        color: $mainBlue
  &__author
    display: flex
    justify-content: space-between
    align-items: center
    padding: 17px 0
    border-top: 1px solid $borderGrey
    border-bottom: 1px solid $borderGrey
    &-holder,
    &-share
      display: flex
      align-items: center  
    &-pic
      +size(40px)
      min-width: 40px
      margin-right: 10px
      border: 2px solid $mainBlue
      border-radius: 50%
      overflow: hidden
    &-desc
      display: flex
      flex-direction: column
    &-name
      color: $mainBlack
      font-family: $avenirNextMedium
      font-size: $extraSmallFontSize
    &-spec
      color: $textGrey
      font-family: $avenirNextLight
      font-size: $extraSmallFontSize
