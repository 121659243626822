.create-broadcast
    &__form
        padding: 20px 30px
        border-radius: 8px
        border: 1px solid $borderGrey
        background-color: $mainWhite
        display: flex
        flex-direction: column
    &__wrap
        max-width: 480px  
    &__hours
        &.form-wrap .select-custom
            width: calc(100% / 2 - 5px)