.nav-tabs
    border: 1px solid $borderGrey
    background-color: $mainWhite
    padding: 15px 8px 0 8px
    margin-bottom: 20px
    border-radius: 8px
    flex-wrap: nowrap
    +max($mobileL)
      transform: translate(-20px)
      border-radius: unset
      width: calc(100% + 40px)
      max-width: calc(100% + 40px)
      overflow: auto hidden
    .nav-link
      cursor: pointer
      border: 0
      margin-bottom: 0
      display: flex
      align-items: center
      background-color: transparent
      font-size: $regularFontSize
      font-family: $avenirNextMedium
      line-height: 1
      padding: 0 28px 17px
      position: relative
      color: $mainBlack
      transition: color .3s ease-in-out
      +max($mobileL)
        white-space: nowrap
      &.active
        background-color: transparent
        color: $mainGreen

        &:after
          opacity: 1

      &:after
        content: ''
        opacity: 0
        height: 4px
        background-color: $mainGreen
        border-radius: 5px 5px 0 0
        position: absolute
        bottom: 0
        left: 0
        right: 0
        transition: all .3s
      &:hover
        transition: color .3s ease-in-out
        color: $mainGreen
      .material-icons,
      .material-icons-outlined
        margin-left: 10px
        font-size: 18px
        &.warning
          color: $mainYellow
        &.danger
          color: $mainRed
        &.success
          color: $mainGreen
      &__tip
        min-width: 20px
        height: 20px
        border-radius: 20px
        background-color: $hoveredMainGreen
        color: $mainWhite
        display: flex
        align-items: center
        justify-content: center
        font-size: $smallFontSize
        font-family: $avenirNextMedium
        margin-left: 10px
    &.lang-tabs
      border: none
      background-color: transparent
      padding: 0
      margin-bottom: 0
      border-radius: 0
      +max($mobileL)
        transform: translateX(0)
      .nav-link
        opacity: .4
        padding: 0
        margin-left: 10px
        &:after
          display: none
        &.active
          opacity: 1
          &:after
            display: none
        + .nav-link
          margin-left: 6px

