.one-document
  $t: &

  position: relative
  border: 1px solid #D2E1F5
  background-color: $mainWhite
  display: flex
  padding: 20px
  width: 100%
  border-radius: 6px
  transition: all .3s
  justify-content: space-between
  align-items: center
  +max($mobile)
    padding: 10px  
  &:hover
    position: relative
    border-color: #00FFC8
    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.1)
    
    #{$t}__desc
      &::before
        opacity: 1
        transform: translateY(0)
        transition: all .3s ease-in-out
    #{$t}__status
      border-color: #00FFC8
    #{$t}__delete
      transition: .3s ease-in-out
      i
        color: $mainBlue
    #{$t}__download
      transition: .3s ease-in-out
      i
        color: $mainRed

  &__status
    display: none
  //   border: 2px solid #D2E1F5
  //   +size(14px)
  //   border-radius: 50%
  //   position: absolute
  //   top: -5px
  //   right: -5px
  //   transition: all .3s
    
  //   &.blue
  //     background-color: #3EB5F1

  //   &.grey
  //     background-color: #BEC7D0

  //   &.yellow
  //     background-color: $mainYellow

  //   &.orange
  //     background-color: #E7A580

  //   &.red
  //     background-color: $mainRed

  &__pic
    +size(40px, 40px)
    min-width: 40px
    +max($mobile)
      margin-right: 10px

  &__body
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    height: 100%
    +max($mobile)
      width: 100%
      height: 100%

  &__desc
    +elipsis
    display: flex
    align-items: flex-start
    flex-direction: column
    justify-content: center
    flex-wrap: wrap
    margin: 0 20px
    position: relative
    transform: translateY(0)
    transition: all .4s ease-in-out
    width: 100%
    +max($mobile)
      margin: 0 10px

  &__name
    +elipsis
    width: 100%
    font: $regularFontSize $avenirNextDemi
    color: $mainBlack
    margin-bottom: 2px
    +max($mobile)
      font-size: $smallFontSize
  &__name-info
    display: flex
    flex-wrap: wrap
  &__place
    font-size: $smallFontSize
    color: $mainBlack
    opacity: .6
    margin-right: 10px
  &__info
    display: flex
    align-items: center  

  &__date
    font-size: $smallFontSize
    color: $mainBlack

  &__place,
  &__date
    +max($mobile)
      font-size: 12px  
  
  &__nav
    display: flex
    height: 100%
    align-items: center
    width: auto
    +max($mobile)
      margin-top: 5px
      grid-column: 1/-1
      justify-self: center

  &__delete,
  &__download
    +size(30px)
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    padding-left: 1px
    i
      color: $mainBlack
    
    &:hover
      text-decoration: none
  &__download
    margin-right: 10px