.common_list
  display: inline-flex
  flex-direction: column
  $t: &
  &-item
    display: flex
    align-items: center
    + .common_list-item
      margin-top: 10px
  &-link
    border: none
    outline: none
    display: flex
    align-items: center
    padding: 0 0 5px 16px
    text-decoration: none
    color: $darkBlue
    font-size: $regularFontSize
    font-family: $avenirNextRegular
    background-color: transparent
    position: relative
    transition: .3s ease-in-out
    + .common_list-link
      padding: 5px 0 5px 16px
    &:before
      content: ''
      +flex-center()
      +size(6px)
      min-width: 6px
      border-radius: 50%
      left: 0
      position: absolute
      background-color: $textGreyLight
    &.active,
    &:hover
      text-decoration: underline
      color: $mainGreen
      transition: .3s ease-in-out
      &::before
        background-color: $mainGreen    
    &.active
      text-decoration: none
      &:hover
        color: $mainGreen
        text-decoration: $mainGreen
  &.static_list
    #{$t}-link
      color: $darkGrey
      font-size: $regularFontSize
      font-family: $avenirNextMedium
      padding: 12px 0 12px 16px
      &.active,
      &:hover
        color: $mainBlack
        transition: .3s ease-in-out
        &:before
          transform: scale(1)