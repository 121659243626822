.service-dropdown
    &__item
        display: flex
        align-items: center
        &-title
            font-size: $demiFontSize
            font-family: $avenirNextLight
            color: $lightBlue
            min-width: 120px
            margin-right: 30px
        &-value
            font-size: $demiFontSize
            font-family: $avenirNextMedium
            color: $mainBlack
        + .service-dropdown__item
            margin-top: 20px