.main-news
    &__item
        height: 148px
        max-height: 100%
        overflow: hidden
        display: flex
        align-items: flex-start
        justify-content: space-between
        margin-bottom: 30px
        +max($desktop)
            height: auto
            justify-content: start
            margin-bottom: 20px
        &-pic
            min-width: 209px
            height: 148px
            background-size: cover
            background-position: center
            backround-repeat: no-repeat
            border-radius: 4px
            margin-right: 30px
        &-title
            color: $darkBlue
            line-height: 29px
            height: 100%
            max-height: 116px
            overflow: hidden
            text-decoration: none
            +max($laptopM)
                font: $demiFontSize $avenirNextDemi
            +max($mobile)
                font: $regularFontSize $avenirNextDemi
            &.links-effect
                &:hover,
                &:focus
                    color: $darkBlue
        &-content
            display: flex
            flex-direction: column
            height: 100%
            +max($desktop)
                width: 100%
        &-heading
            height: 100%
            margin-bottom: 6px    
        &-footer
            display: flex
            justify-content: space-between
            align-items: flex-end
            width: 100%
            margin-top: auto
            &--left,
            &--right
                +flex-center()
                align-items: flex-end
        &-category,
        &-date
            font-size: $extraSmallFontSize
            font-family: $avenirNextMedium
            color: $mainGreen
            margin-right: 12px
            &.links-effect
                &:hover,
                &:focus
                    color: $mainGreen
        &-date
            color: $mainGrey 
        &-data
            display: flex
            align-items: center
            color: $mainGrey
            font-size: $extraSmallFontSize
            font-family: $avenirNextDemi
            .material-icons,
            .material-icons-outlined
                font-size: 18px
                margin-right: 6px
            + .main-news__item-data
                margin-left: 13px   