.add-review-dropdown
  .btn-wrap.btn-wrap--jcsb
    label
      width: calc(50% - 15px)
      input
        display: none
.btn-review
  background-color: rgba(#00D7A9, 0.15)
  transition: all .2s
  height: 50px
  padding: 0
  border-radius: 3px
  display: flex
  align-items: center
  justify-content: center
  border: 0
  opacity: .5
  cursor: pointer
  &:hover
    opacity: 1
  +max($mobile)
    margin-right: 10px
    border-radius: 7px
    height: unset
    padding: 8.5px
    font-size: 13px
  
  
  &--dislike
    background-color: rgba(#FF0000, 0.15)
    +max($mobile)
      margin-right: 0
      margin-left: 10px
    
  i
    margin-right: 20px
    +max($mobile)
      margin-right: 15px
      font-size: $mediumFontSize

  &.active
    background-color: $mainGreen