.cabinet-broadcasts-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 30px

.cabinet-broadcast
  box-shadow: 0 10px 20px 0px rgba(0,0,0,0.05)
  background-color: $mainWhite
  border: 1px solid $borderGrey
  border-radius: 8px
  padding: 30px
  display: flex
  flex-direction: column
  min-width: 330px
  &__pic
    +size(100%, 147px)
    min-height: 147px
    overflow: hidden
    display: flex
    margin-bottom: 20px
    text-decoration: none
    &:hover
      text-decoration: none
    img
      max-width: 100%
      overflow: hidden
      object-fit: cover
      border-radius: 4px
  &__body
    display: flex
    flex-direction: column
    height: 100%
    margin-bottom: 20px
    margin-top: auto
    position: relative
    .context-menu
      position: absolute
      right: 0
      top: 0
  &__category
    width: 100%
    margin-bottom: 15px
    font-size: $extraSmallFontSize
    color: $mainGreen
    padding-right: 42px
  &__title
    color: $mainBlack
    font-family: $avenirNextMedium
    font-size: $regularFontSize
    line-height: 20px
    &:hover
      color: $mainBlack
    &-wrapper
      height: 100%
      max-height: 95px
      overflow: hidden
      margin-bottom: 10px 
  &__time
    &-field
      color: $mainGrey
      font-size: $extraSmallFontSize
      font-family: $avenirNextMedium     
