.dashboard-reviews
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  padding-bottom: 0
  &__rate
    text-align: center
    font: 13px $avenirNextMedium
    color: #000

  &__recommended-quantity
    margin-left: 10px
  &__recommended-quantity:before,
  &__not-recommended-quantity:before
    content: ""
    width: 10px
    height: 10px
    border-radius: 5px
    display: inline-block
    margin-right: 5px
  &__recommended-quantity:before
    background: #00D7A9
  &__not-recommended-quantity:before
    background: #FF4E4E
