.dialogs-preview,
.notification-preview
    .none-notification
        padding: 15px 20px
        color: $mainBlack
        font-size: $smallFontSize
    .dropdown-body
        width: 360px
        right: -34px
        top: calc(100% + 20px)
        +max($mobile)
            width: 320px
            right: -95px
        &:after
            right: 47px
        &:before
            content: ''
            height: 25px
            left: 0
            right: 0
            bottom: calc(100% - 2px)
            display: block
            position: absolute
    a
        display: flex
        align-items: center
        font-family: $avenirNextMedium
        font-size: $regularFontSize
        color: $mainBlue
        text-decoration: none
        &:hover
            text-decoration: none
            color: $mainBlue
        i
          color: $darkBlue
          font-size: 24px
          +max($mobile)
            font-size: 20px
    &__list
        padding: 5px 0
    &__title
        display: flex
        justify-content: space-between
        align-items: center
        border-bottom: 1px solid $halfGrey
        padding: 16px 20px
        width: 100%
        span
            font: $regularFontSize $avenirNextDemi
            color: $mainBlack
        .material-icons,
        .material-icons-outlined
            color: $mainBlue
            font-size: 20px
    &__item
        display: flex
        transition: .3s ease-int-out
        padding: 15px 20px
        &:hover
            background-color: $helperGrey
            .notification-preview__ico
                background-color: $mainWhite
        &:last-child
            border-bottom: 0
    &__ico
        background-color: $helperGrey
        +size(34px)
        min-width: 34px
        border-radius: 50%
        margin-right: 10px
        display: flex
        justify-content: center
        align-items: center
        i
            color: $mainBlue
            font-size: 20px
    &__body
        display: flex
        justify-content: space-between
        align-items: center
        flex-wrap: wrap
        width: calc(100% - 10px - 36px)
    &__name
        color: $mainBlack
        font-size: $regularFontSize
        font-family: $avenirNextMedium
        display: block
    &__date
        color: $mainBlack
        opacity: .7
        font-size: 11px
    &__message
        margin-top: 7px
        overflow-wrap: anywhere
        color: $mainBlack
        font-size: $smallFontSize
        a
            font-size: $smallFontSize
        .notification-preview__item.red &
            span
                color: $mainRed
