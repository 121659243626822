.dropdown-form
  $t: &
  +max($laptop)
    width: 100%
  &__title
    font: $largerFontSize $avenirNextDemi
    color: $mainBlack
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 10px
    &--mb-none
      margin-bottom: 0
    +max($mobile)
      font-size: $bigFontSize

    span
      font-family: $avenirNextMedium
      font-size: $bigFontSize
      +max($mobile)
        font-size: $regularFontSize

    + #{$t}__subtitle
      +max($mobile)
        margin-top: 0
        span
          color: $mainRed

  &__subtitle
    font-size: $regularFontSize
    font-family: $avenirNextMedium
    color: $mainBlack
    margin: 20px 0px
    background-color: rgba(50, 210, 159, 0.1)
    padding: 20px
    border: 1px solid #32D29F
    box-sizing: border-box
    border-radius: 10px
    &.simple-subtitle
      border: none
      background-color: unset
      margin: 0px 0px 30px
      padding: 0
    a
      color: $mainBlack
      text-decoration: underline

      &:hover
        color: $mainBlack
        text-decoration: none
    .red
      color: $mainRed
  &__small-text
    font: 12px $avenirNextMedium
    margin-top: 15px
    margin-bottom: 25px
.register-wards-hint
  font: $extraSmallFontSize $avenirNextLight
  margin-top: 5px
  &--bold
    font: $extraSmallFontSize $avenirNextMedium
  &--green
    font: $extraSmallFontSize $avenirNextMedium
    color: $mainGreen
    text-decoration: underline
.register-wards-btn
  margin: 22px 0
