/* MAIN AND SECONDARY BIG BUTTONS(HEIGHT: 50px) */
.button
  min-height: 50px
  background-color: $mainGreen
  padding: 0 32px
  color: $mainWhite
  font: $regularFontSize $avenirNextMedium
  +flex-center()
  text-align: center
  border-radius: 2px
  transition: .3s ease-in-out
  cursor: pointer
  position: relative
  overflow: hidden
  &:hover
    color: $mainWhite
    text-decoration: none
    background-color: $hoveredMainGreen
  &.button_icon
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      margin-right: 12px
  &.button_icon-right
    justify-content: space-between
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      margin-left: 24px
      margin-right: 0
  &.button_icon_center
    justify-content: center
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      margin-right: 15px
      margin-left: 0
  &.button_secondary
    padding: 0 24px
    background-color: $mainWhite
    color: $mainGreen
    border: 1px solid $mainGreen
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $mainGreen
    &:hover
      color: $mainWhite
      text-decoration: none
      background-color: $hoveredMainGreen
      border: 1px solid $hoveredMainGreen
      .material-icons,
      .material-icons-outlined
        color: $mainWhite
  &:disabled,
  &.button_disabled
    border: 1px solid $mainGrey
    background-color: $disabledGrey
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    &:after
      color: rgba($mainBlack, .4)
/* END */

/* MAIN AND SECONDARY REGULAR BUTTONS(HEIGHT: 40px) */
.button_regular
  min-height: 40px
  background-color: $mainGreen
  padding: 0 20px
  color: $mainWhite
  font: $smallFontSize $avenirNextMedium
  +flex-center()
  text-align: center
  border-radius: 2px
  transition: .3s ease-in-out
  cursor: pointer
  position: relative
  overflow: hidden
  &:hover
    color: $mainWhite
    text-decoration: none
    background-color: $hoveredMainGreen
  &.button_icon
    justify-content: space-between
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      margin-right: 24px
  &.button_icon-right
    justify-content: space-between
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      margin-left: 24px
      margin-right: 0
  &.button_regular_secondary
    background-color: $mainWhite
    color: $mainGreen
    border: 1px solid $mainGreen
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $secondaryGrey
      i.material-icons,
      i.material-icons-outlined,
      span.material-icons,
      span.material-icons-outlined
        color: $mainGreen
    &:hover
      color: $mainWhite
      text-decoration: none
      background-color: $hoveredMainGreen
      border: 1px solid $hoveredMainGreen
  &.button_regular_tertiary
    border: 1px solid $mainGreen
    color: $mainGreen
    background-color: $mainWhite
  &:disabled,
  &.button_disabled
    border: 1px solid $mainGrey
    background-color: $disabledGrey
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    &:after
      color: rgba($mainBlack, .4)
/* END */  

/* MAIN AND SECONDARY DEMI BUTTONS(40px x 40px without text buttons) */
.button_demi
  +size(40px)
  min-width: 40px
  +flex-center()
  text-align: center
  color: $mainWhite
  background-color: $mainGreen
  border-radius: 2px
  transition: .3s ease-in-out
  cursor: pointer
  position: relative
  overflow: hidden
  &:hover
    text-decoration: none
    background-color: $hoveredMainGreen
  &.button_demi_secondary
    background-color: $mainWhite
    color: $mainGreen
    border: 1px solid $mainGreen
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $mainBlack
    &:hover
      color: $mainWhite
      text-decoration: none
      background-color: $hoveredMainGreen
      border: 1px solid $hoveredMainGreen
  &:disabled,
  &.button_disabled
    border: 1px solid $mainGrey
    background-color: $disabledGrey
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    &:after
      color: rgba($mainBlack, .4)
/* END */        

/* MAIN AND SECONDARY MEDIUM BUTTONS(HEIGHT: 36px) */
.button_medium
  min-height: 36px
  background-color: $mainGreen
  padding: 0 24px
  color: $mainWhite
  font: $smallFontSize $avenirNextMedium
  +flex-center()
  text-align: center
  min-width: 120px
  border-radius: 2px
  transition: .3s ease-in-out
  cursor: pointer
  position: relative
  overflow: hidden
  &:hover
    color: $mainWhite
    text-decoration: none
    background-color: $hoveredMainGreen
  &.button_icon
    justify-content: space-between
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      margin-right: 24px
  &.button_icon-right
    justify-content: space-between
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      margin-left: 24px
      margin-right: 0
  &.button_medium_secondary
    background-color: $mainWhite
    color: $mainGreen
    border: 1px solid $mainGreen
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $hoveredMainGreen
      transition: .3s ease-in-out
    &:hover
      color: $mainWhite
      text-decoration: none
      background-color: $hoveredMainGreen
      border: 1px solid $hoveredMainGreen
      i.material-icons,
      i.material-icons-outlined,
      span.material-icons,
      span.material-icons-outlined
        color: $mainWhite
  &:disabled,
  &.button_disabled
    border: 1px solid $mainGrey
    background-color: $disabledGrey
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    &:after
      color: rgba($mainBlack, .4)
/* END */ 

/* MAIN AND SECONDARY SMALL BUTTONS(50x50 without text buttons) */
.button_small
  +size(50px)
  min-width: 50px
  +flex-center()
  text-align: center
  color: $mainWhite
  background-color: $mainGreen
  border-radius: 2px
  transition: .3s ease-in-out
  cursor: pointer
  position: relative
  overflow: hidden
  @media not all and (pointer: coarse)
    &:hover
      text-decoration: none
      background-color: $hoveredMainGreen
  &.button_small_secondary
    background-color: $mainWhite
    color: $mainGreen
    border: 1px solid $mainGreen
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $mainBlack
    &:hover
      background-color: $hoveredGrey
  &:disabled,
  &.button_disabled
    border: 1px solid $mainGrey
    background-color: $disabledGrey
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    &:after
      color: rgba($mainBlack, .4)
/* END */

/* BORDERLESS BUTTONS(Without borders buttons) */
.button_borderless
  +flex-center()
  color: $mainBlue
  font: $regularFontSize $avenirNextMedium
  background-color: transparent
  position: relative
  padding: 0
  transition: .3s ease-in-out
  cursor: pointer
  i.material-icons,
  i.material-icons-outlined,
  span.material-icons,
  span.material-icons-outlined
    +flex-center()
    font-size: $bigFontSize
    +size(24px)
    min-width: 24px
    margin-right: 12px
  &:hover
    text-decoration: none
    color: $hoveredMainBlue
  &.button_borderless_secondary
    color: $darkGrey
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $secondaryGrey
    &:hover
      color: $mainBlack
      i.material-icons,
      i.material-icons-outlined,
      span.material-icons,
      span.material-icons-outlined
        color: $mainBlack
  &:disabled,
  &.button_disabled
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: rgba($mainBlack, .4)
    &:after
      color: rgba($mainBlack, .4)
  &[aria-expanded=true]
    .material-icons,
    .material-icons-outlined
      display: none
      &.active
        display: flex
  &[aria-expanded=false]
    .material-icons,
    .material-icons-outlined
      display: flex
      &.active
        display: none
/* END */

/* SMALLER BUTTONS(36x36 without text buttons) */

.button_smaller
  +size(36px)
  min-width: 36px
  +flex-center()
  text-align: center
  color: $mainWhite
  background-color: $mainGreen
  border-radius: 2px
  transition: .3s ease-in-out
  position: relative
  cursor: pointer
  i.material-icons,
  i.material-icons-outlined,
  span.material-icons,
  span.material-icons-outlined
    color: $mainWhite
  &:hover
    color: $mainWhite
    text-decoration: none
    background-color: $hoveredMainGreen
  &.button_smaller_secondary
    background-color: $mainWhite
    border: 1px solid $mainGrey
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $mainBlack
    &:hover
      color: $mainWhite
      text-decoration: none
      background-color: $hoveredMainGreen
  &:disabled,
  &.button_disabled
    border: 1px solid $mainGrey
    background-color: $disabledGrey
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: rgba($mainBlack, .4)
    &:after
      color: rgba($mainBlack, .4)
/* END */

/* OTHERS LAYOUT BUTTONS */
.button_action
  padding: 0
  +flex-center()
  background-color: transparent
  border: none
  outline: none
  transition: .3s ease-in-out
  cursor: pointer
  $t: &
  &-title
    font-size: $regularFontSize
    font-family: $avenirNextMedium
    color: $mainBlack
    margin-right: 10px
  &.button_action_left
    #{$t}-title
      margin-left: 10px
      margin-right: 0
  i.material-icons,
  i.material-icons-outlined,
  span.material-icons,
  span.material-icons-outlined
    color: $mainGrey
  &.active
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $mainBlack

/* SMALLER BUTTONS(36x36 with only icons) */

.button_only_icon
  +size(36px)
  min-width: 36px
  background: none
  +flex-center()
  text-align: center
  border-radius: 2px
  transition: .3s ease-in-out
  position: relative
  cursor: pointer
  i.material-icons,
  i.material-icons-outlined,
  span.material-icons,
  span.material-icons-outlined
    color: $mainGrey
  &:hover
    text-decoration: none
    box-shadow: none
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: $mainGreen
  &:disabled,
  &.button_disabled
    color: rgba($mainBlack, .4) !important
    pointer-events: none
    i.material-icons,
    i.material-icons-outlined,
    span.material-icons,
    span.material-icons-outlined
      color: rgba($mainBlack, .4)

/* END */
