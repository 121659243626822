.ui-widget.ui-widget-content.ui-datepicker
  background-color: $mainWhite!important
  width: 330px!important
  border-radius: 5px!important
  padding: 20px!important
  box-shadow: 0 5px 10px 0px rgba(0,0,0,0.1)
  border: 1px solid $halfGrey!important
  transform: translateY(16px)!important
  @media(max-width: 400px)
    width: 290px !important
    padding: 10px !important

.ui-datepicker
  .ui-datepicker-header
    background-color: transparent
    border: 0
    padding: 0
    margin-bottom: 10px
    +flex-center()

  .ui-datepicker-title
    min-height: 36px
    margin: 0
    width: calc(100% - 72px)
    padding: 0 10px
    display: flex
    align-items: center
    justify-content: space-between


    .ui-datepicker-month
      position: relative
      font-family: $avenirNextMedium
      color: $mainBlack
      font-size: $regularFontSize

      &:after
        content: ''
        +top()
        left: 0
        color: $mainGreen
        +size(24px)

    .ui-datepicker-year
      display: flex
      align-items: center
      width: unset
      background-color: transparent
      font-family: $avenirNextMedium
      color: $mainBlack
      font-size: $regularFontSize
      min-height: 36px
      padding: 0 10px
      cursor: pointer
      border: 1px solid $mainGrey
      border-radius: 5px
      margin: 0

  .ui-datepicker-prev,
  .ui-datepicker-next
    +size(36px)
    min-width: 36px
    border: 1px solid $mainGrey
    background-color: transparent
    border-radius: 5px
    cursor: pointer
    top: 0

    &:after
      content: ''
      background-image: url('/build/images/icons/baseline-keyboard_arrow_left-24px.svg')
      background-repeat: no-repeat
      background-size: 100%
      +size(20px)
      display: block
      +center()

  .ui-datepicker-prev
    left: 0
    .ui-icon
      display: none !important

  .ui-datepicker-next
    right: 0
    &:after
      background-image: url('/build/images/icons/baseline-keyboard_arrow_right-24px.svg')

  .ui-icon, .ui-widget-content .ui-icon
    background-image: none
    position: relative

.ui-datepicker-calendar
  thead
    tr
      th
        font: $smallFontSize $avenirNextMedium
        color: $mainBlack
        &.ui-datepicker-week-end
          color: $mainRed


  tbody
    tr
      td

        &.ui-datepicker-week-end
          .ui-state-default
            color: $mainRed
        .ui-state-default
          min-width: 36px
          +size(36px)
          +flex-center()
          border-radius: 5px
          color: $darkBlue
          font-size: $regularFontSize
          font-family: $avenirNextMedium
          background-color: transparent
          border: 1px solid transparent
          margin: 0 auto

          &.ui-state-active
            border: 1px solid $mainGreen
            background-color: $mainGreen
            color: $mainWhite
        &.ui-datepicker-today
          a
            background-color: transparent
            border: 1px solid $halfGrey





.ui-datepicker-inline.ui-widget.ui-widget-content
  box-shadow: none!important
  border: 0!important
  padding: 0!important
  width: 100%!important
