.add-file
  position: relative
  padding: 19px 20px
  display: flex
  align-items: center
  justify-content: space-between

  label
    margin-bottom: 0
  &__form
    position: relative   
  &__preloader
    min-height: 200px
    width: 100%
    +flex-center()
    position: absolute
  &__input
    position: absolute
    bottom: 27px
    left: 40px
    opacity: 0
    pointer-events: none

  &__drag
    +size(138px)
    border-radius: 50%
    border: 2px dashed #DCDCDC
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    color: $darkBlue
    font-size: $smallFontSize
    font-family: $avenirNextMedium

  &__left
    width: 100%

    label
      width: 100%
      display: flex
      margin-bottom: 10px
      color: $mainBlack
      font: $regularFontSize $avenirNextMedium

  &__name
    margin-bottom: 16px

  &__field
    background-color: #E8F2FF
    border-radius: 30px
    font: $smallFontSize / 1 $avenirNextMedium
    color: $darkBlue
    position: relative
    height: 50px
    display: flex
    padding: 0 20px
    align-items: center

    i
      color: $mainGreen
      margin-right: 5px
      font-size: $mediumFontSize

  &__field-wrap
    display: flex
    justify-content: space-between
    width: 100%
    input
      position: absolute
      width: 100%
      height: 100%
      opacity: 0

  &__field-border
    border: 1px solid #D2E1F5
    padding: 5px
    border-radius: 5px

  &__btn
    position: absolute
    right: 5px
    top: auto
    padding: 0 30px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    background-color: $mainGreen
    border-radius: 40px
    color: $darkBlue
    font: $smallFontSize $avenirNextMedium

    &:hover
      color: $mainWhite

