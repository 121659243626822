.shares-wrap
  padding-top: 140px
  +max($desktop)
    padding-top: 90px
  +max($laptop)
    padding-top: 70px
  .swiper-pagination
    width: 100%
    position: static
    margin-top: 30px
    +flex-center()
    .swiper-pagination-bullet
      background-color: $mainGrey
      opacity: 1
      width: 8px
      height: 8px
      border-radius: 50%
      margin: 0 5px
      &-active
        width: 20px
        border-radius: 150px
        background-color: $hoveredMainGreen

.main-shares
  $t: & 
  &__item
    +flex-center
    min-height: 200px
    position: relative
    max-height: 320px
    img
      width: 100%
      border-radius: 5px
      overflow: hidden
      height: 100%
    .swiper-lazy-preloader
      border: 4px solid $hoveredMainGreen
      border-top-color: transparent
  
.swiper-news
  overflow: hidden