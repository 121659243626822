.insurance-case-card
    border-radius: 5px
    background-color: $mainWhite
    padding: 20px
    border: 1px solid $halfGrey
    position: relative
    display: flex
    flex-direction: column
    height: 100%
    .context-menu
        position: absolute
        right: 10px
        top: 15px
        .material-icons
            color: $mainBlack
    &__tag
        display: flex
        margin-bottom: 20px
        text-decoration: none
        &:hover
            text-decoration: none        
    &__heading
        display: flex
        align-items: center
        justify-content: flex-start
        margin-bottom: 30px
        &-pic
            +size(50px)
            min-width: 50px
            margin-right: 10px
        &-name
            text-decoration: none
            line-height: 20px
            color: $mainBlack
            padding-right: 60px
            &:hover
                color: $mainBlack
                text-decoration: none
    &__body
        height: 100%
        &-service        
            &--title
                font-size: $regularFontSize
                font-family: $avenirNextDemi
                color: $mainBlack
                line-height: 22px
                margin-bottom: 10px
            &--desc
                line-height: 18px
                color: $mainBlack
        &-top
            margin-bottom: 20px
        &-info
            display: flex
            align-items: flex-start
            width: 100%
            flex-direction: column
            margin-bottom: 20px
            &--row
                justify-content: space-between
                flex-direction: row
            &--date-time
                margin-bottom: 30px
                max-width: 80% 
            &--title
                font-size: $smallFontSize
                color: $mainBlack
                font-family: $avenirNextMedium
            &--description
                font-size: $smallFontSize
                color: $mainBlack
                font-family: $avenirNextDemi             