=size($a, $b: $a)
  width: $a
  height: $b

=elipsis
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  padding-right: 5px

=placeholder
  &::placeholder


=pseudo($content: '', $display: block, $position: absolute)
  content: $content
  display: $display

  @if $position == absolute
    position: absolute
  @else if $position == fixed
    position: fixed

=arrow($direction, $color, $sizebt, $size-lb: $sizebt)
  @if $direction == up
    border-left: $size-lb solid transparent
    border-right: $size-lb solid transparent
    border-bottom: $sizebt solid $color
  @else if $direction == down
    border-left: $size-lb solid transparent
    border-right: $size-lb solid transparent
    border-top: $sizebt solid $color
  @else if $direction == right
    border-top: $size solid transparent
    border-bottom: $size solid transparent
    border-left: $size solid $color
  @else if $direction == left
    border-top: $size solid transparent
    border-bottom: $size solid transparent
    border-right: $size solid $color


=pseudoMaterial($el-width: 24px, $el-height: 24px, $content: '', $font-family: 'Material Icons', $font-size: 24px, $color: unset)
  font-family: $font-family
  content: $content
  font-size: $font-size
  color: $color
  +size($el-width, $el-height)
  min-width: $el-width
  +flex-center()
  -webkit-font-feature-settings: 'liga' 1
  -moz-font-feature-settings: 'liga' 1
  font-feature-settings: 'liga' 1

@mixin top
  position: absolute
  top: 50%
  transform: translateY(-50%)

@mixin left
  position: absolute
  left: 50%
  transform: translateX(-50%)

@mixin center
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

@mixin flex-center
  display: flex
  justify-content: center
  align-items: center

@mixin flex-content
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%

=transition($time: .2s, $func: ease-in-out)
  transition: $time all $func

$desktop: 1440px
$laptopM: 1200px
$laptop: 1024px
$tablet: 992px
$mobile: 767px
$mobileL: 575px
$mobileM: 425px

\:root
  --desktop: #{$desktop}
  --laptopM: #{$laptopM}
  --laptop: #{$laptop}
  --tablet: #{$tablet}
  --mobile: #{$mobile}
  --mobileL: #{$mobileL}
  --mobileM: #{$mobileM}

=max($width)
  @if $width == $desktop
    @media only screen and (max-width: $desktop)
      @content
  @else if $width == $laptopM
    @media only screen and (max-width: $laptopM)
      @content
  @else if $width == $laptop
    @media only screen and (max-width: $laptop)
      @content
  @else if $width == $tablet
    @media only screen and (max-width: $tablet)
      @content
  @else if $width == $mobile
    @media only screen and (max-width: $mobile)
      @content
  @else if $width == $mobileL
    @media only screen and (max-width: $mobileL)
      @content
  @else if $width == $mobileM
    @media only screen and (max-width: $mobileM)
      @content
  @else
    @media only screen and (max-width: $width + 'px')
      @content