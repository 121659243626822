form
   width: 100%

.ui-datepicker
   z-index: 1051 !important

.a2lix_translations

   input, textarea
      background-color: transparent
      width: 100%
      font-size: $regularFontSize
      font-family: $avenirNextMedium
      padding: 15px 21px
      height: auto
      border: 1px solid #D2E1F5
      color: #1D1E44
      border-radius: .25rem
   +max($mobile)
      font-size: 12px

   &:focus
      outline: none

   textarea
      height: 180px

   + .form-group
      margin-top: 15px

   .tab-pane
   > div
      > div
         + div
            margin-top: 15px
#formGiveAccess
   .form-wrap
   .periodError
      position: absolute
      bottom: -30px
      font-size: 13px
      color: red
      line-height: 1
   .form-group
   &--w100
      width: 100%
   .select2-selection
      &.select2-selection--multiple
         .select2-selection__rendered
         .select2-search__field
            width: 100% !important
   p
      +max($mobile)
         font-size: $smallFontSize
#brodacastForm
   .add-document
      width: 100%
      position: relative
      justify-content: space-between

.form-wrap
   &--rel
      position: relative

#imgDocument
   .modal-dialog
      width: 690px
      max-width: 90%
   +max($mobileL)
      width: auto
      max-width: 100%
   .modal-body
      padding: 20px 40px
   img
      width: 100%
      height: auto

.error-text
   color: #FF6767
   font-family: $avenirNextBold
.default-text
   line-height: 20px
.hide-empty:empty
   display: none
.js-wait
   cursor: wait

.MuiAccordion-root
   border: 1px solid $textGreyLight
   width: 100%
   margin-bottom: 10px
//.MuiPaper-elevation1
//   box-shadow: unset!important
// .MuiAccordion-rounded
//   &:first-child
//     borer-radius: 2px

.showOnMap
   .MuiAccordion-root
      margin: 0px!important
   .MuiAccordion-root
      position: unset
   .MuiAccordionSummary-root
      padding: 0px
   .MuiAccordion-root
      border: none
   .MuiAccordionDetails-root
      padding: 0px 0px 10px

.showOnModal
   margin-right: 15px
   .MuiAccordion-root
      border: none
      border-bottom: 1px solid $halfGrey
      position: unset
      &:last-child
         border: none
      &.Mui-expanded
         margin: 0px
   .MuiAccordionSummary-root
      padding: 0px
      .MuiAccordionSummary-content
         color: $mainBlue
         font-size: $bigFontSize
         line-height: 1
         margin: 30px 0px 30px
         &.Mui-expanded
            margin: 30px 0px 10px
      .MuiIconButton-label
         color: $mainBlue
   .MuiAccordionDetails-root
      display: block
      padding: 0px 0px 20px 0px
   .MuiIconButton-edgeEnd
      margin-right: 0px
   .MuiAccordionSummary-expandIcon
      &.Mui-expanded
         margin-bottom: -20px

.dataPickerWrapper
   width: 100%
   .MuiInputBase-input
      padding: 15.5px 14px
   .MuiOutlinedInput-notchedOutline
      border-color: $textGreyLight
   .MuiInputBase-formControl:hover
   .MuiOutlinedInput-notchedOutline
      border-color: $textGreyLight
      box-shadow: 1px 1px 5px $textGreyLight
   .MuiOutlinedInput-root
      border-radius: 2px
      margin-bottom: 0px
   [class*="PrivateNotchedOutline-root"].MuiOutlinedInput-notchedOutline
      top: -6px
   .MuiInputBase-input
      font-family: $avenirNextLight
      color: $darkBlue
      font-size: $regularFontSize
      position: relative
      top: 3px

.material-icons::before
   content: attr(data-icon)
   // width: 100%

.material-icons-outlined::before
   content: attr(data-icon)
   width: 100%
