.concilium-form
  background: $mainWhite
  padding: 30px
  border: 1px solid $halfGrey
  border-radius: 8px
  &__wrapper
    display: flex
    width: 100%
    justify-content: space-between
  &__column
    width: calc( 50% - 15px )
  &__measure-time
    width: calc( 50% - 5px )
  &__total-sum
    color: $mainBlack
    display: flex
    justify-content: space-between
    align-content: center
    padding: 15px
    font: $demiFontSize $avenirNextRegular
    line-height: 25px
  &__total-sum-value
    color: $darkBlue
    font: $demiFontSize $avenirNextDemi
    line-height: 25px
    margin-left: auto
  &__total-sum-currency
    color: $darkBlue
    font: $demiFontSize $avenirNextDemi
    line-height: 25px
    margin-left: 5px
  .form-group--row
    display: flex
    justify-content: space-between

  .input-calendar
    position: relative
    &__hidden-field
      position: absolute
      max-width: 0
      max-height: 0
      left: 50%
      border: none
      padding: 0
    i
      position: absolute
      right: 15px
      top: 15px
      color: $mainGrey
  .dropdown
    &__arrow
      position: absolute
      content: ""
      border-color: $halfGrey transparent transparent transparent
      border-width: 5px 5px 0 5px
      border-style: solid
      display: block
      top: 56px
      right: 20px
    &:hover
      .dropdown-body
        opacity: 1
        border: 1px solid $halfGrey
        &:empty
          border: none
      .dropdown__arrow
        border-color: $mainBlack transparent transparent transparent
    &-body
      margin-top: 10px
      top: 100%
      visibility: visible
      opacity: 0
      border: none
      // TODO: 3 items visible with scroll
      // height: 315px
      // max-height: 315px
      // overflow: hidden auto
    &__wrapper
      position: relative

  .one-document
    &__delete i,
    &__download i
      color: $mainGrey
    &:hover
      .one-document__delete i,
      .one-download i
        color: $mainGreen
