.broadcast-similar
  display: flex
  + .broadcast-similar
    margin-top: 18px
    padding-top: 18px
    border-top: 1px solid $borderGrey
  &__list
    display: flex
    flex-direction: column
  &__title
    text-transform: uppercase
    margin-bottom: 20px
    color: $mainBlack  
  &__pic
    min-width: 210px
    min-height: 124px
    +size(210px, 124px)
    border-radius: 4px
    overflow: hidden
    margin-right: 15px
    img
      max-width: 100%
      object-fit: cover
      +size(100%)
  &__body
    display: flex
    flex-direction: column
    width: 100%
  &__heading
    display: flex
    flex-direction: column
    margin-bottom: 10px  
  &__category
    color: $mainGreen
    font-family: $avenirNextMedium
    font-size: $extraSmallFontSize
  &__date
    display: flex
    align-items: center
    color: $mainGrey
    font-family: $avenirNextMedium
    padding-right: 12px
    margin-right: 8px
    font-size: $extraSmallFontSize
  &__name
    font-size: $smallFontSize
    font-family: $avenirNextDemi
    color: $darkBlue
    &.links-effect
      &:hover,
      &:focus
        color: $darkBlue
    &-wrapper
      line-height: 17px
      height: 100%
      max-height: 70px
      overflow: hidden   
  &__footer
    margin-top: auto
  &__author
    font-size: $extraSmallFontSize
    font-family: $avenirNextMedium
    color: $mainBlack
    &.links-effect
      &:hover,
      &:focus
        color: $mainBlack
    &-wrapper
      line-height: 1     
