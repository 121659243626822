.news
  padding-top: 50px
  +max($mobile)
    padding-top: 18px
  &-block
    margin-top: 60px
    +max($mobile)
      margin-top: 30px
    &__title
      font-family: $avenirNextLight
      color: $mainBlack
      font-size: $largerFontSize
      margin-bottom: 30px
      +max($mobile)
        font-size: $bigFontSize
        margin-bottom: 20px
  &-title
    margin-bottom: 30px
    &-count
      margin-bottom: 30px
      font-size: $bigFontSize
      font-family: $avenirNextLight
      color: $mainBlack
      margin-top: -20px
      +max($mobile)
        font-size: $regularFontSize
  &-preview
    &-list
      &.news-preview-autoheight
        .default-news__item
          height: 100%
          max-height: 400px  
