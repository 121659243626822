.archive-history
    width: 100%
    border-collapse: collapse
    
    .collapse
        width: 50%

    &-desk
        +max(1280)
            display: none
    &-mobile
        display: none
        +max(1280)
            display: block
    &__wrapper
        padding: 0 30px 32px 30px
        background-color: $mainWhite
        border: 1px solid $borderGrey
        border-radius: 8px
        overflow-x: auto
        +max($tablet)
            padding: 0 2px
    &__head
        td
            color: #999999
            font-size: $regularFontSize
            font-family: $avenirNextDemi
            border-bottom: 1px solid $borderGrey
            padding-top: 21px
            padding-bottom: 21px
            padding-right: 10px
            +max($mobile)
                padding-top: 15px
                padding-bottom: 15px
                padding-right: 8px
            &:first-child
                padding-left: 10px
                padding-right: 0

    &__body
        td
            color: $mainBlack
            font-size: $extraSmallFontSize
            font-family: $avenirNextLight
            border-bottom: 1px solid $borderGrey
            padding-top: 17px
            padding-bottom: 17px
            padding-right: 10px
            +max($mobile)
                padding-top: 15px
                padding-bottom: 15px
                padding-right: 8px
            &:first-child
                padding-left: 10px
                padding-right: 10px
        .archive-history__td
            &--regular
                font-family: $avenirNextMedium
            &--hiden
                border-bottom: 0
                padding-left: 10px
                &:first-child
                    padding-left: 10px
                    padding-right: 10px
        .archive-history__date-header
            font-size: $mediumFontSize
            font-family: $avenirNextLight
        tr .material-icons
            font-size: 16px
            color: #C0D3FE
            text-align: center
        tr .material-icons__wrapper
            text-align: center
    &__user
        display: flex
        align-items: center
        min-height: 30px
        img
            overflow: hidden
            border-radius: 50%
            border: 1px solid $mainBlue
            +size(30px, 30px)
            min-width: 30px
            margin-right: 5px
    &__user--disabled
        color: $textGreyLight
    &__details
        cursor: pointer
        user-select: none
        font-family: $avenirNextMedium
        font-size: $extraSmallFontSize
        color: $darkBlue
        &.active i
            transform: rotate(-180deg)
            transition: transform .2s ease-out
    &-edit
        padding-top: 17px
        display: flex
        &__title
            display: block
            padding-bottom: 17px
            border-bottom: 1px solid $borderGrey
        &__item
            flex-basis: 50%
            +max($mobile)
                &:first-child
                    padding-bottom: 20px
            &-title
                display: block
                font-family: $avenirNextDemi
                font-size: $extraSmallFontSize
                color: $textGrey
            &-text
                font-weight: $avenirNextLight
                font-size: $extraSmallFontSize
                color: $mainBlack
    &__btn
        border: 1px solid $mainGrey
        background-color: transparent
        box-shadow: none
    &-info
        &__title
        color: $mainBlack
        font: $demiFontSize $avenirNextLight
        cursor: pointer
        padding: 0 30px
        height: 65px
        border-bottom: $borderGrey 1px solid
        align-items: center
        +flex-content()
        +max($laptopM)
            font-size: $mediumFontSize
.archive-history-mobile
    background-color: $mainWhite
    &__item
        padding: 20px 15px 0 15px
        border-top: 1px solid $borderGrey

        .panel
    &__date
        padding: 15px
    &__info
        padding-bottom: 18px
        display: flex
        flex-direction: column
        font-family: $avenirNextLight
        font-size: $extraSmallFontSize
        color: $mainBlack
        .archive-history__user span
            color: $mainBlack
            font-family: $avenirNextMedium
        .archive-history__user--disabled span
            color: $textGreyLight
        span
            font-family: $avenirNextDemi
            font-size: $extraSmallFontSize
            color: $textGrey
        .archive-history-mobile__title
            color: $darkBlue
            font-family: $avenirNextMedium
    &__details
        padding-bottom: 16px
        display: flex
        align-items: center
        font-family: $avenirNextMedium
        font-size: $extraSmallFontSize
        color: $darkBlue
    &-edit
        padding-bottom: 17px
        +max($mobile)
            flex-direction: column
        &__title
            padding-top: 17px
            font-family: $avenirNextMedium
            font-size: $extraSmallFontSize
            border-top: 1px solid $borderGrey
.panel
    display: none
    max-height: 0
    overflow: hidden
    transition: max-height .3s ease-out