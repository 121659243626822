.faq
  padding-top: 50px
  +max($mobile)
    padding-top: 18px 
  &-list-wrap
    margin-top: 65px
    +max($mobile)
      margin-top: 20px
  &-list
    &:not(:first-child)
      margin-top: 60px
      +max($mobile)
        margin-top: 30px
    &__title
      margin-bottom: 15px
      font-size: $bigFontSize
      font-family: $avenirNextMedium
      +max($mobile)
        margin-bottom: 5px
        font-size: $regularFontSize
  &-list--one-category
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 20px 30px
    +max($mobile)
      grid-template-columns: 1fr
      grid-gap: 15px
  &-item
    $t: &
    display: inline-block
    border: 1px solid $halfGrey
    background-color: $mainWhite
    border-radius: 5px
    font-family: $avenirNextRegular
    color: $darkBlue
    position: relative
    overflow: hidden
    max-height: 80px
    width: 100%
    transition: max-height .3s ease-in-out
    +max($mobile)
      width: 100%
      margin-right: 0
    &:last-child
      margin-bottom: 0
    &.active
      max-height: 100%
      border-color: $mainGreen
      #{$t}__title,
      #{$t}__ico
        color: $mainWhite
        font-family: $avenirNextDemi
      #{$t}__heading
        background-color: $mainGreen
    &__title
      font-size: $regularFontSize
      overflow: hidden
      height: 80px
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      position: relative
      +max($mobile)
        font-size: $smallFontSize
        height: 60px
      +max($mobileM)
        font-size: $extraSmallFontSize
    &__ico
      color: $halfGrey
    &__heading
      cursor: pointer
    &__heading,
    &__desc
      padding: 0 30px
      +max($mobile)
        padding: 0 15px
    &__desc
      padding-bottom: 30px
      padding-top: 30px
      font-size: $regularFontSize
      color: $darkBlue
      line-height: 22px
      display: none
      +max($mobile)
        font-size: $smallFontSize
        padding-bottom: 15px
        padding-top: 15px
      +max($mobileM)
        font-size: $extraSmallFontSize

      p + p
        margin-top: 20px
        +max($mobile)
          margin-top: 10px

