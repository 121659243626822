.toggle
  &-wrapper
    display: flex
    align-items: flex-start
    label
      font-size: $regularFontSize
      color: $mainBlack
      font-family: $avenirNextMedium
      line-height: 28px
      display: block
  &-checkbox
    display: flex
    align-items: baseline
    position: relative
    margin-right: 12px
    input
      position: absolute
      visibility: hidden
      + i
        +size(44px, 24px)
        float: left
        border-radius: 20px
        transition: all 0.25s
        background-color: $borderGrey
        border: 1px solid $borderGrey
        position: relative
        top: 3px
        &:hover
          transition: .3s ease-in-out
          border: 1px solid $halfGrey
          background-color: $halfGrey
        &:after
          content: ' '
          background-color: $mainWhite
          float: left
          +size(20px)
          border-radius: inherit
          transition: inherit
          position: absolute
          top: .5px
          left: 2px
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2)
      &:checked + i
        border-color: $mainGreen
        background-color: $mainGreen
        &:after
          background-color: $mainWhite
          margin-left: calc(100% - 23px)
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2)
      &:disabled
        cursor: none
        pointer-events: none
        color: $lightGrey
        + i
          border-color: $lightGrey
          background-color: $lightGrey
          &:after
            background-color: $mainWhite