.concilium-view
  padding: 30px
  background: $mainWhite
  &__section
    + .concilium-view__section
      margin-top: 40px
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
  &__section-title
    font: $bigFontSize $avenirNextLight
    color: $mainBlack
  &__wrapper
    + .concilium-view__wrapper
      margin-top: 20px
  &__desc
    font: $regularFontSize $avenirNextLight
    color: $mainBlack
  &__sum
    background: $backgroundGrey
    font: $demiFontSize $avenirNextLight
    color: $mainBlack
    padding: 16px 80px 16px 0
    text-align: right

  &__total-sum-value
    font: $demiFontSize $avenirNextDemi
    color: $darkBlue
  .concilium-member
    display: flex
    justify-content: flex-start
    &--accepted
      .concilium-member__status
        color: $mainGreen
        background: $lightGrey
    &--pending
      .concilium-member__status
        color: $mainYellow
        background: $lightYellow

    &__wrapper
      width: 45%
      display: flex
      align-items: center
    &__pic
      margin-right: 10px
      +size(50px)
    &__info
      display: flex
      flex-direction: column
      align-items: flex-start
    a.concilium-member__name
      font: $extraSmallFontSize $avenirNextMedium
      color: $darkBlue
      text-decoration: none
      display: block
      margin-bottom: 0
      &:hover
        color: $darkBlue
        text-decoration: underline
    &__status
      font: $minimalFontSize $avenirNextDemi
      color: $mainBlack
      padding: 4px 12px
