.actions-wrapper
    padding: 15px 20px
    background-color: $mainWhite
    border-radius: 8px
    border: 1px solid $borderGrey
    margin: 20px 0
    display: grid
    grid-gap: 25px
    +max($mobile)
        grid-gap: 15px
    &.my-patiens-search
        grid-template-columns: auto 160px
        .search
            max-width: 100%