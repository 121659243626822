.flesh-notification
  background-color: $mainWhite
  padding: 20px
  display: flex
  align-items: flex-start
  border-radius: 10px
  position: relative
  $fn: &
  &__ico
    +size(50px)
    min-width: 50px
    +flex-center()
    margin-right: 20px
    background-color: #FFC4C4
    border-radius: 50%

  &__message
    width: 100%
    color: $mainBlack
    font-size: $regularFontSize
    +max($mobile)
      width: 100%
      padding-right: 20px

    span
      color: #0044D5
  
  &__close
    +size(36px)
    +flex-center()
    position: absolute
    top: 0
    right: 0
    background-color: transparent
    border: 0
    padding: 0

    i
      font-size: $demiFontSize
      color: #ACB6DB

  &.red
    border: 1px solid $mainRed
    #{$fn}__ico
      background-color: $mainRed
      color: $mainWhite
  &.green
    border: 1px solid $mainGreen
    #{$fn}__ico
      background-color: $mainGreen
      color: $mainWhite
    &--marginbtm
      margin-bottom: 30px
  &.yellow
    border: 1px solid $mainYellow
    #{$fn}__ico
      background-color: $mainYellow
      color: $mainWhite
  &.blue
    border: 1px solid $hoveredMainBlue
    #{$fn}__ico
      background-color: $hoveredMainBlue
      color: $mainWhite     

.notification-list
  position: fixed
  top: 21px
  right: 32px
  width: 420px
  max-width: 100%
  z-index: 10000
  +max($mobileL)
    width: calc( 100% - 40px )
.notification-item
  $item: &
  width: 100%
  min-height: 185px
  max-width: 100%
  position: relative
  background: $mainWhite
  //border: 2px solid $mainGreen
  box-shadow: 0 0 16.5px rgba(0, 0, 0, 0.2)
  border-radius: 20px
  cursor: pointer
  padding: 20px
  transition: all .5s ease-out
  transform: translateX(110%)
  display: flex
  flex-direction: column
  //&::after
  //  content: "clear"
  //  font: $smallFontSize "Material Icons"
  //  color: $mainGrey
  //  display: block
  //  position: absolute
  //  right: 8px
  //  top: 8px
  //&::before
  //  content: "beenhere"
  //  font: $smallFontSize "Material Icons"
  //  color: $mainGreen
  //  display: block
  //  position: absolute
  //  font-size: 45px
  //  top: 50%
  //  transform: translateY(-50%)
  //  left: 20px
  &.show
    transition: all .5s ease-out
    transform: translateX(0%)

  + #{$item}
    margin-top: 22px
  &__title
    //font: $smallFontSize $avenirNextDemi
    //color: $mainBlack
    font-family: GraphikLCG
    font-weight: 500
    font-size: 16px
    line-height: 18px
    letter-spacing: -0.03em
    color: #000000
    margin-bottom: 18px
  &__message
    //font: $regularFontSize $avenirNextMedium
    //color: $darkGrey
    font-family: GraphikLCG
    font-weight: 500
    font-size: 12px
    line-height: 14px
    letter-spacing: -0.03em
    color: #425466
  &__button
    width: 142px
    height: 40px
    background-color: #29F499
    border-radius: 40px
    margin-top: auto
    margin-left: auto
    display: flex
    justify-content: center
    align-items: center
    font-family: GraphikLCG
    font-weight: 500
    font-size: 14px
    line-height: 14px
    letter-spacing: -0.03em
    color: #000000
    
  //&--error
  //  border: 2px solid $mainRed
  //  &::before
  //    content: "error"
  //    color: $mainRed
