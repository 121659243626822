.search
  $t: &
  position: relative
  width: 100%
  max-width: 330px

  &__input
    border: 1px solid #545784
    width: 100%
    padding: 0 20px
    min-height: 50px
    border-radius: 10px
    color: $darkBlue
    font-family: $avenirNextMedium
    background-color: transparent
    line-height: 1

  &__button
    +size(50px)
    position: absolute
    top: 0
    right: 4px
    background-color: transparent
    border: 0
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    &:focus
      outline: none

    .material-icons
      color: $darkBlue
  form
    margin-bottom: 0
  &.header__search
    max-width: 480px
    +max($mobile)
      max-width: 100%
    .button_regular
      position: absolute
      top: 5px
      right: 5px
    
