.booking-card
    border-radius: 5px
    background-color: $mainWhite
    padding: 20px
    border: 1px solid $halfGrey
    position: relative
    .context-menu
        position: absolute
        right: 10px
        top: 15px
    &__heading
        display: flex
        align-items: center
        justify-content: flex-start
        margin-bottom: 30px
        &-pic
            +size(50px)
            min-width: 50px
            overflow: hidden
            border-radius: 50%
            margin-right: 10px
            img
                height: 100%
                max-width: 100%
                object-fit: cover
        &-name
            text-decoration: none
            line-height: 20px
            color: $mainBlack
            &:hover
                color: $mainBlack
                text-decoration: none

    &__body
        &-service        
            &--title
                font-size: $regularFontSize
                font-family: $avenirNextDemi
                color: $mainBlack
                line-height: 22px
                margin-bottom: 10px
            &--desc
                line-height: 18px
                color: $mainBlack
        &-top
            margin-bottom: 20px
        &-info
            display: flex
            align-items: flex-start
            width: 100%
            flex-direction: column
            margin-bottom: 20px
            &--row
                justify-content: space-between
                flex-direction: row
            &--date-time
                margin-bottom: 30px
                max-width: 80%   
            &--title
                font-size: $smallFontSize
                color: $mainBlack
                font-family: $avenirNextMedium
            &--description
                font-size: $smallFontSize
                color: $mainBlack
                font-family: $avenirNextDemi  
