.modal
  .close
    padding: 0
    margin: 0
    background-color: transparent
    +size(36px)
    position: absolute
    top: 0
    right: 0
    display: flex
    justify-content: center
    align-items: center
    opacity: 1

  &-dialog
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%) !important //overlaps bootstrap default style rule
    width: 70vw
    height: 100%
  .modal-header
    position: relative
    padding-right: 40px
    padding: 20px
    padding-top: 30px
    &.documents-modal__header
      +max($mobile)
        border: unset
        padding: 15px 40px 15px 15px

  .modal-content
    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.1)
    border: 1px solid $halfGrey

  .modal-title
    color: $darkBlue
    font: $mediumFontSize $avenirNextBold

  .modal-body
    padding: 15px
    #hourListFree
      margin-top: 20px

    &--text
      margin: 30px 0
      display: flex

  .modal-footer
    .btn-wrap
      display: flex
      width: 100%
      justify-content: center
    &.documents-modal__footer
      +max($mobile)
        border: unset
        .btn
          font-size: $smallFontSize
          padding: 8.5px 15px
          width: 100%
          justify-content: center
.modal-btn
  height: 40px
  padding: 0 30px
  color: $mainBlack
  font: $smallFontSize $avenirNextMedium
  align-items: center
  border-radius: 30px
  background-color: transparent
  box-shadow: none
  border: 1px solid $mainGreen

  &--grey
    background-color: #E8E8E8
    border: 0

.w-70
  min-height: 70vh
  object-fit: contain
  width: 100%
