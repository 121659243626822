.cabinet-archive-nav
  display: flex
  align-items: center
  justify-content: space-between
  +max($mobile)
    &.cabinet-archive-mobile-fields
      flex-wrap: wrap
    flex-direction: column
    align-items: flex-start
    width: 100%
    a, button
      width: 100%
      margin-right: 0
    a
      margin-bottom: 10px
  + .flesh-notification
    margin-top: 30px

.btn-wrap.btn-wrap--end.btn-wrap--mt-auto.btn-wrap--data-archive
  .button_regular
    margin-right: auto
  .button_demi + .button_demi
    margin-left: 10px
//-----will not need  probably
.search-archive-dates
  display: flex
  +max($mobile)
    flex-direction: column

  &__left,
  &__right
    width: 50%
    padding: 20px
    +max($mobile)
      width: 100%

    > span
      display: block
      color: $mainBlack
      font-size: $smallFontSize
      font-family: $avenirNextMedium

  &__left
    border-right: 1px solid $halfGrey
    +max($mobile)
      border-right: none
.cabinet-services-tabs .flesh-notification
  margin-bottom: 20px