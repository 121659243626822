.first-screen
  background-repeat: no-repeat
  background-size: 100%
  padding-top: 190px
  text-align: center
  color: $mainWhite
  height: auto
  background-position: top
  position: relative
  z-index: 3
  +max($mobile)
    padding-top: 160px
    background-image: url('/build/images/background/header-background_nobg.svg')
    background-size: cover
  &-bg,
  &-nobg
    position: absolute
    top: 0
    left: 0
    right: 0
    width: 100%
    z-index: 3

.preview
  +max($desktop)
    position: relative
    z-index: 3
    &__buttonPlug
      margin-top: 0px
      padding-bottom: 90px
  +max($tablet)
    &__buttonPlug
      margin-top: -90px
  +max($mobile)
    &__buttonPlug
      padding-bottom: 30px
  &__title
    margin-bottom: 15px
    +max($mobile)
      margin-bottom: 20px

    img
      width: 320px
      +max($mobile)
        max-width: 100%
        width: 182px

  &__subtitle
    font: 26px / 1 $avenirNextMedium
    margin-bottom: 50px
    +max($mobile)
      font-size: $mediumFontSize
      font-family: $avenirNextDemi
      margin-bottom: 40px

  &__second-title
    font: $extraLargeFontSize $avenirNextBold
    margin-bottom: 10px
    +max($mobile)
      font-size: $bigFontSize
      margin-bottom: 20px

  &__second-subtitle
    font: $bigFontSize / 1 $avenirNextMedium
    width: 450px
    margin: 0 auto 60px
    max-width: 100%
    +max($mobile)
      font-size: $mediumFontSize

  &__btn
    min-width: 250px
    height: 60px
    display: flex
    justify-content: space-between
    align-items: center
    color: #12C48B
    padding: 0 10px 0 30px
    font: $regularFontSize / 1 $avenirNextMedium
    +max($mobile)
      max-width: 324px
      width: 100%
      min-height: 50px
      height: unset
      padding: 0 5px 0 20px

    span
      +size(40px)
      background-color: #12C48B
      border-radius: 50%
      +flex-center()

      i
        color: $mainWhite

.notebook
  position: relative
  z-index: 4
  img
    width: 920px
    max-width: 100%
