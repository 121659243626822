.modal-help-patient
  .modal-dialog--modified
    max-width: 700px

.help-patient
  display: flex
  figure
    width: 14.56%
    flex-shrink: 0
    img
      max-width: 100%
  &__content
    font: $demiFontSize $avenirNextMedium
    color: $darkBlue
    margin-left: 5.66%
    +max($mobile)
      font-size: 16px
    p + p
      margin-top: 30px
      +max($mobile)
        margin-top: 15px