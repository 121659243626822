.radio
  $t: &

  position: relative
  display: flex
  align-items: center
  cursor: pointer

  input
    position: absolute
    left: 5px
    top: 50%
    transform: translateY(-50%)
    opacity: 0

    &:checked
      ~ #{$t}__ico
        border: 2px solid $mainGreen
        &:after
          opacity: 1
          background-color: $mainGreen
    &:hover
      ~ #{$t}__ico
        border-color: $mainGreen
    &:disabled
      cursor: none
      pointer-events: none
      ~ #{$t}__ico
        border-color: $mainGrey
        &:after
          background-color: $mainGrey
      ~ #{$t}__text
        color: $mainGrey

  &__ico
    +size(21px)
    background-color: transparent
    border: 2px solid $lightBlue
    border-radius: 50%
    margin-right: 10px
    position: relative

    &:after
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0
      margin: auto
      +size(11px)
      background-color: $mainBlack
      border-radius: 50%
      opacity: 0

  &__text
    font-family: $avenirNextMedium
    font-size: $smallFontSize
    color: $mainBlack

.radio-wrap
  display: flex
  align-items: center
  flex-wrap: wrap

  label
    width: 100%

    &.radio
      width: auto

  .radio + .radio
    margin-left: 25px
  &.register-radio-wrap
    margin: 10px 0 0 0
