.broadcast-preview
  background-color: $mainWhite
  display: flex
  flex-direction: column
  min-width: 330px
  position: relative
  &__actions
    display: flex
    align-items: center
    position: absolute
    left: 20px
    top: 20px
    border-radius: 2px
    background-color: rgba(37, 174, 246, 0.7)
    padding: 7px
    z-index: 2
    &-item
      display: flex
      align-items: center
      color: $mainWhite
      .material-icons,
      .material-icons-outlined
        font-size: 18px
        margin-right: 3px
      span
        font-size: $extraSmallFontSize
        font-family: $avenirNextDemi
      + .broadcast-preview__actions-item
        margin-left: 10px
  &__pic
    +size(100%, 180px)
    min-height: 180px
    overflow: hidden
    position: relative
    margin-bottom: 20px
    img
      border-radius: 4px
      +size(100%, 100%)
      max-width: 100%
      object-fit: cover
  
  &__status
    position: absolute
    top: 30px
    left: 30px
    background-color: rgba($mainBlack, .6)
    border-radius: 4px
    display: flex
    align-items: center
    font: 12px $avenirNextMedium
    padding: 7px 10px
    color: $mainWhite
    text-transform: uppercase

    span
      display: flex
      align-items: center

      i
        margin-right: 6px
        color: $mainRed
        font-size: 17px

      &:first-child
        padding-right: 10px
        position: relative

        &:after
          content: ''
          background-color: $mainWhite
          opacity: 0.5
          width: 1px
          height: 14px
          position: absolute
          right: 0
          top: calc(50% - 1px)
          transform: translateY(-50%)


      &:last-child
        margin-left: 8px

        i
          color: $mainGreen
  &__state,
  &__category
    color: $mainGreen
    font-family: $avenirNextMedium
    font-size: $regularFontSize
  &__state
    display: flex
    align-items: center
    color: $mainGrey
  &__body
    margin-bottom: 15px
  &__info
    display: flex
    flex-direction: column
    margin-bottom: 10px
  &__title
    color: $darkBlue
    word-break: break-word
    line-height: 25px
    &.links-effect
      &:hover,
      &:focus
        color: $darkBlue
    &-wrapper
      height: 75px
      max-height: 100%
      overflow: hidden
  &__name
    font-size: $mediumFontSize
  &__author
    display: flex
    align-items: flex-start
    width: 100%
    &-pic
      +size(40px)
      min-width: 40px
      border-radius: 50%
      border: 2px solid $mainBlue
      margin-right: 10px
      img
        border-radius: 50%
        width: 100%
        max-width: 100%
        object-fit: cover
        height: 100%
    &-desc
      display: flex
      flex-direction: column
    &-name
      font-size: $extraSmallFontSize
      color: $mainBlack
      font-family: $avenirNextMedium     
    &-specialization
      font-size: $extraSmallFontSize
      font-family: $avenirNextLight
      color: $textGrey

