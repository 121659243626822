.cabinet-complaint-list
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
  width: 100%
  +max($mobile)
    flex-direction: column

  .complaint-item
    width: calc((100% - 60px) / 3)
    margin-bottom: 30px
    margin-right: 30px
    &:nth-child(3n)
      margin-right: 0
    @media(max-width: 1440px)
      width: calc((100% - 60px) / 2)
      &:nth-child(2n)
        margin-right: 0
      &:nth-child(3n)
        margin-right: 30px
    +max($mobile)
      width: 100%
      margin-right: 0

  .complaint-item
    border: 1px solid $halfGrey
    background-color: $mainWhite
    padding: 20px
    border-radius: 5px
    color: $mainBlack

    &__number
      &::before
        content: "№"
        margin-right: 5px
    &__reason
      color: $mainRed

    &__sender
      margin: 20px 0 15px
      a
        color: $mainBlack

    &__info-title
      color: $mainBlack
      font: $smallFontSize $avenirNextMedium
      text-overflow: ellipsis
      overflow: hidden

    &__info
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      line-height: normal
      &:last-child
        margin-bottom: 0
      &--column
        flex-direction: column
    &__right
      min-width: 30%

    &__service-type
      text-overflow: ellipsis
      overflow: hidden

    &__info-desc
      font: $smallFontSize / 21px $avenirNextDemi
      text-overflow: ellipsis
      overflow: hidden
      margin-bottom: 20px

    &__service-name
      margin-bottom: 10px

    &__description
      word-break: break-all
