.cabinet-mobile-nav
    display: none
    width: 100%
    background-color: $mainWhite
    box-shadow: 0px 5px 5px 3px $mainBlack
    +max($laptop)
        display: flex
        flex-direction: column
        position: fixed
        bottom: 0
        left: 0
        right: 0
        z-index: 7
    &__wrapper
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        padding: 0 20px
    &__link
        padding: 10px 15px
        display: flex
        flex-direction: column
        align-items: center
        font-size: 10px
        color: $mainGrey
        text-decoration: none
        font-family: $avenirNextDemi
        &.cabinet-mobile-more:not([href]):not([tabindex]),   
        &:hover
            color: $mainGrey
            text-decoration: none
        &.active
            color: $mainGreen     
            &.cabinet-mobile-more
                color: $mainGreen
                &:not([href]):not([tabindex]):hover
                    color: $mainGreen
         