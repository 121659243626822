.gallery-wrapper
  position: relative
  z-index: 20

  .content
    position: relative
    overflow: hidden
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 3vh 0
    background: #101014
    background: linear-gradient(to left, #19191f, #101014)
    .loader
      position: fixed
      left: 0
      top: 0
      width: 100%
      height: 100%
      z-index: 20
      background: #101014
      background: linear-gradient(to left, #19191f, #101014)
      display: flex
      align-items: center
      justify-content: center
      svg
        path, rect
          fill: $mainGreen

    .gallery
      &.full
        position: relative
        display: flex
        align-items: center
        z-index: 19

        .swiper-container .swiper-wrapper
          display: flex
          height: 80vh
          align-items: center

          .swiper-slide
            position: relative
            height: 50vh
            transition: all 0.4s ease-out
            box-sizing: border-box
            width: auto
            opacity: 0.25

            &.swiper-slide-prev, &.swiper-slide-next
              width: 20% !important

            &.swiper-slide-active
              height: 80vh
              width: 60% !important
              opacity: 1

              &:hover .zoom
                opacity: 1
                pointer-events: auto
                visibility: visible

            .image
              width: 100%
              height: 100%

              img
                width: 100%
                height: 100%
                display: block
                object-fit: cover

            .overlay
              position: absolute
              left: 0
              bottom: 0
              z-index: 2
              width: 100%
              height: auto
              padding: 50px 50px 30px 50px
              background: $mainBlack
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%)
              display: flex
              align-items: center
              opacity: 0
              visibility: hidden
              transition: all 0.3s ease-out
              +max($mobile)
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0) 100%)

              &.show
                opacity: 1
                visibility: visible

                .text-wrap
                  opacity: 1
                  visibility: visible
                  transform: translateY(0)

              .text-wrap
                display: flex
                flex-direction: column
                max-width: 600px
                opacity: 0
                visibility: hidden
                transform: translateY(50px)
                transition: all 0.4s linear
                text-align: left

                .name span
                  font-size: 2.2em
                  color: $mainWhite
                  font-family: $avenirNextBold
                  +max($mobile)
                    font-family: $avenirNextDemi
                    line-height: 30px
                    font-size: $bigFontSize!important
                  +max($mobileM)
                    line-height: 24px
                    font-size: $mediumFontSize!important

        .swiper-next-button, .swiper-prev-button
          position: absolute
          z-index: 99
          outline: none
          transition: all 0.2s linear
          width: 20%
          height: 50vh
          display: flex
          justify-content: flex-end
          align-items: center
          cursor: pointer
          +max($mobile)
            display: none

        .swiper-next-button.swiper-button-disabled, .swiper-prev-button.swiper-button-disabled
          opacity: 0.2

        .swiper-next-button em, .swiper-prev-button em
          font-size: 24px

        .swiper-next-button
          right: 0
          padding-left: 5vw
          justify-content: flex-start

        .swiper-prev-button
          left: 0
          padding-right: 5vw

      &.thumb
        position: relative
        z-index: 19
        width: 100%
        max-width: 1020px
        margin: 0 auto
        display: flex
        align-items: center
        padding-left: 12px

        .swiper-container .swiper-wrapper .swiper-slide
          position: relative
          width: 200px !important
          max-width: 100%
          height: 10vh
          box-sizing: border-box
          cursor: pointer
          +max($mobile)
            width: 140px !important

          &.swiper-slide-thumb-active .image
            box-shadow: inset 0px 0px 0px 2px $mainGreen
            padding: 2px

            .overlay
              opacity: 1

          .image
            width: 100%
            height: 100%
            position: relative

            img
              width: 100%
              height: 100%
              display: block
              object-fit: cover

            .overlay
              position: absolute
              left: 2px
              top: 2px
              background-color: rgba(43, 44, 54, 0.6)
              display: flex
              align-items: center
              justify-content: center
              color: $mainWhite
              width: calc(100% - 4px)
              height: calc(100% - 4px)
              opacity: 0
              transition: all 0.2s linear

        .swiper-next-button
          right: -15px

        .swiper-prev-button
          left: -5px

          em
            transform: rotate(180deg)

        .swiper-next-button, .swiper-prev-button
          position: absolute
          z-index: 99
          outline: none
          transition: all 0.2s linear
          width: 32px
          height: 20px
          display: flex
          justify-content: center
          align-items: center
          background-color: $mainGreen
          cursor: pointer
          em
            color: $mainWhite

        .swiper-next-button:hover, .swiper-prev-button:hover
          background-color: $hoveredMainGreen

        .swiper-next-button.swiper-button-disabled, .swiper-prev-button.swiper-button-disabled
          opacity: 0.2

+max($laptop)
  .gallery-wrapper .content
    padding: 5vh 0

    .gallery
      &.full
        .swiper-container .swiper-wrapper
          height: 70vh

          .swiper-slide
            height: 50vh

            &.swiper-slide-prev, &.swiper-slide-next
              width: 10% !important

            &.swiper-slide-active
              height: 70vh
              width: 80% !important

            .overlay
              padding: 20px

              .text-wrap
                transform: translateY(0)

                .name span
                  font-size: 1.6em

                .caption p
                  font-size: 1.06em

        .swiper-next-button, .swiper-prev-button
          width: 10%
          height: 50vh
          align-items: center
          justify-content: center

        .swiper-next-button em, .swiper-prev-button em
          font-size: 45px

        .swiper-next-button
          padding-left: 0

        .swiper-prev-button
          padding-right: 0

      &.thumb
        padding: 0 30px
        max-width: 100%

        .swiper-container .swiper-wrapper .swiper-slide
          width: auto
          height: 80px
          min-height: 100%

        .swiper-next-button
          right: 10px

        .swiper-prev-button
          left: 10px

.swiper-container .swiper-wrapper .swiper-slide
  width: auto
  min-height: 100%
  height: unset

.swiper-news-wrapper
  +flex-center
